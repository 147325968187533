import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ALL_SUIVISTOCK } from "../../../utils/FDoceneteteUtils";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import { DialogProps } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { set } from "../../../app/globalSlice";

interface State {
  arRef: string;
  arSuivistock: number;
  canChangeArSuivistock: number;
  disabled?: boolean;
}

const UpdateSuiviStockComponent: React.FC<State> = React.memo(
  ({ arRef, arSuivistock, canChangeArSuivistock, disabled }) => {
    const theme = useTheme();
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [newArSuivistock, setNewArSuivistock] = React.useState<
      number | undefined
    >(undefined);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const dispatch = useAppDispatch();

    const handleDialog = React.useCallback(
      (event: SelectChangeEvent) => {
        if (loading) {
          return;
        }
        setOpenDialog(true);
        setNewArSuivistock(Number(event.target.value));
      },
      [loading]
    );

    const closeDialog = React.useCallback(() => {
      if (loading) {
        return;
      }
      setOpenDialog(false);
      setNewArSuivistock(undefined);
    }, [loading]);

    const changeArSuiviStock = React.useCallback(async () => {
      if (!newArSuivistock) {
        return;
      }
      setLoading(true);
      const fArticleApi = new FormData();
      fArticleApi.append(
        "json",
        JSON.stringify({
          arRef: arRef,
          arSuivistock: newArSuivistock,
        })
      );
      const response = await requestApi({
        method: POST,
        path: FARTICLE_URL,
        allowError: true,
        timeout: 30_000,
        token: token,
        body: fArticleApi,
        formData: true,
      });
      if (response.statusCode === 201) {
        closeDialog();
        dispatch(set({ refreshPage: true }));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [arRef, closeDialog, dispatch, newArSuivistock, t, token]);

    return (
      <>
        <Dialog
          maxWidth={maxWidth}
          fullScreen={fullScreen}
          fullWidth={true}
          onClose={closeDialog}
          open={openDialog}
        >
          <DialogContent>
            <Typography sx={{ textAlign: "center" }}>
              {t("sentence.askChangeArSuivistock")
                .replace("%arRef%", arRef)
                .replace("%oldValue%", t("word.arSuivistock." + arSuivistock))
                .replace(
                  "%newValue%",
                  t("word.arSuivistock." + newArSuivistock)
                )}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <LoadingButton disabled={loading} onClick={closeDialog}>
              {t("word.no")}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={changeArSuiviStock}
            >
              {t("word.yes")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <FormControl size="small">
          <Select
            disabled={canChangeArSuivistock !== 0 || disabled === true}
            value={arSuivistock.toString()}
            onChange={handleDialog}
          >
            {ALL_SUIVISTOCK.map((v) => (
              <MenuItem value={v.toString()} key={v}>
                {t("word.arSuivistock." + v)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  }
);

export default UpdateSuiviStockComponent;
