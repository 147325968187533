import * as React from "react";
import { SyntheticEvent, useImperativeHandle, useRef } from "react";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import {
  DriverInterface,
  FArticleSmallInterface,
} from "../../../interfaces/FArticleInterface";
import {
  DriverComponentAutocomplete,
  FArticleSmallComponentAutocomplete,
} from "./FArticleSmallComponent";
import { useNavigate } from "react-router-dom";
import { PRODUCTS_PAGE } from "../../../utils/RouteUtils";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import { STORAGE_INDEXABLE } from "../../../utils/StorageUtils";
import { ELASTIC_STOCK_AVAILABLE } from "../../../utils/FArticleUtils";

const stringSimilarity = require("string-similarity");
const slugify = require("slugify");

interface State {
  onSuccess: Function;
  handleSearchClose?: Function;
  hideSearchIcon?: boolean;
  hideConstructor?: boolean;
  preventSearch?: boolean;
  full?: boolean;
}

const minStringSimilarity = 0.8;

const SearchFArticleComponent = React.memo(
  React.forwardRef(
    (
      {
        onSuccess,
        handleSearchClose,
        hideSearchIcon,
        hideConstructor,
        preventSearch,
        full,
      }: State,
      ref
    ) => {
      const theme = useTheme();
      const inputRef: any = useRef();
      const isSmall = useMediaQuery(theme.breakpoints.down("md"));
      const { t } = useTranslation();
      const [searchFArticle, setSearchFArticle] = React.useState<string>("");
      const [isTyping, setIsTyping] = React.useState<boolean>(false);
      const [isSearching, setIsSearching] = React.useState<boolean>(false);
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const globalSearchByRayon = useAppSelector(
        (state: RootState) => state.globalState.globalSearchByRayon
      );
      const [fArticles, setFArticles] = React.useState<
        (FArticleSmallInterface | DriverInterface)[]
      >([]);
      const drivers = useAppSelector(
        (state: RootState) => state.globalState.drivers
      );
      const getDriverNames = React.useCallback(() => {
        return (
          drivers?.map((driver) => slugify(driver.name, { lower: true })) ?? []
        );
      }, [drivers]);
      const [driverNames, setDriverNames] = React.useState<string[]>(
        getDriverNames()
      );
      const refField: any = useRef();
      const navigate = useNavigate();

      const handleSearchFArticle = React.useCallback(
        (event: SyntheticEvent<Element, Event>, value: string) => {
          if (event && event.type === "blur") {
            return;
          }
          setSearchFArticle(value);
          setFArticles([]);
          setIsTyping(true);
        },
        []
      );

      const goToSearchFArticle = React.useCallback(
        (keywords: string) => {
          const list: NodeList = refField.current.querySelectorAll("input");
          list.forEach((el) => {
            // @ts-ignore
            el.blur();
          });
          let driverFound = "";
          let driver = null;
          const listKeywords = keywords
            .split(" ")
            .filter((keyword) => keyword !== "");
          for (const keyword of listKeywords) {
            const matchResult = stringSimilarity.findBestMatch(
              slugify(keyword, { lower: true }),
              driverNames
            );
            if (matchResult.bestMatch.rating > minStringSimilarity) {
              driver = drivers?.find(
                (driver) =>
                  slugify(driver.name, { lower: true }) ===
                  matchResult.bestMatch.target
              );
              driverFound = keyword;
              break;
            }
          }
          // if (driver) {
          //   navigate(
          //     PRODUCTS_PAGE +
          //       objectToQuery({
          //         "brands[]": driver.name,
          //         keywords: keywords.replace(driverFound, ""),
          //       })
          //   );
          // } else {
          navigate(
            PRODUCTS_PAGE +
              objectToQuery({
                keywords: keywords,
                single: "true",
                ...(globalSearchByRayon && {
                  "order[categories]": JSON.stringify({
                    order: "asc",
                  }),
                }),
              })
          );
          // }
          setSearchFArticle("");
          setFArticles([]);
          if (handleSearchClose) {
            handleSearchClose();
          }
        },
        [driverNames, drivers, globalSearchByRayon, handleSearchClose, navigate]
      );

      const searchFArticles = React.useCallback(async () => {
        const keywords = searchFArticle.trim();
        setIsTyping(false);
        if (keywords === "") {
          return;
        }
        setIsSearching(true);
        let indexable = localStorage.getItem(STORAGE_INDEXABLE);
        if (indexable === null) {
          indexable = "1";
        }
        const param: any = {
          keywords: keywords,
          stock: ELASTIC_STOCK_AVAILABLE,
          ...(indexable !== "" && { indexable: indexable }),
          ...(full && { full: true }),
        };
        const response = await requestApi({
          method: GET,
          path: FARTICLE_URL + objectToQuery(param),
          allowError: false,
          paginate: false,
          token: token,
          timeout: 30_000,
        });
        if (searchFArticle !== inputRef.current.value) {
          // search has changed don't use this result
          return;
        }
        if (response.statusCode === 200) {
          const matchResult = stringSimilarity.findBestMatch(
            slugify(keywords, { lower: true }),
            driverNames
          );
          let result = response.content.fArticles;
          if (hideConstructor !== true) {
            if (matchResult.bestMatch.rating > minStringSimilarity) {
              const driver = drivers?.find(
                (driver) =>
                  slugify(driver.name, { lower: true }) ===
                  matchResult.bestMatch.target
              );
              if (driver) {
                result = [driver, ...result];
              }
            }
          }
          setFArticles(result);
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setIsSearching(false);
      }, [
        driverNames,
        drivers,
        full,
        hideConstructor,
        searchFArticle,
        t,
        token,
      ]);

      const onChangeAutocomplete = React.useCallback(
        (fArticleSmall: any) => {
          onSuccess(fArticleSmall);
          setSearchFArticle("");
          setFArticles([]);
          const list: NodeList = refField.current.querySelectorAll("input");
          list.forEach((el) => {
            // @ts-ignore
            el.blur();
          });
        },
        [onSuccess]
      );

      useImperativeHandle(ref, () => ({
        focus() {
          inputRef.current.focus();
        },
      }));

      React.useEffect(() => {
        const timeoutTyping = setTimeout(() => {
          searchFArticles();
        }, 500);
        return () => clearTimeout(timeoutTyping);
      }, [searchFArticles]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setDriverNames(getDriverNames());
      }, [drivers]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {isSmall && searchFArticle.trim() !== "" && (
            <Button
              variant="contained"
              onClick={() => goToSearchFArticle(searchFArticle.trim())}
              sx={{
                paddingY: 2,
                width: "100%",
              }}
            >
              {t("word.search")}
            </Button>
          )}
          <Autocomplete
            fullWidth
            loadingText={t("word.loading") + "..."}
            classes={{ hasPopupIcon: "MuiAutocomplete-transform-rotate-0" }}
            forcePopupIcon={false}
            clearIcon={undefined}
            inputValue={searchFArticle}
            clearOnBlur={false}
            onInputChange={handleSearchFArticle}
            noOptionsText={
              isSearching ? (
                <Box sx={{ textAlign: "center" }}>
                  <CircularProgress />
                </Box>
              ) : searchFArticle !== "" && !isTyping ? (
                t("sentence.no_result")
              ) : (
                t("word.sav.searchFArticle.label")
              )
            }
            options={fArticles}
            filterOptions={(options) => {
              return options;
            }}
            getOptionLabel={(
              fArticleSmall: FArticleSmallInterface | DriverInterface
            ) => {
              // @ts-ignore
              return fArticleSmall?.arRef ?? "";
            }}
            isOptionEqualToValue={(
              option: FArticleSmallInterface | DriverInterface,
              value: FArticleSmallInterface | DriverInterface
            ) => {
              // @ts-ignore
              return option?.arRef === value?.arRef;
            }}
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  // @ts-ignore
                  key={option?.arRef ?? option?.name}
                  style={{ display: "block" }}
                >
                  {/*@ts-ignore*/}
                  {option?.arRef ? (
                    <FArticleSmallComponentAutocomplete
                      // @ts-ignore
                      fArticle={option}
                      searchFArticle={searchFArticle}
                    />
                  ) : (
                    // @ts-ignore
                    <DriverComponentAutocomplete driver={option} />
                  )}
                </li>
              );
            }}
            onChange={(e, value) => onChangeAutocomplete(value)}
            value={null}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={inputRef}
                ref={refField}
                className="no-padding-text-field"
                onKeyUp={(e) => {
                  if (e.key === "Enter" && preventSearch !== true) {
                    goToSearchFArticle(searchFArticle.trim());
                  }
                }}
                autoComplete="off"
                sx={{ bgcolor: "background.paper" }}
              />
            )}
          />
          {!isSmall && hideSearchIcon !== true && (
            <Button
              variant="contained"
              onClick={() => goToSearchFArticle(searchFArticle.trim())}
              disabled={searchFArticle.trim() === ""}
            >
              <SearchIcon />
            </Button>
          )}
        </>
      );
    }
  )
);

export default SearchFArticleComponent;
