import React, { useEffect, useImperativeHandle } from "react";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {
  SAGE_DO_TYPE_ACHAT_CC,
  SAGE_DO_TYPE_ACHAT_FA,
  SAGE_DO_TYPE_ACHAT_FC,
  SAGE_DO_TYPE_ACHAT_LI,
  SAGE_DO_TYPE_VENTE_BC,
  SAGE_DO_TYPE_VENTE_BL,
  SAGE_DO_TYPE_VENTE_FA,
} from "../../../../utils/DoceneteteUtils";
import TableBody from "@mui/material/TableBody";
import {
  ActionTransformInterface,
  FDocenteteFoundInterface,
  FormTransform,
  scrollToArticleFDocligne,
  TransformInterface,
} from "../EntreeStockArrivageComponent";
import {
  ArrivageFDocenteteInterface,
  ArrivageFDocligneInterface,
  ArrivageInterface,
  IgnoredDocumentInterface,
  UpdatingArrivageInterface,
} from "../../../../interfaces/ArrivageInterface";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import {
  FDOCENETETE_COLORS,
  FDOCENETETE_STATUS,
} from "../../../../utils/FDoceneteteUtils";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { requestApi } from "../../../../helpers/RequestApi";
import { POST } from "../../../../utils/MethodUtils";
import { FDOCENTETES_URL } from "../../../../utils/UrlsUtils";
import getErrorApi from "../../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import {
  addStateFDoclignes,
  getTransform,
} from "../../../../helpers/FDocenteteHelper";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import UpdateFDocenteteArrivageComponent from "../UpdateFDocenteteArrivageComponent";
import Box from "@mui/material/Box";
import CopyClipboardComponent from "../../CopyClipboardComponent";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import TransformIcon from "@mui/icons-material/Transform";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { PRODUCT_PAGE } from "../../../../utils/RouteUtils";
import { getUrlImageDirectory } from "../../../../helpers/FileHelper";
import { Chip } from "@mui/material";
import Badge from "@mui/material/Badge";

const clone = require("clone");

interface State {
  initArrivage: ArrivageInterface;
  focusMainSearch: Function;
  colSpan: number;
  transform: TransformInterface;
  setTransform: Function;
  updatingArrivage: UpdatingArrivageInterface;
  fastTransform: boolean;
  hideFDoclignes: boolean;
  updateIgnoredDocument: Function;
}

interface State7 {
  running: boolean;
  setTransform: Function;
  fDocenteteFounds: FDocenteteFoundInterface[];
  setFDocenteteFounds: Function;
  arrivage: ArrivageInterface;
  updatingArrivage: UpdatingArrivageInterface;
  doType: number;
  fastTransform: boolean;
}

interface State4 {
  fDocligne: ArrivageFDocligneInterface;
  emplacement: string;
  prop: "bonDeCommande" | "bonDeLivraison" | "facture";
  focusMainSearch: Function;
}

interface State5 {
  arrivage: ArrivageInterface;
  fDocligne: ArrivageFDocligneInterface;
  doType: number;
  action: ActionTransformInterface | undefined;
  running: boolean;
  setTransform: Function;
  fDocenteteFounds: FDocenteteFoundInterface[];
  setFDocenteteFounds: Function;
  updatingArrivage: UpdatingArrivageInterface;
  fastTransform: boolean;
}

interface State3 {
  colSpan: number;
  ignoredDocuments: IgnoredDocumentInterface[];
  updateIgnoredDocument: Function;
  prop: "bonDeCommande" | "bonDeLivraison" | "facture";
  fDocligne: ArrivageFDocligneInterface;
  fDocentetes: ArrivageFDocenteteInterface[] | undefined;
  running: boolean;
  updatingArrivage: UpdatingArrivageInterface;
  arrivage: ArrivageInterface;
  fDocenteteFounds: FDocenteteFoundInterface[];
  setFDocenteteFounds: Function;
  setTransform: Function;
  fastTransform: boolean;
  focusMainSearch: Function;
}

interface State2 {
  arrivage: ArrivageInterface;
  fDocligne: ArrivageFDocligneInterface;
  emplacement: string;
  transform: TransformInterface;
  setTransform: Function;
  fDocenteteFounds: FDocenteteFoundInterface[];
  setFDocenteteFounds: Function;
  updatingArrivage: UpdatingArrivageInterface;
  fastTransform: boolean;
  focusMainSearch: Function;
}

const SingleTableRowFDocligne = React.memo(
  ({ fDocligne, emplacement, prop, focusMainSearch }: State4) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const display =
      (prop === "facture" &&
        fDocligne.stateFDocligne.currentFacture !== "" &&
        fDocligne.doPiece === fDocligne.stateFDocligne.currentFacture) ||
      (prop === "bonDeLivraison" &&
        fDocligne.stateFDocligne.currentBonDeLivraison !== "" &&
        [fDocligne.doPiece, fDocligne.dlPieceBl].includes(
          fDocligne.stateFDocligne.currentBonDeLivraison
        )) ||
      (prop === "bonDeCommande" &&
        fDocligne.stateFDocligne.currentBonDeCommande !== "" &&
        [fDocligne.doPiece, fDocligne.dlPieceBc].includes(
          fDocligne.stateFDocligne.currentBonDeCommande
        ));
    const displayFull =
      display &&
      ((fDocligne.doType === SAGE_DO_TYPE_ACHAT_CC &&
        prop === "bonDeCommande") ||
        (fDocligne.doType === SAGE_DO_TYPE_ACHAT_LI &&
          prop === "bonDeLivraison") ||
        prop === "facture");
    const showBorderX =
      display ||
      (prop === "bonDeCommande" &&
        fDocligne.stateFDocligne.previousBonDeCommande ===
          fDocligne.stateFDocligne.nextBonDeCommande &&
        fDocligne.stateFDocligne.nextBonDeCommande !== "") ||
      (prop === "bonDeLivraison" &&
        fDocligne.stateFDocligne.previousBonDeLivraison ===
          fDocligne.stateFDocligne.nextBonDeLivraison &&
        fDocligne.stateFDocligne.nextBonDeLivraison !== "") ||
      (prop === "facture" &&
        fDocligne.stateFDocligne.previousFacture ===
          fDocligne.stateFDocligne.nextFacture &&
        fDocligne.stateFDocligne.nextFacture !== "");

    let color = FDOCENETETE_COLORS[SAGE_DO_TYPE_VENTE_FA].main;
    if (prop === "bonDeCommande") {
      color = FDOCENETETE_COLORS[SAGE_DO_TYPE_VENTE_BC].main;
    } else if (prop === "bonDeLivraison") {
      color = FDOCENETETE_COLORS[SAGE_DO_TYPE_VENTE_BL].main;
    }

    const showBorderBottom =
      (prop === "bonDeCommande" &&
        fDocligne.stateFDocligne.currentBonDeCommande !== "" &&
        fDocligne.stateFDocligne.nextBonDeCommande !==
          fDocligne.stateFDocligne.currentBonDeCommande) ||
      (prop === "bonDeLivraison" &&
        fDocligne.stateFDocligne.currentBonDeLivraison !== "" &&
        fDocligne.stateFDocligne.nextBonDeLivraison !==
          fDocligne.stateFDocligne.currentBonDeLivraison) ||
      (prop === "facture" &&
        fDocligne.stateFDocligne.currentFacture !== "" &&
        fDocligne.stateFDocligne.nextFacture !==
          fDocligne.stateFDocligne.currentFacture);

    const found = fDocligne.found ?? 0;
    return (
      <>
        <TableCell
          scope="row"
          sx={{
            padding: 0,
            border: "none",
            ...(showBorderX && {
              borderLeft: "2px " + color + " solid",
            }),
            ...(showBorderBottom && {
              borderBottom: "2px " + color + " solid",
            }),
          }}
        >
          {display && displayFull && (
            <Link
              style={{
                textDecoration: "none",
                color: "inherit",
                textAlign: "center",
              }}
              to={PRODUCT_PAGE + "/" + fDocligne.arRef}
            >
              <img
                src={
                  (process.env.REACT_APP_API_URL ?? "") +
                  getUrlImageDirectory(fDocligne.arRef) +
                  "/" +
                  fDocligne?.image
                }
                style={{
                  maxWidth: "40px",
                  float: "left",
                }}
                alt={fDocligne.arRef}
                loading="lazy"
              />
            </Link>
          )}
        </TableCell>
        <TableCell
          scope="row"
          sx={{
            padding: 0,
            border: "none",
            ...(showBorderBottom && {
              borderBottom: "2px " + color + " solid",
            }),
          }}
        >
          {display && (
            <CopyClipboardComponent
              className="RobotoMono"
              component="span"
              text={fDocligne.arRef}
              afterCopy={() => {
                focusMainSearch();
                scrollToArticleFDocligne(fDocligne.arRef, [fDocligne]);
              }}
              sx={{
                wordBreak: "initial",
                color: theme.palette.success.main,
              }}
            />
          )}
        </TableCell>
        <TableCell
          scope="row"
          sx={{
            padding: 0,
            border: "none",
            ...(showBorderBottom && {
              borderBottom: "2px " + color + " solid",
            }),
          }}
        >
          {display && displayFull && fDocligne.lsNoserie && (
            <CopyClipboardComponent
              className="RobotoMono"
              component="span"
              text={fDocligne.lsNoserie}
              sx={{
                wordBreak: "initial",
              }}
            />
          )}
        </TableCell>
        <TableCell
          scope="row"
          sx={{
            padding: 0,
            border: "none",
            ...(showBorderBottom && {
              borderBottom: "2px " + color + " solid",
            }),
          }}
        >
          {display && displayFull && (
            <>
              <Chip
                label={t("word.arSuivistock." + fDocligne.arSuivistock)}
                variant={
                  fDocligne.canChangeArSuivistock === 0 ? "filled" : "outlined"
                }
                color="primary"
                sx={{ marginLeft: 1 }}
              />
            </>
          )}
        </TableCell>
        <TableCell
          scope="row"
          sx={{
            padding: 0,
            border: "none",
            ...(showBorderX && {
              borderRight: "2px " + color + " solid",
            }),
            textAlign: "right",
            ...(showBorderBottom && {
              borderBottom: "2px " + color + " solid",
            }),
          }}
        >
          {display && displayFull && (
            <Chip
              label={found + "/" + fDocligne.dlQte}
              size="small"
              variant="outlined"
              color={
                found === 0
                  ? "error"
                  : found < fDocligne.dlQte
                  ? "warning"
                  : "success"
              }
            />
          )}
        </TableCell>
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.fDocligne.dlNo === nextProps.fDocligne.dlNo &&
      prevProps.fDocligne.doType === nextProps.fDocligne.doType &&
      prevProps.fDocligne.found === nextProps.fDocligne.found
    );
  }
);

const SingleTableHeader = React.memo(
  ({
    colSpan,
    fDocligne,
    prop,
    updateIgnoredDocument,
    ignoredDocuments,
    fDocentetes,
    running,
    updatingArrivage,
    arrivage,
    fDocenteteFounds,
    setFDocenteteFounds,
    setTransform,
    fastTransform,
    focusMainSearch,
  }: State3) => {
    const [disabled, setDisabled] = React.useState(false);
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [open, setOpen] = React.useState<boolean>(false);

    const thisProp = prop.charAt(0).toUpperCase() + prop.slice(1);
    // @ts-ignore
    const current:
      | "currentBonDeCommande"
      | "currentBonDeLivraison"
      | "currentFacture" = "current" + thisProp;
    // @ts-ignore
    const next: "nextBonDeCommande" | "nextBonDeLivraison" | "nextFacture" =
      "next" + thisProp;
    // @ts-ignore
    const previous:
      | "previousBonDeCommande"
      | "previousBonDeLivraison"
      | "previousFacture" = "previous" + thisProp;

    const newDoc =
      fDocligne.stateFDocligne[current] !== "" &&
      fDocligne.stateFDocligne[previous] !== fDocligne.stateFDocligne[current];

    const showBorderX =
      newDoc ||
      (fDocligne.stateFDocligne[previous] === fDocligne.stateFDocligne[next] &&
        fDocligne.stateFDocligne[next] !== "") ||
      (fDocligne.stateFDocligne[previous] ===
        fDocligne.stateFDocligne[current] &&
        fDocligne.stateFDocligne[current] !== "");

    let color = FDOCENETETE_COLORS[SAGE_DO_TYPE_VENTE_FA].main;
    if (prop === "bonDeCommande") {
      color = FDOCENETETE_COLORS[SAGE_DO_TYPE_VENTE_BC].main;
    } else if (prop === "bonDeLivraison") {
      color = FDOCENETETE_COLORS[SAGE_DO_TYPE_VENTE_BL].main;
    }

    const currentDoPiece =
      // @ts-ignore
      fDocligne.stateFDocligne[
        "current" + prop.charAt(0).toUpperCase() + prop.slice(1)
      ];
    const fDocentete = fDocentetes?.find((f) => f.doPiece === currentDoPiece);
    const [doStatut, setDoStatut] = React.useState(fDocentete?.doStatut ?? "");
    const getFDoclignes = React.useCallback(() => {
      return (
        arrivage.fDoclignes?.filter(
          (f) =>
            f.found &&
            f.found > 0 &&
            fDocentete &&
            f.doPiece === fDocentete.doPiece
        ) ?? []
      );
    }, [arrivage.fDoclignes, fDocentete]);
    const [fDoclignes, setFDoclignes] = React.useState(getFDoclignes());

    const onChangeDoStatut = React.useCallback(
      async (event: SelectChangeEvent) => {
        setDisabled(true);
        const fDocenteteApi = new FormData();
        if (fDocentete?.doPiece) {
          fDocenteteApi.append("doPiece", fDocentete.doPiece);
        }
        fDocenteteApi.append("doStatut", event.target.value);
        const response = await requestApi({
          method: POST,
          path: FDOCENTETES_URL,
          allowError: true,
          token: token,
          body: fDocenteteApi,
          formData: true,
          timeout: 30_000,
        });
        if (response.statusCode === 200) {
          setDoStatut(event.target.value);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setDisabled(false);
      },
      [fDocentete?.doPiece, t, token]
    );

    let doType = SAGE_DO_TYPE_ACHAT_FA;
    if (prop === "bonDeCommande") {
      doType = SAGE_DO_TYPE_ACHAT_LI;
    }

    const handleTransform = React.useCallback(() => {
      if (fastTransform) {
        setTransform((transform: TransformInterface) => {
          transform = getTransform(
            transform,
            fDoclignes,
            arrivage?.data?.fDocenteteLigneArrivages ?? [],
            doType
          );
          transform.running = true;
          return clone(transform);
        });
      } else {
        setOpen(true);
      }
    }, [
      arrivage?.data?.fDocenteteLigneArrivages,
      doType,
      fDoclignes,
      fastTransform,
      setTransform,
    ]);

    useEffect(() => {
      setDoStatut(fDocentete?.doStatut ?? "");
    }, [fDocentetes]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      setFDoclignes(getFDoclignes());
    }, [arrivage.fDoclignes, fDocentete?.doPiece]); // eslint-disable-line react-hooks/exhaustive-deps

    const globalDisabled =
      running || !!updatingArrivage.currentUpdateTime || updatingArrivage.error;

    return (
      <>
        <TableCell
          scope="row"
          colSpan={colSpan}
          sx={{
            padding: 0,
            border: "none",
            ...(showBorderX && {
              borderLeft: "2px " + color + " solid",
              borderRight: "2px " + color + " solid",
            }),
            ...(newDoc && {
              borderTop: "2px " + color + " solid",
              borderBottom: "2px " + color + " dashed",
            }),
          }}
        >
          {newDoc && (
            <>
              {fDocentete && (
                <>
                  {Number(fDocentete.doType) === SAGE_DO_TYPE_ACHAT_FC ? (
                    <Typography sx={{ textAlign: "center" }}>
                      {t("word.fdocentete.doType." + SAGE_DO_TYPE_ACHAT_FC)}
                    </Typography>
                  ) : (
                    <>
                      {FDOCENETETE_STATUS.hasOwnProperty(fDocentete.doType) && (
                        <FormControl size="small" fullWidth={true}>
                          <Select
                            value={doStatut}
                            disabled={running || disabled}
                            onChange={onChangeDoStatut}
                          >
                            {FDOCENETETE_STATUS[fDocentete.doType].map(
                              (v: number) => (
                                <MenuItem value={v} key={v}>
                                  {t(
                                    "word.doStatut." +
                                      fDocentete.doType +
                                      "." +
                                      v
                                  )}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      )}
                    </>
                  )}
                  <UpdateFDocenteteArrivageComponent
                    fDocentete={fDocentete}
                    disabled={running}
                    focusMainSearch={focusMainSearch}
                  />
                </>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <CopyClipboardComponent
                    className="RobotoMono"
                    component="span"
                    text={currentDoPiece}
                  />
                  <CopyClipboardComponent
                    className="RobotoMono"
                    component="span"
                    text={fDocligne.doTiers}
                  />
                </Box>
                {fDocentete &&
                  (arrivage.mandatoryDocuments ?? []).length === 0 && (
                    <LoadingButton
                      variant="text"
                      color="inherit"
                      disabled={
                        running ||
                        !!updatingArrivage.currentUpdateTime ||
                        updatingArrivage.error
                      }
                      sx={{
                        borderRadius: "50%",
                        minWidth: "auto",
                        padding: "12px",
                      }}
                      onClick={() => {
                        updateIgnoredDocument([
                          ...ignoredDocuments,
                          {
                            doPiece: fDocentete.doPiece,
                            doType: fDocentete.doType,
                          },
                        ]);
                      }}
                    >
                      <CloseIcon />
                    </LoadingButton>
                  )}
              </Box>
            </>
          )}
        </TableCell>
        {prop !== "facture" && (
          <TableCell
            scope="row"
            sx={{
              paddingY: 0,
              paddingX: 5,
              border: "none",
            }}
          >
            {newDoc && fDocentete && (
              <>
                <FormTransform
                  running={running}
                  setTransform={setTransform}
                  fDocenteteLigneArrivages={
                    arrivage.data.fDocenteteLigneArrivages ?? []
                  }
                  fDocenteteFounds={fDocenteteFounds}
                  setFDocenteteFounds={setFDocenteteFounds}
                  fDoclignes={fDoclignes}
                  doType={doType}
                  open={open}
                  setOpen={setOpen}
                  updatingArrivage={updatingArrivage}
                />
                <LoadingButton
                  variant="text"
                  color="inherit"
                  sx={{
                    borderRadius: "50%",
                    minWidth: "auto",
                    padding: "12px",
                  }}
                  disabled={globalDisabled}
                  onClick={handleTransform}
                >
                  <TransformIcon
                    color={globalDisabled ? undefined : "primary"}
                  />
                </LoadingButton>
              </>
            )}
          </TableCell>
        )}
      </>
    );
  }
);

const TransformAllButton = React.memo(
  ({
    arrivage,
    doType,
    running,
    fDocenteteFounds,
    setFDocenteteFounds,
    setTransform,
    updatingArrivage,
    fastTransform,
  }: State7) => {
    const getFDoclignes = React.useCallback(() => {
      return (
        arrivage.fDoclignes?.filter(
          (f) => f.doType < SAGE_DO_TYPE_ACHAT_FA && f.found && f.found > 0
        ) ?? []
      );
    }, [arrivage.fDoclignes]);
    const [fDoclignes, setFDoclignes] = React.useState(getFDoclignes());

    const [open, setOpen] = React.useState<boolean>(false);

    const handleTransform = React.useCallback(() => {
      if (fastTransform) {
        setTransform((transform: TransformInterface) => {
          transform = getTransform(
            transform,
            fDoclignes,
            arrivage?.data?.fDocenteteLigneArrivages ?? [],
            doType
          );
          transform.running = true;
          return clone(transform);
        });
      } else {
        setOpen(true);
      }
    }, [
      arrivage?.data?.fDocenteteLigneArrivages,
      doType,
      fDoclignes,
      fastTransform,
      setTransform,
    ]);

    useEffect(() => {
      setFDoclignes(getFDoclignes());
    }, [arrivage.fDoclignes]); // eslint-disable-line react-hooks/exhaustive-deps

    const disabled =
      running || !!updatingArrivage.currentUpdateTime || updatingArrivage.error;
    return (
      <>
        <FormTransform
          running={running}
          setTransform={setTransform}
          fDocenteteLigneArrivages={
            arrivage.data.fDocenteteLigneArrivages ?? []
          }
          fDocenteteFounds={fDocenteteFounds}
          setFDocenteteFounds={setFDocenteteFounds}
          fDoclignes={fDoclignes}
          doType={doType}
          open={open}
          setOpen={setOpen}
          updatingArrivage={updatingArrivage}
        />
        <TableCell
          sx={{
            border: "none",
            textAlign: "center",
          }}
        >
          <LoadingButton
            variant="text"
            color="inherit"
            sx={{
              borderRadius: "50%",
              minWidth: "auto",
              padding: "12px",
            }}
            disabled={disabled}
            onClick={handleTransform}
          >
            <TransformIcon color={disabled ? undefined : "success"} />
          </LoadingButton>
        </TableCell>
      </>
    );
  }
);

const TransformCellSeparator: React.FC<State5> = React.memo(
  ({
    arrivage,
    fDocligne,
    doType,
    action,
    running,
    fDocenteteFounds,
    setFDocenteteFounds,
    setTransform,
    updatingArrivage,
    fastTransform,
  }) => {
    const getStep = React.useCallback(() => {
      return action?.steps.find((s) => s.doType === doType);
    }, [action?.steps, doType]);
    const [step, setStep] = React.useState(getStep());
    const [open, setOpen] = React.useState<boolean>(false);

    const getStepState = React.useCallback((): any => {
      const display = fDocligne.doType < doType || step !== undefined;
      const loading =
        step !== undefined && step.started && step.success === undefined;
      const disabled = !loading && running;
      const stepToDo =
        disabled && step && !loading && step.success === undefined;
      const stepError = step?.success === false;
      const stepSuccess = step?.success === true;
      return {
        display: display,
        loading: loading,
        disabled: disabled,
        stepToDo: stepToDo,
        stepError: stepError,
        stepSuccess: stepSuccess,
      };
    }, [doType, fDocligne.doType, running, step]);
    const [stepState, setStepState] = React.useState<any>(getStepState());

    const handleTransform = React.useCallback(() => {
      if (fastTransform) {
        setTransform((transform: TransformInterface) => {
          transform = getTransform(
            transform,
            [fDocligne],
            arrivage?.data?.fDocenteteLigneArrivages ?? [],
            doType
          );
          transform.running = true;
          return clone(transform);
        });
      } else {
        setOpen(true);
      }
    }, [
      arrivage?.data?.fDocenteteLigneArrivages,
      doType,
      fDocligne,
      fastTransform,
      setTransform,
    ]);

    React.useEffect(() => {
      setStep(getStep());
    }, [action?.steps, fDocligne.doPiece, fDocligne.doType]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setStepState(getStepState());
    }, [step, running]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <FormTransform
          running={running}
          setTransform={setTransform}
          fDocenteteLigneArrivages={
            arrivage.data.fDocenteteLigneArrivages ?? []
          }
          fDocenteteFounds={fDocenteteFounds}
          setFDocenteteFounds={setFDocenteteFounds}
          fDoclignes={[fDocligne]}
          doType={doType}
          open={open}
          setOpen={setOpen}
          updatingArrivage={updatingArrivage}
        />
        <TableCell
          scope="row"
          sx={{
            paddingY: 0,
            paddingX: 2,
            border: "none",
            textAlign: "center",
          }}
        >
          {stepState.display && (
            <>
              {stepState.stepError ? (
                <CloseIcon color="error" />
              ) : (
                <LoadingButton
                  variant="text"
                  color="inherit"
                  sx={{
                    borderRadius: "50%",
                    minWidth: "auto",
                    padding: "12px",
                  }}
                  loading={stepState.loading}
                  disabled={
                    stepState.disabled ||
                    !!updatingArrivage.currentUpdateTime ||
                    updatingArrivage.error
                  }
                  onClick={handleTransform}
                >
                  <Badge
                    color={stepState.stepToDo ? "primary" : undefined}
                    variant="dot"
                  >
                    <TransformIcon />
                  </Badge>
                </LoadingButton>
              )}
            </>
          )}
        </TableCell>
      </>
    );
  }
);

const TableRowFDocligne = React.memo(
  ({
    arrivage,
    fDocligne,
    emplacement,
    transform,
    setTransform,
    fDocenteteFounds,
    setFDocenteteFounds,
    updatingArrivage,
    fastTransform,
    focusMainSearch,
  }: State2) => {
    const action = transform?.actions.find((a) => a.dlNo === fDocligne.dlNo);
    return (
      <TableRow
        id={"fDocligne-" + fDocligne.dlNo}
        className={"fDocligne-arRef-" + fDocligne.arRef}
      >
        <SingleTableRowFDocligne
          fDocligne={fDocligne}
          emplacement={emplacement}
          prop="bonDeCommande"
          focusMainSearch={focusMainSearch}
        />
        <TransformCellSeparator
          arrivage={arrivage}
          fDocligne={fDocligne}
          doType={SAGE_DO_TYPE_ACHAT_LI}
          action={action}
          running={transform.running}
          setTransform={setTransform}
          fDocenteteFounds={fDocenteteFounds}
          setFDocenteteFounds={setFDocenteteFounds}
          updatingArrivage={updatingArrivage}
          fastTransform={fastTransform}
        />
        <SingleTableRowFDocligne
          fDocligne={fDocligne}
          emplacement={emplacement}
          prop="bonDeLivraison"
          focusMainSearch={focusMainSearch}
        />
        <TransformCellSeparator
          arrivage={arrivage}
          fDocligne={fDocligne}
          doType={SAGE_DO_TYPE_ACHAT_FA}
          action={action}
          running={transform.running}
          setTransform={setTransform}
          fDocenteteFounds={fDocenteteFounds}
          setFDocenteteFounds={setFDocenteteFounds}
          updatingArrivage={updatingArrivage}
          fastTransform={fastTransform}
        />
        <SingleTableRowFDocligne
          fDocligne={fDocligne}
          emplacement={emplacement}
          prop="facture"
          focusMainSearch={focusMainSearch}
        />
      </TableRow>
    );
  }
);

const EntreeStockDisplayFDocligneComponent = React.memo(
  React.forwardRef(
    (
      {
        initArrivage,
        focusMainSearch,
        colSpan,
        transform,
        setTransform,
        updatingArrivage,
        fastTransform,
        hideFDoclignes,
        updateIgnoredDocument,
      }: State,
      ref
    ) => {
      const [arrivage, setArrivage] = React.useState<
        ArrivageInterface | undefined | null
      >(initArrivage);
      const { t } = useTranslation();

      const [fDocenteteFounds, setFDocenteteFounds] = React.useState<
        FDocenteteFoundInterface[]
      >([]);

      const getFDoclignesDisplay = React.useCallback(() => {
        if (arrivage?.fDoclignes) {
          let newArrivage: ArrivageInterface | undefined = clone(arrivage);
          if (newArrivage?.fDoclignes) {
            newArrivage.fDoclignes = newArrivage.fDoclignes.filter((f) => {
              return !hideFDoclignes || (f.found ?? 0) > 0;
            });
            newArrivage = addStateFDoclignes(newArrivage);
            return newArrivage?.fDoclignes;
          }
        }
        return arrivage?.fDoclignes;
      }, [arrivage, hideFDoclignes]);

      const [fDoclignesDisplay, setFDoclignesDisplay] = React.useState<
        ArrivageFDocligneInterface[] | undefined
      >(getFDoclignesDisplay());

      useImperativeHandle(ref, () => ({
        updateArrivage(newArrivage: ArrivageInterface) {
          setArrivage(newArrivage);
        },
      }));

      React.useEffect(() => {
        setFDocenteteFounds((x) => {
          const arr: FDocenteteFoundInterface[] = [
            ...x,
            ...(arrivage?.fDoclignes?.map((y: any) => {
              return {
                doType: y.doType,
                doPiece: y.doPiece,
                doTiers: y.doTiers,
              };
            }) ?? []),
          ];
          return arr.filter(
            (a, i) =>
              arr.findIndex(
                (s) => a.doPiece === s.doPiece && a.doType === s.doType
              ) === i
          );
        });
      }, [arrivage?.fDoclignes]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setFDoclignesDisplay(getFDoclignesDisplay());
      }, [arrivage?.fDoclignes, hideFDoclignes]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {arrivage && fDoclignesDisplay && fDoclignesDisplay.length > 0 && (
            <Table size="small" sx={{ width: "auto", marginY: 1 }}>
              <TableHead>
                <TableRow
                  sx={{
                    borderBottom: "2px black dashed",
                  }}
                >
                  <TableCell
                    sx={{
                      border: "none",
                      textAlign: "center",
                    }}
                    colSpan={colSpan}
                  >
                    <Typography>
                      {t("word.fdocentete.doType." + SAGE_DO_TYPE_ACHAT_CC)}
                    </Typography>
                  </TableCell>
                  <TransformAllButton
                    arrivage={arrivage}
                    doType={SAGE_DO_TYPE_ACHAT_LI}
                    running={transform.running}
                    fDocenteteFounds={fDocenteteFounds}
                    setFDocenteteFounds={setFDocenteteFounds}
                    setTransform={setTransform}
                    updatingArrivage={updatingArrivage}
                    fastTransform={fastTransform}
                  />
                  <TableCell
                    sx={{
                      border: "none",
                      textAlign: "center",
                    }}
                    colSpan={colSpan}
                  >
                    <Typography>
                      {t("word.fdocentete.doType." + SAGE_DO_TYPE_ACHAT_LI)}
                    </Typography>
                  </TableCell>
                  <TransformAllButton
                    arrivage={arrivage}
                    doType={SAGE_DO_TYPE_ACHAT_FA}
                    running={transform.running}
                    fDocenteteFounds={fDocenteteFounds}
                    setFDocenteteFounds={setFDocenteteFounds}
                    setTransform={setTransform}
                    updatingArrivage={updatingArrivage}
                    fastTransform={fastTransform}
                  />
                  <TableCell
                    sx={{
                      border: "none",
                      textAlign: "center",
                    }}
                    colSpan={colSpan}
                  >
                    <Typography>
                      {t("word.fdocentete.doType." + SAGE_DO_TYPE_ACHAT_FA)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fDoclignesDisplay.map((fDocligne) => {
                  const emplacement =
                    arrivage.data.fDocenteteLigneArrivages?.find(
                      (f) => f.arRef === fDocligne.arRef
                    )?.emplacement ?? "";
                  return (
                    <React.Fragment key={fDocligne.dlNo}>
                      <TableRow>
                        {["bonDeCommande", "bonDeLivraison", "facture"].map(
                          (prop, indexProp) => (
                            <SingleTableHeader
                              key={indexProp}
                              running={transform.running}
                              ignoredDocuments={arrivage.ignoredDocuments}
                              updateIgnoredDocument={updateIgnoredDocument}
                              colSpan={colSpan}
                              // @ts-ignore
                              prop={prop}
                              fDocligne={fDocligne}
                              fDocentetes={arrivage.fDocentetes}
                              updatingArrivage={updatingArrivage}
                              arrivage={arrivage}
                              setFDocenteteFounds={setFDocenteteFounds}
                              fDocenteteFounds={fDocenteteFounds}
                              setTransform={setTransform}
                              fastTransform={fastTransform}
                              focusMainSearch={focusMainSearch}
                            />
                          )
                        )}
                      </TableRow>
                      <TableRowFDocligne
                        arrivage={arrivage}
                        fDocligne={fDocligne}
                        emplacement={emplacement}
                        transform={transform}
                        setTransform={setTransform}
                        fDocenteteFounds={fDocenteteFounds}
                        setFDocenteteFounds={setFDocenteteFounds}
                        updatingArrivage={updatingArrivage}
                        fastTransform={fastTransform}
                        focusMainSearch={focusMainSearch}
                      />
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </>
      );
    }
  ),
  (prevProps, nextProps) => {
    // const focusMainSearch =
    //   prevProps.focusMainSearch === nextProps.focusMainSearch;
    const colSpan = prevProps.colSpan === nextProps.colSpan;
    const transform = prevProps.transform === nextProps.transform;
    const setTransform = prevProps.setTransform === nextProps.setTransform;
    // const updatingArrivage =
    //   JSON.stringify(prevProps.updatingArrivage) ===
    //   JSON.stringify(nextProps.updatingArrivage);
    const fastTransform = prevProps.fastTransform === nextProps.fastTransform;
    const hideFDoclignes =
      prevProps.hideFDoclignes === nextProps.hideFDoclignes;
    const updateIgnoredDocument =
      prevProps.updateIgnoredDocument === nextProps.updateIgnoredDocument;
    return (
      colSpan &&
      transform &&
      setTransform &&
      // updatingArrivage &&
      fastTransform &&
      hideFDoclignes &&
      updateIgnoredDocument
    );
  }
);

export default EntreeStockDisplayFDocligneComponent;
