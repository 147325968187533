import React, { useImperativeHandle, useRef } from "react";
import { SavInterface } from "../../../interfaces/SavInterface";
import { FDocenteteInterface } from "../../../interfaces/FDocenteteInterface";
import { Grid, Link as LinkCustom } from "@mui/material";
import SavFDocenteteNoveFormComponent from "./SavFDocenteteNoveFormComponent";
import SavFArticleNoveFormComponent from "./SavFArticleNoveFormComponent";
import { UserInterface } from "../../../interfaces/UserInterface";
import Typography from "@mui/material/Typography";
import { PRODUCT_PAGE, RAW_HTML_PAGE } from "../../../utils/RouteUtils";
import { Link } from "react-router-dom";
import DrawerComponent from "../../../components/common/DrawerComponent";
import CopyClipboardComponent from "../../../components/common/CopyClipboardComponent";
import { getUrlPdfDoPiece } from "../../../helpers/FDocenteteHelper";

interface State {
  sav?: SavInterface | null;
  userForm: UserInterface | undefined;
  setSav: Function;
}

const SavOriginNoveFormComponent = React.memo(
  React.forwardRef(({ sav, setSav, userForm }: State, ref) => {
    const [fDocentete, setFDocentete] =
      React.useState<FDocenteteInterface | null>(null);
    const fDocenteteRef: any = useRef();
    const fArticleRef: any = useRef();
    const getDisplaySerialNumber = React.useCallback(() => {
      return sav?.fArticleSerialNumber ?? "";
    }, [sav?.fArticleSerialNumber]);
    const [displaySerialNumber, setDisplaySerialNumber] =
      React.useState<string>(getDisplaySerialNumber());
    const [openDrawer, setOpenDrawer] = React.useState("");

    const handleOpen = React.useCallback((doPiece: string) => {
      setOpenDrawer(doPiece);
    }, []);
    const handleClose = React.useCallback(() => {
      setOpenDrawer("");
    }, []);

    useImperativeHandle(ref, () => ({
      getValue() {
        const fDocenteteValue = fDocenteteRef.current.getValue();
        if (!fDocenteteValue) {
          return undefined;
        }
        const fArticleValue = fArticleRef.current.getValue();
        if (!fArticleValue) {
          return undefined;
        }
        return { ...fDocenteteValue, ...fArticleValue };
      },
    }));

    React.useEffect(() => {
      setDisplaySerialNumber(getDisplaySerialNumber());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {openDrawer !== "" && (
          <DrawerComponent
            open={openDrawer !== ""}
            onClose={handleClose}
            handleDrawerClose={handleClose}
            drawerwidth={window.innerWidth}
            content={
              <iframe
                title="document"
                style={{ width: "100%", height: "91.7vh" }}
                src={
                  window.origin +
                  RAW_HTML_PAGE +
                  "?url=" +
                  getUrlPdfDoPiece(openDrawer)
                }
              />
            }
          />
        )}
        <Grid item xs={12} md={6}>
          <SavFDocenteteNoveFormComponent
            sav={sav}
            setFDocentete={setFDocentete}
            ref={fDocenteteRef}
            userForm={userForm}
            setSav={setSav}
          />
          {fDocentete?.doPiece && (
            <LinkCustom
              sx={{ cursor: "pointer" }}
              onClick={() => handleOpen(fDocentete.doPiece)}
            >
              <Typography>{fDocentete.doPiece}</Typography>
            </LinkCustom>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <SavFArticleNoveFormComponent
            sav={sav}
            fDocentete={fDocentete}
            ref={fArticleRef}
            setDisplaySerialNumber={setDisplaySerialNumber}
          />
          <Typography>
            {sav?.fArticleId && (
              <Link to={PRODUCT_PAGE + "/" + sav.fArticleId}>
                {sav.fArticleId}
              </Link>
            )}
            {displaySerialNumber !== "" && " SN: "}
            <CopyClipboardComponent
              className="RobotoMono"
              component="span"
              text={displaySerialNumber}
            />
          </Typography>
        </Grid>
      </>
    );
  })
);

export default SavOriginNoveFormComponent;
