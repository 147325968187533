import * as React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Typography from "@mui/material/Typography";
import { getOrderWithCart } from "../../../helpers/CartHelper";
import { TextField, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import { OrderInterface } from "../../../interfaces/OrderInterface";
import { LoadingButton } from "@mui/lab";
import { setCart } from "../../../app/globalSlice";
import { toastr } from "react-redux-toastr";
import { PATCH } from "../../../utils/MethodUtils";
import { CART_URL } from "../../../utils/UrlsUtils";
import { requestApi } from "../../../helpers/RequestApi";
import getErrorApi from "../../../helpers/GetErrorApi";

const AddProductByRefComponent: React.FC = React.memo(() => {
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const cart = useAppSelector((state: RootState) => state.globalState.cart);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const [arRefToAdd, setArRefToAdd] = React.useState("");
  const [order, setOrder] = React.useState<OrderInterface | undefined>(
    getOrderWithCart(cart, undefined)
  );
  const { t } = useTranslation();

  const onCartUpdate = React.useCallback(() => {
    setOrder(getOrderWithCart(cart, undefined));
  }, [cart]);

  const handleChangeText = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setArRefToAdd(event.target.value);
    },
    []
  );

  const addRef = React.useCallback(async () => {
    if (!order || !cart) {
      return;
    }
    setLoading(true);
    let exists = false;
    const cartFArticles: any[] = order.orderFArticles.map((orderFArticle) => {
      let quantity = orderFArticle.quantity;
      if (orderFArticle.fArticle.arRef === arRefToAdd) {
        exists = true;
        quantity++;
      }
      return {
        quantity: quantity,
        fArticleId: orderFArticle.fArticle.arRef,
      };
    });
    if (!exists) {
      cartFArticles.push({
        quantity: 1,
        fArticleId: arRefToAdd,
      });
    }
    const response = await requestApi({
      method: PATCH,
      path: CART_URL + "/" + cart.id,
      allowError: true,
      token: token,
      body: {
        cartFArticles: cartFArticles,
      },
    });
    if (response.statusCode === 200) {
      dispatch(setCart(response.content));
      setArRefToAdd("");
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [arRefToAdd, cart, dispatch, order, t, token]);

  React.useEffect(() => {
    onCartUpdate();
  }, [cart]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography component="span">{t("word.addMoreProduct")}</Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", marginLeft: 1 }}>
        <Tooltip title={t("sentence.addMoreProduct")}>
          <InfoIcon />
        </Tooltip>
      </Box>
      <TextField
        fullWidth
        autoComplete="off"
        type="text"
        sx={{ maxWidth: "200px", marginLeft: 1 }}
        value={arRefToAdd}
        onChange={handleChangeText}
      />
      <LoadingButton
        variant="contained"
        sx={{ marginLeft: 1 }}
        loading={loading}
        onClick={addRef}
      >
        {t("word.add")}
      </LoadingButton>
    </Box>
  );
});

export default AddProductByRefComponent;
