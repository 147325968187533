import React from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { Box, Chip, DialogProps, Grid, Tooltip } from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { Link, useSearchParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { set } from "../../../../app/globalSlice";
import { searchParamToObject } from "../../../../helpers/SearchParamHelper";
import { objectToQuery, requestApi } from "../../../../helpers/RequestApi";
import { GET } from "../../../../utils/MethodUtils";
import { FILTER_URL } from "../../../../utils/UrlsUtils";
import DrawerComponent from "../../DrawerComponent";
import { getLocaleDataGrid } from "../../../../helpers/GetLanguage";
import EditIcon from "@mui/icons-material/Edit";
import {
  FilterFilterCategoryInterface,
  FilterFilterInterface,
  FilterFilterValueInterface,
} from "../../../../interfaces/FilterInterface";
import FilterFormComponent from "./form/filterForm/FilterFormComponent";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { CATEGORY_PAGE } from "../../../../utils/RouteUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContent from "@mui/material/DialogContent";
import FilterDeleteComponent from "./form/filterForm/FilterDeleteComponent";
import { TYPE_NUMERIC } from "../../../../utils/FilterUtils";
import AutocompleteCategoryComponent from "../../category/form/AutocompleteCategoryComponent";
import FilterSearchComponent from "../FilterSearchComponent";

const minWidth = 1200;

interface State {
  custom: boolean;
}

const FilterAdminComponent: React.FC<State> = React.memo(({ custom }) => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [searchParams, setSearchParams] = useSearchParams();
  const width = window.innerWidth;
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [defaultItemsPerPage] = React.useState(50);
  const [filters, setFilters] = React.useState<
    FilterFilterInterface[] | undefined
  >(undefined);
  const [filterToDelete, setFilterToDelete] = React.useState<
    FilterFilterInterface | undefined
  >(undefined);
  const [open, setOpen] = React.useState(false);
  const [totalItems, setTotalItems] = React.useState(0);
  const [filter, setFilter] = React.useState<
    FilterFilterInterface | null | undefined
  >(undefined);
  const { t, i18n } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);

  const handleDrawerOpen = React.useCallback(
    (thisFilter: FilterFilterInterface | null) => {
      setFilter(thisFilter);
      setOpen(true);
    },
    []
  );

  const handleDrawerDeleteOpen = React.useCallback(
    (thisFilter: FilterFilterInterface) => {
      setFilterToDelete(thisFilter);
    },
    []
  );

  const handleDrawerDeleteClose = React.useCallback(() => {
    setFilterToDelete(undefined);
  }, []);

  const getColumns = React.useCallback(() => {
    const result: GridColDef[] = [
      {
        field: "id",
        flex: 0,
        headerName: t("column.id"),
        headerClassName: "background-nove",
        filterable: false,
      },
      {
        field: "name",
        flex: custom ? 1 : 0,
        headerName: t("column.name"),
        headerClassName: "background-nove",
        filterable: false,
      },
    ];
    if (!custom) {
      result.push(
        {
          field: "filterCategories",
          headerName: t("column.categories"),
          flex: 1,
          headerClassName: "background-nove",
          filterable: false,
          cellClassName: "flex-wrap-wrap",
          renderCell: (params: GridRenderCellParams) => (
            <>
              {params.row?.filterCategories &&
                params.row.filterCategories.map(
                  (
                    filterCategory: FilterFilterCategoryInterface,
                    indexFilterCategory: number
                  ) => (
                    <Link
                      key={indexFilterCategory}
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                      }}
                      to={CATEGORY_PAGE + "/" + filterCategory.category.slug}
                    >
                      <Chip
                        sx={{ margin: 0.5, cursor: "pointer" }}
                        label={
                          "[" +
                          filterCategory.category.id +
                          "]" +
                          filterCategory.category.name
                        }
                        color="primary"
                      />
                    </Link>
                  )
                )}
            </>
          ),
        },
        {
          field: "filterValues",
          headerName: t("column.values"),
          flex: 1,
          headerClassName: "background-nove",
          filterable: false,
          cellClassName: "flex-wrap-wrap",
          renderCell: (params: GridRenderCellParams) => (
            <>
              {params.row?.type !== TYPE_NUMERIC
                ? params.row?.filterValues.map(
                    (
                      filterValue: FilterFilterValueInterface,
                      indexFilterValue: number
                    ) => (
                      <Chip
                        key={indexFilterValue}
                        sx={{ margin: 0.5 }}
                        label={filterValue.name}
                        color="primary"
                        variant="outlined"
                      />
                    )
                  )
                : t("word.filter.type." + params.row.type)}
            </>
          ),
        }
      );
    } else {
      result.push({
        field: "onlyAdmin",
        flex: custom ? 1 : 0,
        headerName: t("word.onlyAdmin"),
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.row.onlyAdmin ? (
              <DoneIcon color="success" />
            ) : (
              <CloseIcon color="error" />
            )}
          </>
        ),
      });
    }
    if (!custom) {
      result.push({
        field: "reference",
        flex: custom ? 1 : 0,
        headerName: t("word.reference"),
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const isRef: any = {
            yes: [],
            no: [],
          };
          for (const filterCategory of params.row.filterCategories) {
            if (filterCategory.reference) {
              isRef.yes.push(filterCategory.category.name);
            } else {
              isRef.no.push(filterCategory.category.name);
            }
          }
          return (
            <>
              {isRef.yes.length > 0 && (
                <Tooltip title={isRef.yes.join(", ")}>
                  <DoneIcon color="success" />
                </Tooltip>
              )}
              {isRef.no.length > 0 && (
                <Tooltip title={isRef.no.join(", ")}>
                  <CloseIcon color="error" />
                </Tooltip>
              )}
            </>
          );
        },
      });
      result.push({
        field: "nbFArticles",
        flex: 0,
        headerName: t("word.quantity"),
        headerClassName: "background-nove",
        filterable: false,
        sortable: true,
      });
    }
    result.push({
      field: "actions",
      headerName: t("column.actions"),
      flex: 0,
      headerClassName: "background-nove",
      filterable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconButton onClick={() => handleDrawerOpen(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDrawerDeleteOpen(params.row)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    });
    return result;
  }, [custom, handleDrawerOpen, handleDrawerDeleteOpen, t]);
  const [columns] = React.useState<GridColDef[]>(getColumns());
  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (filters !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (!searchParamsObject.hasOwnProperty("page")) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (!force) {
        let hasOrder = false;
        for (const [key] of Object.entries(searchParamsObject)) {
          if (key.startsWith("order")) {
            hasOrder = true;
            break;
          }
        }
        if (!hasOrder) {
          searchParamsObject["order[id]"] = "desc";
          hasChanged = true;
        }
      }
      if (hasChanged) {
        dispatch(set({ refreshPage: false }));
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        return;
      }
      const response = await requestApi({
        method: GET,
        path:
          FILTER_URL +
          objectToQuery({ ...searchParamsObject, ...{ custom: custom } }),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        let maxId = Math.max(
          ...response.content["hydra:member"].map(
            (o: FilterFilterInterface) => o.id
          )
        );
        const newFilters = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          ).map(() => {
            maxId++;
            return {
              id: maxId,
            };
          }),
          ...response.content["hydra:member"],
        ];
        setFilters(newFilters);
        setTotalItems(response.content["hydra:totalItems"]);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [
      custom,
      defaultItemsPerPage,
      dispatch,
      filters,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
    ]
  );

  const handleDrawerClose = React.useCallback(() => {
    setFilter(undefined);
    setOpen(false);
  }, []);

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setFilters(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setFilters(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onSortModelChange = React.useCallback(
    (model: GridSortModel) => {
      if (!init) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          delete searchParamsObject[key];
        }
      }
      for (const sort of model) {
        // @ts-ignore
        searchParamsObject["order[" + sort.field + "]"] = sort.sort;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const getSortModel = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    const result: any[] = [];
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (key.startsWith("order")) {
        let field: string[] | string = key.split("[");
        field = field[1].replace("]", "");
        result.push({
          field: field,
          sort: value,
        });
      }
    }
    return result;
  }, [searchParams]);

  const onFilterDeleted = React.useCallback(() => {
    handleDrawerDeleteClose();
    dispatch(set({ refreshPage: true }));
  }, [dispatch, handleDrawerDeleteClose]);

  const onFilterCreatedUpdated = React.useCallback(() => {
    handleDrawerClose();
    dispatch(set({ refreshPage: true }));
  }, [dispatch, handleDrawerClose]);

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!user) {
      setSearchParams(
        {},
        {
          replace: true,
        }
      );
    }
    if (init && !refreshPage) {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams, user?.userIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      <DrawerComponent
        open={open}
        handleDrawerClose={handleDrawerClose}
        onClose={handleDrawerClose}
        drawerwidth={width < minWidth ? width : minWidth}
        content={
          <FilterFormComponent
            filter={filter}
            successFunction={onFilterCreatedUpdated}
          />
        }
      />
      <Dialog
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        onClose={handleDrawerDeleteClose}
        open={!!filterToDelete}
      >
        <DialogContent>
          <FilterDeleteComponent
            filter={filterToDelete}
            successFunction={onFilterDeleted}
            close={handleDrawerDeleteClose}
          />
        </DialogContent>
      </Dialog>
      <Box>
        {!custom && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid container spacing={1} sx={{ marginBottom: 1 }}>
              <Grid item xs={6}>
                <AutocompleteCategoryComponent />
              </Grid>
              <Grid item xs={6}>
                <FilterSearchComponent
                  init={true}
                  placeholder={t("word.search")}
                  updateSearchWithQueryParam={true}
                />
              </Grid>
            </Grid>
            <Box sx={{ marginLeft: 1 }}>
              <LoadingButton
                variant="contained"
                onClick={() => handleDrawerOpen(null)}
              >
                {t("word.filter.create")}
              </LoadingButton>
            </Box>
          </Box>
        )}
        {searchParamsObject.itemsPerPage && searchParamsObject.page && (
          // https://mui.com/x/react-data-grid/components/#pagination
          <DataGrid
            initialState={{
              sorting: {
                sortModel: getSortModel(),
              },
            }}
            getRowHeight={() => "auto"}
            loading={refreshPage}
            rows={refreshPage ? [] : filters ?? []}
            onSortModelChange={onSortModelChange}
            sortingMode="server"
            page={Number(searchParamsObject.page) - 1}
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={Number(searchParamsObject.itemsPerPage)}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            rowCount={totalItems}
            columns={columns}
            autoHeight={true}
            disableExtendRowFullWidth={true}
            localeText={getLocaleDataGrid(i18n.language)}
          />
        )}
      </Box>
    </>
  );
});

export default FilterAdminComponent;
