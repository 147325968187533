import React from "react";
import {
  getNbDayTransformPlInDe,
  getUrlPdfDoPiece,
} from "../../../helpers/FDocenteteHelper";
import { RAW_HTML_PAGE } from "../../../utils/RouteUtils";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { requestApi } from "../../../helpers/RequestApi";
import { GET, PATCH } from "../../../utils/MethodUtils";
import {
  FDOCENTETES_ELASTIC_SEARCH_URL,
  FDOCENTETES_NBDAYTRANSFORMPLINDE_URL,
  FDOCENTETES_URL,
} from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { set } from "../../../app/globalSlice";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { Card, CardContent, Grid, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import { InputInterface } from "../../../interfaces/InputInterface";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Box from "@mui/material/Box";
import { FDocenteteInterface } from "../../../interfaces/FDocenteteInterface";
import Typography from "@mui/material/Typography";
import {
  addDays,
  isAfter,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from "date-fns";

export interface State {
  doPiece: string;
  handleClose: Function;
}

interface FormState {
  date: InputInterface;
}

const FDocenteteConsoleComptoirComponent: React.FC<State> = React.memo(
  ({ doPiece, handleClose }) => {
    const { t, i18n } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = React.useState(false);
    const [nbDayTransformPlInDe, setNbDayTransformPlInDe] = React.useState<
      number | null
    >(null);
    const [loadingElasticSearch, setLoadingElasticSearch] =
      React.useState(false);
    const [fDocentete, setFDocentete] = React.useState<
      FDocenteteInterface | undefined
    >(undefined);
    const getDefaultValues = React.useCallback((): FormState => {
      let thisDate: string | Date | null =
        fDocentete?.dateTransformDevis ?? null;
      if (thisDate !== null) {
        thisDate = new Date(thisDate);
        if (isAfter(new Date("2000-01-01"), thisDate)) {
          thisDate = null;
        } else {
          thisDate = new Date(
            thisDate.getTime() + thisDate.getTimezoneOffset() * 60000
          );
        }
      }
      return {
        date: {
          value: thisDate,
          error: "",
        },
      };
    }, [fDocentete?.dateTransformDevis]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const updateFDoceneteteElasticSearch = React.useCallback(async () => {
      const response = await requestApi({
        method: PATCH,
        path: FDOCENTETES_ELASTIC_SEARCH_URL.replace("{doPiece}", doPiece),
        allowError: false,
        token: token,
        body: {},
      });
      if (response.statusCode === 200) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.fdocentete_updated")
        );
        handleClose();
        dispatch(set({ refreshPage: true }));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
        setLoadingElasticSearch(false);
      }
    }, [dispatch, handleClose, doPiece, t, token]);

    const loadDataNbDayTransformPlInDe = React.useCallback(async () => {
      if (!doPiece) {
        return;
      }
      const response = await requestApi({
        method: GET,
        path: FDOCENTETES_NBDAYTRANSFORMPLINDE_URL.replace(
          "{doPiece}",
          doPiece
        ),
        allowError: false,
        token: token,
      });
      if (response.statusCode === 200) {
        setFDocentete(response.content.fDocentete);
        setNbDayTransformPlInDe(
          getNbDayTransformPlInDe(
            response.content.fDocentete,
            response.content.user,
            response.content.userProp,
            false
          )
        );
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
    }, [doPiece, t, token]);

    const updateDateTransformDevis = React.useCallback(async () => {
      const date = new Date(values.date.value);
      if (!doPiece) {
        return;
      }
      const now = setMilliseconds(
        setSeconds(setMinutes(setHours(new Date(), 23), 59), 59),
        0
      );
      if (values.date.value !== null && isAfter(now, date)) {
        setValues((v) => {
          v.date.error = t("error.dateAfterToday");
          return { ...v };
        });
        return;
      }
      setLoading(true);
      const response = await requestApi({
        method: PATCH,
        path: FDOCENTETES_URL + "/" + doPiece,
        allowError: false,
        token: token,
        body: {
          dateTransformDevis:
            values.date.value !== null
              ? values.date.value.getFullYear() +
                "-" +
                (values.date.value.getMonth() + 1) +
                "-" +
                values.date.value.getDate()
              : null,
        },
      });
      if (response.statusCode === 200) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.fdocentete_updated")
        );
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [doPiece, t, token, values.date.value]);

    const handleChangeDate = React.useCallback(
      (prop: keyof FormState) => (newValue: Date | null) => {
        setValues((v) => {
          return {
            ...v,
            [prop]: {
              ...v[prop],
              value: newValue,
              error: "",
            },
          };
        });
      },
      []
    );

    React.useEffect(() => {
      setFDocentete(undefined);
      setNbDayTransformPlInDe(null);
      loadDataNbDayTransformPlInDe();
    }, [doPiece]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [fDocentete]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Grid container spacing={1} sx={{ padding: 1 }}>
          <Grid item xs={12} md={9}>
            <iframe
              title="document"
              style={{ width: "100%", height: "90vh" }}
              src={
                window.origin +
                RAW_HTML_PAGE +
                "?url=" +
                getUrlPdfDoPiece(doPiece)
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <LoadingButton
              variant="contained"
              loading={loadingElasticSearch}
              onClick={updateFDoceneteteElasticSearch}
            >
              {t("word.updateElasticSearch")}
            </LoadingButton>
            {fDocentete && nbDayTransformPlInDe !== null && (
              <Card variant="outlined" sx={{ marginTop: 1 }}>
                <CardContent>
                  <>
                    <Typography>
                      {t("sentence.shouldTransformPlInDe").replace(
                        "%date%",
                        addDays(
                          new Date(),
                          nbDayTransformPlInDe
                        ).toLocaleDateString(i18n.language, {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          timeZone: "Etc/UTC",
                        })
                      )}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={3} sx={{ width: "100%" }}>
                        <DesktopDatePicker
                          label={t("field.dateDeletionDevis")}
                          // @ts-ignore
                          format="dd/MM/yyyy"
                          // @ts-ignore
                          inputFormat="dd/MM/yyyy"
                          value={values.date.value}
                          onChange={handleChangeDate("date")}
                          // @ts-ignore
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              error={!!values.date.error}
                              helperText={t(values.date.error ?? "")}
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </>
                  <Box sx={{ textAlign: "right", marginTop: 1 }}>
                    <LoadingButton
                      variant="contained"
                      onClick={updateDateTransformDevis}
                      loading={loading}
                    >
                      {t("word.save")}
                    </LoadingButton>
                  </Box>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
);

export default FDocenteteConsoleComptoirComponent;
