import React, { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { set } from "../../../app/globalSlice";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { ContactInterface } from "../../../interfaces/ContactInterface";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { getLocaleDataGrid } from "../../../helpers/GetLanguage";
import { useContainerDimensions } from "../useContainerDimensions";
import DrawerComponent from "../../../components/common/DrawerComponent";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET, PATCH } from "../../../utils/MethodUtils";
import { CONTACT_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { Box, Container, DialogProps, Tooltip, useTheme } from "@mui/material";
import LoginComponent from "../../../components/common/user/LoginComponent";
import ContentComponent from "../../../components/content/ContentComponent";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { useSearchParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { LoadingButton } from "@mui/lab";
import FilterSearchComponent from "../filter/FilterSearchComponent";
import FeedbackFormComponent from "./form/FeedbackFormComponent";
import FeedbackFilterStateComponent from "./filter/FeedbackFilterStateComponent";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import getErrorApi from "../../../helpers/GetErrorApi";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import FeedbackMarkUnreadComponent from "./form/FeedbackMarkUnreadComponent";
import CopyClipboardComponent from "../CopyClipboardComponent";

const clone = require("clone");

const minWidth = 900;

interface State {
  asAdmin: boolean;
}

const FeedbackComponent: React.FC<State> = React.memo(({ asAdmin }) => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const newContactMessage = useAppSelector(
    (state: RootState) => state.globalState.newContactMessage
  );
  const updateContact = useAppSelector(
    (state: RootState) => state.globalState.updateContact
  );
  const theme = useTheme();
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [searchParams, setSearchParams] = useSearchParams();
  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [canApplyFilter, setCanApplyFilter] = React.useState(false);
  const [defaultItemsPerPage] = React.useState(asAdmin ? 50 : 10);
  const [contacts, setContacts] = React.useState<
    ContactInterface[] | undefined
  >(undefined);
  const [open, setOpen] = React.useState(false);
  const [totalItems, setTotalItems] = React.useState(0);
  const [contact, setContact] = React.useState<
    ContactInterface | null | undefined
  >(undefined);
  const [contactUnread, setContactUnread] = React.useState<
    ContactInterface | null | undefined
  >(undefined);
  const { t, i18n } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const newMessageAdmin = useAppSelector(
    (state: RootState) => state.globalState.newMessageAdmin
  );
  const newMessageUser = useAppSelector(
    (state: RootState) => state.globalState.newMessageUser
  );
  const handleDrawerOpen = React.useCallback(
    (thisContact: ContactInterface | null) => {
      setContact(thisContact);
      setOpen(true);
    },
    []
  );
  const handleDialogUnreadOpen = React.useCallback(
    (thisContact: ContactInterface | null) => {
      setContactUnread(thisContact);
    },
    []
  );
  const handleDialogUnreadClose = React.useCallback(() => {
    setContactUnread(undefined);
  }, []);
  const resetNbMessage = React.useCallback(async () => {
    if (!contact) {
      return;
    }
    let field = "";
    if (asAdmin) {
      if (contact.newMessageAdmin > 0) {
        field = "newMessageAdmin";
      }
    } else if (contact.newMessageUser > 0) {
      field = "newMessageUser";
    }
    if (field === "") {
      return;
    }
    const response = await requestApi({
      method: PATCH,
      path: CONTACT_URL + "/" + contact.id,
      allowError: false,
      token: token,
      body: {
        [field]: 0,
      },
    });
    if (response.statusCode === 200) {
      setContacts((x) => {
        if (!x) {
          return x;
        }
        const index = x?.findIndex((x) => x.id === response.content.id);
        if (index >= 0) {
          const result = clone(x);
          result[index][field] = 0;
          return result;
        }
        return x;
      });
      if (asAdmin && newMessageAdmin && newMessageAdmin > 0) {
        dispatch(set({ newMessageAdmin: newMessageAdmin - 1 }));
      } else if (newMessageUser && newMessageUser > 0) {
        dispatch(set({ newMessageUser: newMessageUser - 1 }));
      }
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
  }, [asAdmin, contact, dispatch, newMessageAdmin, newMessageUser, t, token]);
  const getColumns = React.useCallback((): GridColDef[] => {
    let columns: GridColDef[] = [
      {
        field: "id",
        headerName: t("column.id"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
      },
      {
        field: "type",
        headerName: t("column.type"),
        flex: 1,
        headerClassName: "background-nove",
        renderCell: (params: GridRenderCellParams) =>
          t("word.contact.type." + params.row.type),
      },
      {
        field: "subject",
        headerName: t("column.subject"),
        flex: 1,
        headerClassName: "background-nove",
      },
      {
        field: "status",
        headerName: t("column.state"),
        flex: 0,
        headerClassName: "background-nove",
        renderCell: (params: GridRenderCellParams) =>
          t("word.contact.status." + params.row.status),
      },
      {
        field: "nbMessage",
        headerName: t("column.nbMessage"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
      },
      {
        field: "newMessage",
        headerName: t("column.hasNewMessage"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => {
          let newMessage = params.row.newMessageUser;
          if (asAdmin) {
            newMessage = params.row.newMessageAdmin;
          }
          return newMessage > 0 ? (
            <DoneIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          );
        },
      },
      {
        field: "actions",
        headerName: t("column.actions"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <IconButton onClick={() => handleDrawerOpen(params.row)}>
              <VisibilityIcon />
            </IconButton>
            {asAdmin && params.row.newMessageAdmin === 0 && (
              <Tooltip title={t("word.markUnread")}>
                <IconButton onClick={() => handleDialogUnreadOpen(params.row)}>
                  <MarkChatUnreadIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        ),
      },
    ];

    if (asAdmin) {
      const index = 1;
      columns = [
        // part of the array before the specified index
        ...columns.slice(0, index),
        // inserted item
        {
          field: "userIdentifier",
          headerName: t("column.userIdentifier"),
          flex: 0,
          headerClassName: "background-nove",
          filterable: false,
          renderCell: (params: GridRenderCellParams) => (
            <CopyClipboardComponent
              className="RobotoMono"
              component="span"
              text={params.row.userIdentifier ?? ""}
            />
          ),
        },
        // part of the array after the specified index
        ...columns.slice(index),
      ];
    }
    return columns;
  }, [asAdmin, handleDialogUnreadOpen, handleDrawerOpen, t]);
  const [columns, setColumns] = React.useState<GridColDef[]>(getColumns());
  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (contacts !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (!searchParamsObject.hasOwnProperty("page")) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (!asAdmin && !searchParamsObject.hasOwnProperty("userIdentifier")) {
        searchParamsObject.userIdentifier = user?.userIdentifier;
        hasChanged = true;
      }
      if (!force) {
        let hasOrder = false;
        for (const [key] of Object.entries(searchParamsObject)) {
          if (key.startsWith("order")) {
            hasOrder = true;
            break;
          }
        }
        if (!hasOrder) {
          searchParamsObject["order[id]"] = "desc";
          hasChanged = true;
        }
      }
      if (hasChanged) {
        dispatch(set({ refreshPage: false }));
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        return;
      }
      const response = await requestApi({
        method: GET,
        path: CONTACT_URL + objectToQuery(searchParamsObject),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        let maxId = Math.max(
          ...response.content["hydra:member"].map((o: ContactInterface) => o.id)
        );
        const newContacts = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          ).map(() => {
            maxId++;
            return {
              id: maxId,
            };
          }),
          ...response.content["hydra:member"],
        ];
        setContacts(newContacts);
        setTotalItems(response.content["hydra:totalItems"]);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      setCanApplyFilter(true);
      dispatch(set({ refreshPage: false }));
    },
    [
      asAdmin,
      defaultItemsPerPage,
      dispatch,
      contacts,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
    ]
  );
  const onUpdateContact = React.useCallback(() => {
    setContacts((x) => {
      if (!x || !updateContact) {
        return x;
      }
      const index = x?.findIndex((x) => x.id === updateContact.id);
      if (index >= 0) {
        const result = clone(x);
        result[index].status = updateContact.status;
        return result;
      }
      return x;
    });
  }, [updateContact]);

  const handleDrawerClose = React.useCallback(() => {
    setContact(undefined);
    setOpen(false);
  }, []);

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setContacts(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setContacts(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onSortModelChange = React.useCallback(
    (model: GridSortModel) => {
      if (!init) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          delete searchParamsObject[key];
        }
      }
      for (const sort of model) {
        let field = sort.field;
        if (field === "newMessage") {
          field = "newMessageUser";
          if (asAdmin) {
            field = "newMessageAdmin";
          }
        }
        searchParamsObject["order[" + field + "]"] = sort.sort;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [asAdmin, init, searchParams, setSearchParams]
  );

  const getSortModel = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    const result: any[] = [];
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (key.startsWith("order")) {
        let field: string[] | string = key.split("[");
        field = field[1].replace("]", "");
        result.push({
          field: field,
          sort: value,
        });
      }
    }
    return result;
  }, [searchParams]);

  const onContactCreatedUpdated = React.useCallback(
    (contact: ContactInterface) => {
      handleDrawerClose();
      dispatch(set({ refreshPage: true }));
    },
    [dispatch, handleDrawerClose]
  );

  const onNewContactMessage = React.useCallback(() => {
    setContacts((x) => {
      if (!x || !newContactMessage) {
        return x;
      }
      const index = x?.findIndex((x) => x.id === newContactMessage.contact.id);
      if (index >= 0) {
        const result = clone(x);
        result[index] = newContactMessage.contact;
        return result;
      }
      return x;
    });
  }, [newContactMessage]);

  const onContactsChange = React.useCallback(() => {
    if (contact) {
      const newContact = contacts?.find((x) => x.id === contact.id);
      if (newContact) {
        setContact(newContact);
      }
    }
  }, [contact, contacts]);

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setColumns(getColumns());
  }, [asAdmin]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!user) {
      setSearchParams(
        {},
        {
          replace: true,
        }
      );
    }
    if (init && !refreshPage) {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams, user?.userIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    onNewContactMessage();
  }, [newContactMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    onContactsChange();
  }, [contacts]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    resetNbMessage();
  }, [contact?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    onUpdateContact();
  }, [updateContact]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      {!asAdmin && (
        <ContentComponent
          contentName="feedback"
          saveLocalStorage={true}
          preventChangeUrlTab={true}
        />
      )}
      <LoginComponent redirect={null} requireAdmin={asAdmin}>
        <Dialog
          maxWidth={maxWidth}
          fullScreen={fullScreen}
          onClose={handleDialogUnreadClose}
          open={!!contactUnread}
        >
          <FeedbackMarkUnreadComponent
            handleClose={handleDialogUnreadClose}
            contact={contactUnread}
          />
        </Dialog>
        <DrawerComponent
          open={open}
          handleDrawerClose={handleDrawerClose}
          onClose={handleDrawerClose}
          drawerwidth={width < minWidth ? width : minWidth}
          content={
            <FeedbackFormComponent
              successFunction={onContactCreatedUpdated}
              contact={contact}
              asAdmin={asAdmin}
            />
          }
        />
        <Box ref={componentRef}>
          <Container sx={{ marginY: 2 }}>
            {asAdmin && (
              <>
                <FeedbackFilterStateComponent init={canApplyFilter} />
                <FilterSearchComponent
                  init={canApplyFilter}
                  placeholder="word.userIdentifier"
                />
              </>
            )}
            <Box sx={{ marginBottom: 1, marginTop: 2 }}>
              <LoadingButton
                variant="contained"
                onClick={() => handleDrawerOpen(null)}
              >
                {t("word.feedback.create")}
              </LoadingButton>
            </Box>
            {searchParamsObject.itemsPerPage && searchParamsObject.page && (
              // https://mui.com/x/react-data-grid/components/#pagination
              <DataGrid
                initialState={{
                  sorting: {
                    sortModel: getSortModel(),
                  },
                }}
                loading={refreshPage}
                rows={refreshPage ? [] : contacts ?? []}
                onSortModelChange={onSortModelChange}
                sortingMode="server"
                page={Number(searchParamsObject.page) - 1}
                rowsPerPageOptions={[10, 25, 50]}
                pageSize={Number(searchParamsObject.itemsPerPage)}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onPageChange}
                rowCount={totalItems}
                columns={columns}
                autoHeight={true}
                disableExtendRowFullWidth={true}
                localeText={getLocaleDataGrid(i18n.language)}
              />
            )}
          </Container>
        </Box>
      </LoginComponent>
    </>
  );
});

export default FeedbackComponent;
