import React, { useRef } from "react";
import Layout from "../components/common/Layout";
import { set } from "../app/globalSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CategoryInterface } from "../interfaces/CategoryInterface";
import { FARTICLE_URL } from "../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import FilterProductsComponent from "../components/common/category/FilterProductsComponent";
import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  Skeleton,
} from "@mui/material";
import { FArticleSmallInterface } from "../interfaces/FArticleInterface";
import { FArticleSmallComponent } from "../components/common/fArticle/FArticleSmallComponent";
import { groupArray } from "../helpers/GroupHelper";
import { getTitle, searchParamToObject } from "../helpers/SearchParamHelper";
import SearchComponent from "../components/common/SearchComponent";
import Typography from "@mui/material/Typography";
import { GET } from "../utils/MethodUtils";
import { objectToQuery, requestApi } from "../helpers/RequestApi";
import CategoryBreadcrumbComponent from "../components/common/fArticle/CategoryBreadcrumbComponent";
import {
  STORAGE_ICONS,
  STORAGE_INDEXABLE,
  STORAGE_INFINIT_SCROLL,
  STORAGE_INLINE_FARTICLE_CATEGORY,
  STORAGE_NB_GROUP,
  STYLE_INLINE,
} from "../utils/StorageUtils";
import {
  ELASTIC_STOCK_AVAILABLE,
  getInline,
  getNbGroup,
  getStyleInline,
  STYLE_COMPACT_INLINE,
} from "../utils/FArticleUtils";
import { PRODUCT_PAGE } from "../utils/RouteUtils";
import CustomPaginationComponent from "../components/common/CustomPaginationComponent";
import FArticleHeaderComponent from "../components/common/fArticle/FArticleHeaderComponent";
import SortProductsComponent from "../components/common/category/SortProductsComponent";
import FilterReferenceHeaderComponent from "../components/common/FilterReferenceHeaderComponent";
import ConfigureViewProductsComponent from "../components/common/category/ConfigureViewProductsComponent";
import Button from "@mui/material/Button";
import { customRayonSearchChanged } from "../helpers/FilterHelper";
import ContentComponent from "../components/content/ContentComponent";

export interface GroupFArticleInterface {
  title: string | undefined;
  subTitle: string | undefined;
  fArticles: FArticleSmallInterface[];
}

const clone = require("clone");

const ProductsScreen: React.FC = React.memo(() => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const categories = useAppSelector(
    (state: RootState) => state.globalState.categories
  );
  const isAdmin = useAppSelector(
    (state: RootState) => state.globalState.isAdmin
  );
  const showIcons = useAppSelector(
    (state: RootState) => state.globalState.showIcons
  );
  const infinitScroll = useAppSelector(
    (state: RootState) => state.globalState.infinitScroll
  );
  const [scrollArticle, setScrollArticle] = React.useState<string | undefined>(
    () => {
      const searchParamsObject = searchParamToObject(searchParams);
      return searchParamsObject.scrollArticle;
    }
  );
  const getCategory = React.useCallback(() => {
    if (params.slug && categories) {
      for (const parentCategory of categories) {
        if (parentCategory?.children) {
          for (const childCategory of parentCategory.children) {
            if (params.slug === childCategory.slug) {
              return childCategory;
            }
          }
        }
      }
    }
    return undefined;
  }, [categories, params.slug]);
  const [category, setCategory] = React.useState<CategoryInterface | undefined>(
    getCategory()
  );
  const [totalItems, setTotalItems] = React.useState<number | undefined>(
    undefined
  );
  const [hasCategory, setHasCategory] = React.useState<boolean | undefined>(
    undefined
  );
  const [nbGroup, setNbGroup] = React.useState<number>(getNbGroup());
  const [styleInline, setStyleInline] = React.useState<string>(
    getStyleInline()
  );
  const [inline, setInline] = React.useState<boolean>(getInline() !== "0");
  const [isSearching, setIsSearching] = React.useState<boolean>(true);
  const [single, setSingle] = React.useState<boolean>(false);
  const [loadingTop, setLoadingTop] = React.useState<boolean>(false);
  const [loadingBottom, setLoadingBottom] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(1);
  const [nbPage, setNbPage] = React.useState<number | undefined>(undefined);
  const [groupFArticles, setGroupFArticles] = React.useState<
    GroupFArticleInterface[] | undefined
  >(undefined);
  const [open, setOpen] = React.useState(false);
  const [aggregations, setAggregations] = React.useState<any>(null);
  const [nbSelected, setNbSelected] = React.useState<number>(0);
  const [prevScroll, setPrevScroll] = React.useState<any | undefined>(
    undefined
  );
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [initSort, setInitSort] = React.useState(false);
  const [initFilters, setInitFilters] = React.useState(false);
  const [pagesToLoad, setPagesToLoad] = React.useState<number[]>([]);
  const [ignorePages, setIgnorePages] = React.useState<number[]>([]);
  const { t } = useTranslation();
  const filterRef: any = useRef();
  const sortRef: any = useRef();
  const configureRef: any = useRef();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const navigate = useNavigate();
  const [oldSearchParams, setOldSearchParams] = React.useState<any>({});

  const handleChangePage = React.useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = value;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [searchParams, setSearchParams]
  );

  const handleInline = React.useCallback((value: boolean) => {
    setInline(value);
  }, []);

  const handleNbGroup = React.useCallback((value: number) => {
    setNbGroup(value);
  }, []);

  const handleStyleInline = React.useCallback((value: string) => {
    setStyleInline(value);
  }, []);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const paramsHasChanged = React.useCallback(
    (currentSearchParams: any, newSearchParams: any) => {
      const cloneCurrentSearchParams: any = clone(currentSearchParams);
      const cloneNewSearchParams: any = clone(newSearchParams);
      delete cloneCurrentSearchParams.page;
      delete cloneNewSearchParams.page;
      return (
        new URLSearchParams(cloneCurrentSearchParams).toString() !==
        new URLSearchParams(cloneNewSearchParams).toString()
      );
    },
    []
  );

  const apply = React.useCallback(() => {
    if (!init) {
      return;
    }
    const current = searchParamToObject(searchParams);
    const newSearchParams = {
      ...filterRef.current?.getValue(),
      page: current.page ?? 1,
      itemsPerPage: current.itemsPerPage ?? 20,
    };
    for (const key in newSearchParams) {
      if (key.startsWith("order")) {
        delete newSearchParams[key];
      }
    }
    if (sortRef.current) {
      const sort = sortRef.current.getValue() ?? "";
      if (sort !== "") {
        const sortArray = sort.split("|");
        newSearchParams["order[" + sortArray[0] + "]"] = sortArray[1];
      }
    }
    if (paramsHasChanged(current, newSearchParams)) {
      if (!infinitScroll && !scrollArticle) {
        newSearchParams.page = 1;
      }
      setIgnorePages([]);
    }
    delete newSearchParams.single;
    setSearchParams(newSearchParams, {
      replace: true,
    });
    setOpen(false);
  }, [
    infinitScroll,
    init,
    paramsHasChanged,
    scrollArticle,
    searchParams,
    setSearchParams,
  ]);

  const initSearchParam = React.useCallback(() => {
    let searchParamsObject = searchParamToObject(searchParams);
    if (
      !searchParamsObject.hasOwnProperty("stock") ||
      isNaN(searchParamsObject.stock)
    ) {
      searchParamsObject.stock = ELASTIC_STOCK_AVAILABLE;
    }
    if (!searchParamsObject.hasOwnProperty("indexable")) {
      let indexable = localStorage.getItem(STORAGE_INDEXABLE);
      if (indexable === null) {
        indexable = "1";
      }
      if (isAdmin) {
        if (indexable !== "") {
          searchParamsObject.indexable = indexable;
        }
      } else {
        delete searchParamsObject.indexable;
      }
    }
    if (!searchParamsObject.hasOwnProperty("page")) {
      searchParamsObject.page = 1;
    }
    if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
      searchParamsObject.itemsPerPage = 20;
    }
    setSearchParams(searchParamsObject, {
      replace: true,
    });
  }, [isAdmin, searchParams, setSearchParams]);

  const load = React.useCallback(
    async (force: boolean = false) => {
      if (hasCategory !== undefined && !force) {
        dispatch(set({ refreshPage: false }));
        setInit(true);
        return;
      }
      const newCategory = getCategory();
      if (newCategory?.id !== category?.id) {
        setCategory(newCategory);
      }
      initSearchParam();
      dispatch(set({ refreshPage: false }));
      setInit(true);
      const thisHasCategory = newCategory?.id !== undefined;
      setHasCategory(thisHasCategory); // we must set HasCategory after initSearchParam because otherwise the filter are not pre-filled
      if (!thisHasCategory) {
        setInitSort(true); // we must set HasCategory after initSearchParam because otherwise the filter are not pre-filled
      }
    },
    [category?.id, dispatch, getCategory, hasCategory, initSearchParam]
  );

  const getSearchQueryParam = React.useCallback((): any => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchParamsObject = searchParamToObject(urlParams);
    delete searchParamsObject.single;
    if (category?.id) {
      searchParamsObject["categories[]"] = category?.id;
    }
    delete searchParamsObject.scrollArticle;
    return searchParamsObject;
  }, [category?.id]);

  const search = React.useCallback(async () => {
    if (!init || !initSort || !initFilters) {
      return;
    }
    setIsSearching(true);
    setTimeout(async () => {
      const thisSingle = searchParamToObject(searchParams).single === "true";
      const searchParamsObject = getSearchQueryParam();
      setSingle(thisSingle);
      let path = FARTICLE_URL + objectToQuery(searchParamsObject);
      let thisOldSearchParam = { ...searchParamsObject };
      if (Object.keys(oldSearchParams).length !== 0) {
        const thisCustomRayonSearchChanged = customRayonSearchChanged(
          searchParamsObject,
          oldSearchParams
        );
        if (
          thisCustomRayonSearchChanged &&
          searchParamsObject.hasOwnProperty("aggs") &&
          searchParamsObject.hasOwnProperty("categories[]")
        ) {
          filterRef.current.resetCustomRayon();
          return;
        }
      }
      setPage(Number(searchParamsObject.page));
      delete searchParamsObject.page;
      delete searchParamsObject.itemsPerPage;
      delete searchParamsObject.indexable;
      const nbFilterValues = searchParamsObject["filterValues[]"]?.length ?? 0;
      delete searchParamsObject["filterValues[]"];
      for (const key of Object.keys(searchParamsObject)) {
        if (
          key.startsWith("order[") ||
          (key.startsWith("categories[") &&
            !searchParamsObject.hasOwnProperty("aggs"))
        ) {
          delete searchParamsObject[key];
        }
      }
      setNbSelected(
        Object.keys(searchParamsObject).length + Number(nbFilterValues)
      );
      if (!thisSingle) {
        setOldSearchParams(thisOldSearchParam);
        const response = await requestApi({
          method: GET,
          path: path,
          allowError: false,
          paginate: true,
          token: token,
        });
        if (response.statusCode === 200) {
          const searchParamsObject = getSearchQueryParam();
          let newPath = FARTICLE_URL + objectToQuery(getSearchQueryParam());
          if (path !== newPath) {
            return;
          }
          if (single) {
            setSingle(false);
            if (
              response.content.total === 1 &&
              response.content.fArticles[0].arRef.toLowerCase() ===
                searchParamsObject.keywords.toLowerCase()
            ) {
              const fArticle = response.content.fArticles[0];
              navigate(PRODUCT_PAGE + "/" + fArticle.fArticleProp.slug, {
                state: fArticle,
              });
            }
          }
          setAggregations(response.content?.aggregations ?? null);
          setTotalItems(response.content.total);
          setGroupFArticles(
            groupArray(
              response.content.fArticles.map(
                (fArticleSmall: FArticleSmallInterface) => {
                  return {
                    ...fArticleSmall,
                    page: response.content.page,
                  };
                }
              ),
              inline ? 1 : nbGroup,
              category,
              searchParamsObject,
              response.content?.aggregations
            )
          );
          setNbPage(Math.ceil(response.content.total / response.content.size));
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          toastr.error(t("word.error"), t("error.tryAgain"));
        }
      }
      setIsSearching(false);
    });
  }, [
    category,
    getSearchQueryParam,
    init,
    initFilters,
    initSort,
    inline,
    navigate,
    nbGroup,
    oldSearchParams,
    searchParams,
    single,
    t,
    token,
  ]);

  const onChangeNbGroup = React.useCallback(() => {
    localStorage.setItem(STORAGE_NB_GROUP, nbGroup.toString());
    const fArticles = groupFArticles?.reduce((result, groupFArticle) => {
      // @ts-ignore
      return result.concat(groupFArticle.fArticles);
    }, []);
    const searchParamsObject = searchParamToObject(searchParams);
    setGroupFArticles(
      groupArray(
        fArticles,
        inline ? 1 : nbGroup,
        category,
        searchParamsObject,
        aggregations
      )
    );
  }, [aggregations, category, groupFArticles, inline, nbGroup, searchParams]);

  const onChangeStyleInline = React.useCallback(() => {
    localStorage.setItem(STYLE_INLINE, styleInline);
  }, [styleInline]);

  const onChangeInline = React.useCallback(() => {
    let newInline = "0";
    if (inline) {
      newInline = "1";
    }
    localStorage.setItem(STORAGE_INLINE_FARTICLE_CATEGORY, newInline);
    const fArticles = groupFArticles?.reduce((result, groupFArticle) => {
      // @ts-ignore
      return result.concat(groupFArticle.fArticles);
    }, []);
    const searchParamsObject = searchParamToObject(searchParams);
    setGroupFArticles(
      groupArray(
        fArticles,
        inline ? 1 : nbGroup,
        category,
        searchParamsObject,
        aggregations
      )
    );
  }, [aggregations, category, groupFArticles, inline, nbGroup, searchParams]);

  const onChangeIsSmall = React.useCallback(() => {
    if (isSmall) {
      setStyleInline(STYLE_COMPACT_INLINE);
      setNbGroup(2);
    }
  }, [isSmall]);

  const changeShowIcons = React.useCallback(() => {
    localStorage.setItem(STORAGE_ICONS, (!showIcons).toString());
    dispatch(set({ showIcons: !showIcons }));
  }, [dispatch, showIcons]);

  const changeInfinitScroll = React.useCallback(
    (value: boolean) => {
      dispatch(set({ infinitScroll: value }));
      localStorage.setItem(STORAGE_INFINIT_SCROLL, value.toString());
    },
    [dispatch]
  );

  const onPagesToLoadChange = React.useCallback(async () => {
    const result = [];
    for (const pageToLoad of pagesToLoad) {
      let isTop = null;
      if (groupFArticles && groupFArticles[0].fArticles[0].page) {
        isTop = groupFArticles[0].fArticles[0].page > pageToLoad;
        if (isTop) {
          setLoadingTop(true);
        } else {
          setLoadingBottom(true);
        }
      }
      const searchParamsObject = getSearchQueryParam();
      searchParamsObject.page = pageToLoad;
      const path = FARTICLE_URL + objectToQuery(searchParamsObject);
      const response = await requestApi({
        method: GET,
        path: path,
        allowError: false,
        paginate: true,
        token: token,
      });
      if (response.statusCode === 200) {
        result.push(
          (response.content.fArticles = response.content.fArticles.map(
            (fArticleSmall: FArticleSmallInterface) => {
              return {
                ...fArticleSmall,
                page: response.content.page,
              };
            }
          ))
        );
        setIgnorePages((x) => {
          return [...x, pageToLoad];
        });
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        result.push([]);
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      if (isTop !== null) {
        if (isTop) {
          const scrollContainer = document.getElementById("scroll-container");
          if (scrollContainer?.scrollHeight) {
            setPrevScroll({
              scrollHeight: scrollContainer?.scrollHeight,
              scrollTop: scrollContainer?.scrollTop,
            });
          }
          setLoadingTop(false);
        } else {
          setLoadingBottom(false);
        }
      }
      if (result.length === pagesToLoad.length) {
        let merged: FArticleSmallInterface[] = [];
        for (const fArticles of result) {
          merged = [...merged, ...fArticles];
        }
        if (groupFArticles) {
          for (const thisGroupFArticle of groupFArticles) {
            merged = [...merged, ...thisGroupFArticle.fArticles];
          }
        }
        merged.sort((a, b) => {
          if (a.page === undefined || b.page === undefined) {
            return 0;
          }
          if (a.page === b.page) {
            return 0;
          }
          if (a.page > b.page) {
            return 1;
          }
          return -1;
        });
        setGroupFArticles(
          groupArray(
            merged,
            inline ? 1 : nbGroup,
            category,
            searchParamsObject,
            aggregations
          )
        );
        setPagesToLoad([]);
      }
    }
  }, [
    aggregations,
    category,
    getSearchQueryParam,
    groupFArticles,
    inline,
    nbGroup,
    pagesToLoad,
    t,
    token,
  ]);

  const onScrollProducts = React.useCallback(
    (e: any) => {
      if (
        !groupFArticles ||
        pagesToLoad.length > 0 ||
        !infinitScroll ||
        isSearching ||
        (groupFArticles.length === 1 &&
          groupFArticles[0].fArticles.length === 1)
      ) {
        return;
      }
      const position = e.target
        .querySelector("#products-container")
        ?.getBoundingClientRect();
      if (!position) {
        return;
      }
      const isTop = position.top >= -2000;
      const isBottom =
        e.target.offsetHeight + e.target.scrollTop >=
        e.target.scrollHeight - 2000;
      let thisPagesToLoad = [];
      if (isTop) {
        // @ts-ignore
        thisPagesToLoad.push(groupFArticles[0].fArticles[0].page - 1);
      }
      if (isBottom) {
        thisPagesToLoad.push(
          // @ts-ignore
          groupFArticles[groupFArticles.length - 1].fArticles[
            groupFArticles[groupFArticles.length - 1].fArticles.length - 1
          ].page + 1
        );
      }
      thisPagesToLoad = thisPagesToLoad.filter(
        (p) => p && p > 0 && !ignorePages.includes(p)
      );
      if (thisPagesToLoad.length > 0) {
        setPagesToLoad(thisPagesToLoad);
      }
    },
    [
      groupFArticles,
      ignorePages,
      infinitScroll,
      isSearching,
      pagesToLoad.length,
    ]
  );

  const onPressKey = React.useCallback(
    (event: KeyboardEvent) => {
      // @ts-ignore
      const target = event.target;
      if (
        refreshPage ||
        isSearching ||
        (target instanceof HTMLInputElement &&
          // @ts-ignore
          event.target.type.toLowerCase() === "text" &&
          // @ts-ignore
          event.target.tagName.toLowerCase() === "input")
      ) {
        return;
      }
      let value = null;
      if (nbPage !== undefined) {
        if (event.code === "ArrowRight" && page < nbPage) {
          value = page + 1;
        } else if (event.code === "ArrowLeft" && page > 1) {
          value = page - 1;
        }
      }
      if (value) {
        setPage(value);
        const searchParamsObject = searchParamToObject(searchParams);
        searchParamsObject.page = value;
        setSearchParams(searchParamsObject, {
          replace: true,
        });
      }
    },
    [isSearching, nbPage, page, refreshPage, searchParams, setSearchParams]
  );

  const scrollIntoArticle = React.useCallback(() => {
    const scrollContainer = document.getElementById("scroll-container");
    if (
      scrollContainer &&
      scrollContainer?.scrollTop &&
      scrollContainer?.scrollHeight &&
      prevScroll
    ) {
      scrollContainer.scrollTo(
        0,
        scrollContainer?.scrollTop -
          prevScroll.scrollTop +
          (scrollContainer?.scrollHeight - prevScroll.scrollHeight)
      );
      setPrevScroll(undefined);
    }
    const element = document.getElementById("product-" + scrollArticle);
    if (element) {
      element.scrollIntoView();
      setScrollArticle(undefined);
    }
  }, [prevScroll, scrollArticle]);

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    scrollIntoArticle();
  }, [scrollArticle, groupFArticles]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    onPagesToLoadChange();
  }, [pagesToLoad]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    let title = getTitle("products");
    if (category?.name) {
      title = category?.name + " Informatique Guadeloupe NOVE";
    }
    document.title = title;
  }, [category?.name]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!infinitScroll) {
      window.addEventListener("keydown", onPressKey);
    }
    return () => {
      window.removeEventListener("keydown", onPressKey);
    };
  }, [isSearching, nbPage, page, refreshPage, searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    search();
  }, [searchParams, init, initSort, initFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!infinitScroll) {
      setIgnorePages([]);
    }
  }, [infinitScroll]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    onChangeNbGroup();
  }, [nbGroup]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    onChangeStyleInline();
  }, [styleInline]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    onChangeInline();
  }, [inline]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    onChangeIsSmall();
  }, [isSmall]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Layout sx={{ overflow: "hidden" }}>
        <Container
          maxWidth={false}
          sx={{
            display: "flex",
            maxHeight: "100%",
          }}
        >
          {!isSmall && (
            <Box
              sx={{
                paddingTop: 2,
                paddingBottom: 2,
                paddingRight: 1,
                maxWidth: "25%",
                overflow: "auto",
                flexGrow: 0,
                flexShrink: 0,
                flexBasis: "auto",
              }}
            >
              <FilterProductsComponent
                hasCategory={hasCategory}
                category={category}
                ref={filterRef}
                apply={apply}
                aggregations={aggregations}
                setInitFilters={setInitFilters}
              />
            </Box>
          )}
          <Container
            maxWidth={false}
            id="scroll-container"
            sx={{
              paddingTop: 2,
              paddingX: "0 !important",
              paddingBottom: isSmall ? 6 : 2,
              overflow: "auto",
              overflowX: "hidden",
              overflowY: "scroll", // avoid tremors
            }}
            onScroll={onScrollProducts}
          >
            <Box sx={{ ...(!isSmall && { display: "flex" }) }}>
              {hasCategory && (
                <CategoryBreadcrumbComponent category={category} />
              )}
              <Card variant="outlined" sx={{ marginBottom: 1, width: "100%" }}>
                <CardContent sx={{ padding: "8px!important" }}>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        borderRight: "1px solid lightgray",
                        paddingRight: 1,
                      }}
                    >
                      {totalItems !== undefined && !isSearching ? (
                        totalItems + " " + t("word.results")
                      ) : (
                        <Skeleton variant="text" sx={{ width: "100%" }} />
                      )}
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        paddingLeft: 1,
                      }}
                    >
                      <SortProductsComponent
                        category={category}
                        apply={apply}
                        ref={sortRef}
                        setInitSort={setInitSort}
                      />
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        paddingLeft: 1,
                        textAlign: "right",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => configureRef.current.toggleShow()}
                      >
                        {t("word.configuration.display")}
                      </Button>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <ConfigureViewProductsComponent
              ref={configureRef}
              changeInfinitScroll={changeInfinitScroll}
              changeShowIcons={changeShowIcons}
              styleInline={styleInline}
              nbGroup={nbGroup}
              inline={inline}
              handleInline={handleInline}
              handleNbGroup={handleNbGroup}
              handleStyleInline={handleStyleInline}
            />
            <ContentComponent
              contentName="beforeProductsList"
              saveLocalStorage={true}
              noPadding={true}
            />
            {isSearching ? (
              <SearchComponent nbColumn={inline ? 1 : nbGroup} nbLines={4} />
            ) : groupFArticles?.length === 0 ? (
              <Typography
                component="p"
                variant="h5"
                sx={{ textAlign: "center" }}
              >
                {t("word.noResult")}
              </Typography>
            ) : (
              <>
                <FArticleHeaderComponent category={category} />
                {!infinitScroll && (
                  <CustomPaginationComponent
                    nbPage={nbPage}
                    page={page}
                    handleChangePage={handleChangePage}
                  />
                )}
                {loadingTop && (
                  <Box sx={{ textAlign: "center" }}>
                    <CircularProgress />
                  </Box>
                )}
                <Box id="products-container">
                  {groupFArticles?.map(
                    (
                      groupFArticle: GroupFArticleInterface,
                      indexGroupFArticle
                    ) => (
                      <React.Fragment key={indexGroupFArticle}>
                        <FilterReferenceHeaderComponent
                          title={groupFArticle.title}
                          subTitle={groupFArticle.subTitle}
                          first={indexGroupFArticle === 0}
                          inline={inline}
                        />
                        <FArticleSmallComponent
                          nbColumn={inline ? 1 : nbGroup}
                          inline={inline}
                          fArticles={groupFArticle.fArticles}
                          defaultStyleInline={styleInline}
                        />
                      </React.Fragment>
                    )
                  )}
                </Box>
                {loadingBottom && (
                  <Box sx={{ textAlign: "center" }}>
                    <CircularProgress />
                  </Box>
                )}
                {!infinitScroll && (
                  <CustomPaginationComponent
                    nbPage={nbPage}
                    page={page}
                    handleChangePage={handleChangePage}
                  />
                )}
              </>
            )}
            {isSmall && (
              <Box
                sx={{
                  flex: 0,
                  textAlign: "center",
                  position: "absolute",
                  bottom: 5,
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 2,
                }}
              >
                <LoadingButton
                  sx={{ wordBreak: "break-word" }}
                  variant="contained"
                  loading={false}
                  onClick={handleOpen}
                >
                  {t("word.filters")}
                  {nbSelected > 0 && (
                    <Typography
                      component="span"
                      className="badge"
                      sx={{ marginLeft: 1 }}
                    >
                      {nbSelected}
                    </Typography>
                  )}
                </LoadingButton>
              </Box>
            )}
          </Container>
        </Container>
      </Layout>
      <Dialog
        fullScreen={fullScreen}
        open={isSmall}
        onClose={handleClose}
        sx={{
          ...(isSmall &&
            !open && {
              display: "none",
            }),
        }}
        aria-labelledby="dialog-filter"
      >
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box sx={{ flex: 1, overflow: "auto" }}>
            <DialogTitle id="dialog-filter">
              {t("word.filter.label")}: {category?.name}
            </DialogTitle>
            <DialogContent>
              {isSmall && (
                <FilterProductsComponent
                  hasCategory={hasCategory}
                  category={category}
                  ref={filterRef}
                  apply={apply}
                  aggregations={aggregations}
                  setInitFilters={setInitFilters}
                />
              )}
            </DialogContent>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flex: 0,
              marginY: 1,
            }}
          >
            <Box>
              <LoadingButton
                sx={{ wordBreak: "break-word", marginLeft: 1 }}
                loading={false}
                onClick={handleClose}
              >
                {t("word.cancel")}
              </LoadingButton>
            </Box>
            <Box>
              <LoadingButton
                sx={{ wordBreak: "break-word", marginRight: 1 }}
                variant="contained"
                loading={false}
                onClick={apply}
              >
                {t("word.apply")}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
});

export default ProductsScreen;
