import * as React from "react";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

interface State {
  text: string;
  className?: string;
  component?: any;
  sx?: SxProps<Theme>;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  afterCopy?: Function;
}

const CopyClipboardComponent: React.FC<State> = React.memo(
  ({ text, className, component, sx, placement, afterCopy }) => {
    const [open, setOpen] = React.useState(false);
    const copy = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        navigator.clipboard.writeText(text);
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 500);
        if (afterCopy) {
          afterCopy();
        }
      },
      [afterCopy, text]
    );
    const { t } = useTranslation();

    return (
      <>
        {text && (
          <Tooltip title={t("word.copied")} open={open} placement={placement}>
            <Typography
              className={className}
              component={component}
              onClick={copy}
              sx={{
                cursor: "pointer",
                borderStyle: "dashed",
                borderColor: "gray",
                borderWidth: "thin",
                wordBreak: "break-word",
                ...sx,
              }}
            >
              {text}
            </Typography>
          </Tooltip>
        )}
      </>
    );
  }
);

export default CopyClipboardComponent;
