import React, { useRef } from "react";
import ContentComponent from "../../components/content/ContentComponent";
import { DialogContent, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import DialogActions from "@mui/material/DialogActions";
import { requestApi } from "../../helpers/RequestApi";
import { POST } from "../../utils/MethodUtils";
import { EDIT_USER_URL } from "../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { setUser } from "../../app/globalSlice";
import Divider from "@mui/material/Divider";
import getErrorApi from "../../helpers/GetErrorApi";
import PhoneInputComponent from "../../components/common/user/PhoneInputComponent";

interface State {
  close: any;
}

const EditPhoneFormComponent: React.FC<State> = React.memo(({ close }) => {
  const refCtTelephone: any = useRef();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const submit = React.useCallback(async () => {
    const ctTelephoneValue = refCtTelephone.current.getValue();
    if (ctTelephoneValue === undefined) {
      return;
    }
    setLoading(true);
    const response = await requestApi({
      method: POST,
      path: EDIT_USER_URL,
      allowError: false,
      body: {
        userIdentifier: user?.userIdentifier,
        phone: ctTelephoneValue,
      },
      token: token,
    });
    if (response.statusCode === 200) {
      dispatch(setUser(response.content));
      toastr.success(
        t("word.success"),
        t("sentence.notification.phone_updated")
      );
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
    close();
  }, [close, dispatch, t, token, user?.userIdentifier]);

  return (
    <>
      <DialogContent>
        <ContentComponent
          contentName="editPhone"
          saveLocalStorage={true}
          width={320}
        />
        <Divider sx={{ marginBottom: 2 }} />
        <Grid container spacing={1} sx={{ marginBottom: 2 }}>
          <Grid item xs={12}>
            <PhoneInputComponent ref={refCtTelephone} initCtTelephone={""} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <LoadingButton onClick={close} disabled={loading}>
          {t("word.cancel")}
        </LoadingButton>
        <LoadingButton variant="contained" onClick={submit} loading={loading}>
          {t("word.save")}
        </LoadingButton>
      </DialogActions>
    </>
  );
});

export default EditPhoneFormComponent;
