import { DRIVER_URL } from "../utils/UrlsUtils";

export const farticleFormToApi = (
  arRef: string,
  formStateFArticle: any
): any => {
  let icecatId = null;
  if (formStateFArticle.icecatId) {
    icecatId = formStateFArticle.icecatId.toString();
  }
  return {
    arRef: formStateFArticle.arRef ?? arRef,
    arDesign: formStateFArticle.arDesign.value,
    arPublie: formStateFArticle.arPublie,
    arSommeil: formStateFArticle.arSommeil,
    stockManuel: formStateFArticle.stockManuel,
    fArtstocks: formStateFArticle.fArtstocks,
    arCodebarre: formStateFArticle.arCodebarre,
    constructeurRef: formStateFArticle.constructeurRef,
    arPrixach: formStateFArticle.arPrixach,
    arCoef: formStateFArticle.arCoef,
    arPrixven: formStateFArticle.arPrixven,
    fArtclients: formStateFArticle.fArtclients,
    arPoidsnet: formStateFArticle.arPoidsnet,
    arCodefiscal: formStateFArticle.arCodefiscal,
    fArtfourniss: formStateFArticle.fArtfourniss,
    fArticleProp: {
      name: formStateFArticle.arDesign.value,
      arCodebarre: formStateFArticle.arCodebarre,
      icecatId: icecatId,
      constructeurRef: formStateFArticle.constructeurRef,
      model: formStateFArticle.model ?? null,
      driver: formStateFArticle?.driver?.id
        ? DRIVER_URL + "/" + formStateFArticle.driver.id
        : null,
      images: formStateFArticle.images,
      fArticleSellings: formStateFArticle.fArticleSellings,
      keywords: formStateFArticle.keywords,
      spotlight: formStateFArticle.spotlight,
      description: formStateFArticle.description,
      categoryFArticleIds: formStateFArticle.categoryFArticleIds,
      fArticleInfos: formStateFArticle.fArticleInfos,
      tags: formStateFArticle.tags,
      percentAcompte: formStateFArticle.percentAcompte,
      fArticleFiles: formStateFArticle.fArticleFiles.map(
        (fArticleFile: any) => {
          if (fArticleFile.file) {
            delete fArticleFile.file;
          }
          return { ...fArticleFile };
        }
      ),
      configurationFArticleOptions:
        formStateFArticle.configurationFArticleOptions,
    },
    fArtgloss: formStateFArticle.fArtgloss,
  };
};

export const formatReference = (reference: string): string => {
  return reference
    .toUpperCase()
    .replaceAll(" ", "_")
    .replaceAll(new RegExp("[^a-zA-Z0-9$%+-./_]", "g"), "/")
    .substring(0, 30);
};
