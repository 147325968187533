import React, { useEffect, useImperativeHandle } from "react";
import {
  ArrivageFDocligneInterface,
  FDocenteteLigneArrivageSerialNumberInterface,
} from "../../../../interfaces/ArrivageInterface";
import { FDocenteteFoundInterface } from "../EntreeStockArrivageComponent";
import {
  SAGE_DO_TYPE_ACHAT_FA,
  SAGE_DO_TYPE_ACHAT_LI,
} from "../../../../utils/DoceneteteUtils";
import Box from "@mui/material/Box";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CopyClipboardComponent from "../../CopyClipboardComponent";
import { getUrlImageDirectory } from "../../../../helpers/FileHelper";
import { FormHelperText, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { FDOCENTETES_URL } from "../../../../utils/UrlsUtils";
import { GET } from "../../../../utils/MethodUtils";
import { objectToQuery, requestApi } from "../../../../helpers/RequestApi";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import getErrorApi from "../../../../helpers/GetErrorApi";
import { InputInterface } from "../../../../interfaces/InputInterface";
import numberValidator from "../../../../helpers/validator/NumberValidator";
import { SUIVI_STOCK_TYPE_LOT } from "../../../../utils/FDoceneteteUtils";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";
import {
  getDoPieceWithProp,
  getStepsForFDocligne,
} from "../../../../helpers/FDocenteteHelper";
import Typography from "@mui/material/Typography";
import theme from "../../../../app/Theme";
import IconButton from "@mui/material/IconButton";
import SwapVertIcon from "@mui/icons-material/SwapVert";

interface State {
  doType: number | undefined;
  fDocligne: ArrivageFDocligneInterface;
  fDocenteteFounds: FDocenteteFoundInterface[];
  setFDocenteteFounds: Function;
  applyDoPieces: Function;
}

interface State3 {
  doType: number;
  fDoclignes: ArrivageFDocligneInterface[];
  fDocenteteFounds: FDocenteteFoundInterface[];
  setFDocenteteFounds: Function;
}

interface State2 {
  defaultDoPiece: string;
  fDocenteteFounds: FDocenteteFoundInterface[];
  setFDocenteteFounds: Function;
  serialNumbers?: FDocenteteLigneArrivageSerialNumberInterface[];
  doType: number | undefined;
  defaultQuantity: number;
  doTiers: string;
  arSuivistock: number;
  applyDoPieces: Function;
}

interface FormState {
  quantity: InputInterface;
  search: InputInterface;
}

interface FDocligneRefInterface {
  fDocligne: ArrivageFDocligneInterface;
  ref: any;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ArrivageStepFormComponent = React.memo(
  React.forwardRef(
    (
      {
        defaultDoPiece,
        fDocenteteFounds,
        setFDocenteteFounds,
        doType,
        defaultQuantity,
        doTiers,
        serialNumbers,
        arSuivistock,
        applyDoPieces,
      }: State2,
      ref
    ) => {
      const [found, setFound] = React.useState<boolean | undefined>(undefined);
      const handleChangeText = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            v.search.value = event.target.value;
            v.search.error = "";
            return { ...v };
          });
        },
        []
      );
      const [values, setValues] = React.useState<FormState>({
        quantity: { value: defaultQuantity.toString(), error: "" },
        search: { value: defaultDoPiece ?? "", error: "" },
      });
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const { t } = useTranslation();

      // const addRemoveQuantity = React.useCallback(
      //   (q: number) => {
      //     setValues((v) => {
      //       let value: number = Number(v.quantity.value ?? 0) + q;
      //       if (value <= 0) {
      //         value = 1;
      //       }
      //       if (value > defaultQuantity) {
      //         value = defaultQuantity;
      //       }
      //       v.quantity = {
      //         ...v.quantity,
      //         value: value.toString(),
      //         error: "",
      //       };
      //       return { ...v };
      //     });
      //   },
      //   [defaultQuantity]
      // );
      //
      // const handleChangeQuantity = React.useCallback(
      //   (event: React.ChangeEvent<HTMLInputElement>) => {
      //     let value: string = event.target.value;
      //     setValues((v) => {
      //       v.quantity = {
      //         ...v.quantity,
      //         value: value.toString(),
      //         error: "",
      //       };
      //       return { ...v };
      //     });
      //   },
      //   []
      // );

      const fastFound = React.useCallback(() => {
        const thisSearch = values.search.value.trim().toUpperCase();
        if (thisSearch === "") {
          setFound(true);
          return true;
        }
        const fDocenteteFound = fDocenteteFounds.find(
          (fDocenteteFound) =>
            fDocenteteFound.doPiece === thisSearch &&
            fDocenteteFound.doType === doType
        );
        if (fDocenteteFound) {
          if (fDocenteteFound.doTiers === doTiers) {
            setFound(true);
          } else {
            setFound(false);
            setValues((v) => {
              v.search.error = t("error.wrongDoTiers");
              return { ...v };
            });
          }
          return true;
        }
        return false;
      }, [doTiers, doType, fDocenteteFounds, t, values.search.value]);

      const searchKeywords = React.useCallback(async () => {
        const thisSearch = values.search.value.trim().toUpperCase();
        if (fastFound()) {
          return;
        }
        setFound(undefined);
        const param: any = {
          doPiece: thisSearch,
          doType: doType,
        };
        const response = await requestApi({
          method: GET,
          path: FDOCENTETES_URL + objectToQuery(param),
          allowError: false,
          paginate: false,
          token: token,
        });
        if (
          response.statusCode === 200 &&
          response.content.fDocentetes.length === 1 &&
          response.content.fDocentetes[0].doPiece === thisSearch &&
          response.content.fDocentetes[0].doType === doType
        ) {
          if (response.content.fDocentetes[0].doTiers.ctNum === doTiers) {
            setFound(true);
            setFDocenteteFounds((x: FDocenteteFoundInterface[]) => {
              const arr: FDocenteteFoundInterface[] = [
                ...x,
                {
                  ...param,
                  doTiers: response.content.fDocentetes[0].doTiers.ctNum,
                },
              ];
              return arr.filter(
                (a, i) =>
                  arr.findIndex(
                    (s) => a.doPiece === s.doPiece && a.doType === s.doType
                  ) === i
              );
            });
          } else {
            setFound(false);
            setValues((v) => {
              v.search.error = t("error.wrongDoTiers");
              return { ...v };
            });
          }
        } else {
          setFound(false);
          if (response.statusCode === 401) {
            toastr.info(t("word.info"), t("error.reconnect"));
          } else if (response.statusCode !== 200) {
            for (let message of getErrorApi(response.content)) {
              toastr.error(t("word.error"), t(message));
            }
          }
        }
      }, [
        values.search.value,
        fastFound,
        doType,
        doTiers,
        token,
        setFDocenteteFounds,
        t,
      ]);

      const getValue = React.useCallback(() => {
        if (!found) {
          setValues((v) => {
            v.search.error = t("word.notFound");
            return { ...v };
          });
          return undefined;
        }
        const thisSearch = values.search.value.trim().toUpperCase();
        if (doType === SAGE_DO_TYPE_ACHAT_LI) {
          const error = numberValidator(values.quantity.value, 1);
          if (error) {
            setValues((v) => {
              v.quantity.error = error;
              return { ...v };
            });
            return undefined;
          }
        }
        return {
          doType: doType,
          doPiece: thisSearch,
          ...(doType !== SAGE_DO_TYPE_ACHAT_FA && {
            quantity: Number(values.quantity.value),
          }),
        };
      }, [doType, found, values.search.value, t, values.quantity.value]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
        setSearch(newSearch: string) {
          setValues((v) => {
            v.search.value = newSearch;
            v.search.error = "";
            return { ...v };
          });
        },
        setQuantityError(error: string) {
          setValues((v) => {
            v.quantity.error = error;
            return { ...v };
          });
        },
        duplicateNewDocument() {
          if (values.search.value.trim() === "") {
            setValues((v) => {
              v.search.error = t("error.duplicateNewDocument");
              return { ...v };
            });
          }
        },
      }));

      React.useEffect(() => {
        if (!fastFound()) {
          setFound(undefined);
        }
        const timeoutTyping = setTimeout(() => {
          searchKeywords();
        }, 500);
        return () => clearTimeout(timeoutTyping);
      }, [values.search.value]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues((v) => {
          v.search.value = defaultDoPiece ?? "";
          v.search.error = "";
          return { ...v };
        });
      }, [defaultDoPiece]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              autoComplete="off"
              type="text"
              value={values.search.value}
              error={!!values.search.error}
              helperText={t(values.search.error ?? "")}
              onChange={handleChangeText}
              placeholder={t("word.new")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {found ? (
                      <DoneIcon color="success" />
                    ) : found === false ? (
                      <CloseIcon color="error" />
                    ) : (
                      <SearchIcon />
                    )}
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() => {
                        applyDoPieces(doType, values.search.value);
                      }}
                    >
                      <SwapVertIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {doType !== SAGE_DO_TYPE_ACHAT_FA && (
              <>
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ marginLeft: 1 }}
                >
                  <Typography
                    sx={{
                      ...(!!values.quantity.error && {
                        color: theme.palette.error.main,
                      }),
                    }}
                  >
                    {values.quantity.value}
                  </Typography>
                  {!!values.quantity.error && (
                    <FormHelperText error>
                      {t(values.quantity.error ?? "").replace("{min}", "1")}
                    </FormHelperText>
                  )}
                </Typography>
                {/*<FormControl variant="outlined" required>*/}
                {/*  <OutlinedInput*/}
                {/*      type="number"*/}
                {/*      error={!!values.quantity.error}*/}
                {/*      onFocus={(e) => e.target.select()}*/}
                {/*      value={values.quantity.value}*/}
                {/*      onChange={handleChangeQuantity}*/}
                {/*      onBlur={() => {*/}
                {/*        addRemoveQuantity(0);*/}
                {/*      }}*/}
                {/*      inputProps={{*/}
                {/*        sx: {*/}
                {/*          width: 132,*/}
                {/*          textAlign: "center",*/}
                {/*        },*/}
                {/*      }}*/}
                {/*      endAdornment={*/}
                {/*        <InputAdornment position="end">*/}
                {/*          <AddIcon*/}
                {/*              sx={{ cursor: "pointer" }}*/}
                {/*              onClick={() => {*/}
                {/*                addRemoveQuantity(1);*/}
                {/*              }}*/}
                {/*          />*/}
                {/*        </InputAdornment>*/}
                {/*      }*/}
                {/*      startAdornment={*/}
                {/*        <InputAdornment position="start">*/}
                {/*          <RemoveIcon*/}
                {/*              sx={{ cursor: "pointer" }}*/}
                {/*              onClick={() => {*/}
                {/*                addRemoveQuantity(-1);*/}
                {/*              }}*/}
                {/*          />*/}
                {/*        </InputAdornment>*/}
                {/*      }*/}
                {/*  />*/}
                {/*  {!!values.quantity.error && (*/}
                {/*      <FormHelperText error>*/}
                {/*        {t(values.quantity.error ?? "").replace("{min}", "1")}*/}
                {/*      </FormHelperText>*/}
                {/*  )}*/}
                {/*</FormControl>*/}
              </>
            )}
          </Box>
          {serialNumbers &&
            serialNumbers.length > 0 &&
            doType &&
            doType < SAGE_DO_TYPE_ACHAT_FA && (
              <Table sx={{ width: "100%" }} size="small">
                <TableBody>
                  {serialNumbers
                    ?.filter((serialNumber) => serialNumber.quantity > 0)
                    ?.map((serialNumber, indexSerialNumber) => (
                      <TableRow key={indexSerialNumber}>
                        <TableCell>
                          <CopyClipboardComponent text={serialNumber.serial} />
                        </TableCell>
                        {arSuivistock === SUIVI_STOCK_TYPE_LOT && (
                          <TableCell>{serialNumber.quantity}</TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
        </Box>
      );
    }
  )
);

const SingleArrivageActionFormComponent = React.memo(
  React.forwardRef(
    (
      {
        doType,
        fDocligne,
        fDocenteteFounds,
        setFDocenteteFounds,
        applyDoPieces,
      }: State,
      ref
    ) => {
      const { t } = useTranslation();

      const [stepsForFDocligne, setStepsForFDocligne] = React.useState<any[]>(
        getStepsForFDocligne(fDocligne, doType)
      );
      const [blPiece, setBlPiece] = React.useState<any>(undefined);
      const [facturePiece, setFacturePiece] = React.useState<any>(undefined);

      useImperativeHandle(ref, () => ({
        getValue() {
          let values = stepsForFDocligne.map((d) => {
            return d.ref.current.getValue();
          });
          if (values.filter((v) => v !== undefined).length !== values.length) {
            return undefined;
          }
          values = stepsForFDocligne.map((d) => {
            return {
              oldDoType: d.oldDoType,
              oldDoPiece: d.oldDoPiece,
              serialNumbers: d.serialNumbers,
              dlNo: d.dlNo,
              ...d.ref.current.getValue(),
            };
          });
          let lastQuantity = values[0].quantity;
          let hasError = false;
          for (const i in values) {
            if (values[i].quantity > lastQuantity) {
              hasError = true;
              stepsForFDocligne[i].ref.current.setQuantityError(
                t("error.arrivage_quantity_action")
              );
            }
            lastQuantity = values[i].quantity;
          }
          if (hasError) {
            return undefined;
          }
          return {
            dlNo: fDocligne.dlNo,
            values: values,
          };
        },
        setSearch(thisDoType: number, doPiece: string) {
          if (thisDoType === SAGE_DO_TYPE_ACHAT_LI) {
            blPiece.ref.current.setSearch(doPiece);
          } else if (thisDoType === SAGE_DO_TYPE_ACHAT_FA) {
            facturePiece.ref.current.setSearch(doPiece);
          }
        },
        duplicateNewDocument(nbNewFacture: number, nbNewBonLivraison: number) {
          if (nbNewFacture > 1 && facturePiece) {
            facturePiece?.ref?.current?.duplicateNewDocument();
          } else if (nbNewBonLivraison > 1 && blPiece) {
            blPiece?.ref?.current?.duplicateNewDocument();
          }
        },
      }));

      React.useEffect(() => {
        setStepsForFDocligne(getStepsForFDocligne(fDocligne, doType));
      }, [doType, fDocligne.stateFDocligne]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setBlPiece(
          stepsForFDocligne?.find(
            (p) =>
              p.doType === SAGE_DO_TYPE_ACHAT_LI &&
              p.doTiers === fDocligne.doTiers
          )
        );
        setFacturePiece(
          stepsForFDocligne?.find(
            (p) =>
              p.doType === SAGE_DO_TYPE_ACHAT_FA &&
              p.doTiers === fDocligne.doTiers
          )
        );
      }, [stepsForFDocligne]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          <StyledTableCell>
            <img
              src={
                (process.env.REACT_APP_API_URL ?? "") +
                getUrlImageDirectory(fDocligne.arRef) +
                "/" +
                fDocligne?.image
              }
              style={{
                maxWidth: "40px",
                float: "left",
              }}
              alt={fDocligne.arRef}
              loading="lazy"
            />
          </StyledTableCell>
          <StyledTableCell>
            <CopyClipboardComponent
              className="RobotoMono"
              component="span"
              text={fDocligne.arRef}
              sx={{
                wordBreak: "normal",
                color: theme.palette.success.main,
              }}
            />
          </StyledTableCell>
          <StyledTableCell>
            <CopyClipboardComponent
              className="RobotoMono"
              component="span"
              text={getDoPieceWithProp([fDocligne], 0, "bonDeCommande")}
              sx={{
                wordBreak: "normal",
              }}
            />
          </StyledTableCell>
          <StyledTableCell>
            <ArrowForwardIcon />
          </StyledTableCell>
          <StyledTableCell>
            {blPiece ? (
              <ArrivageStepFormComponent
                ref={blPiece.ref}
                doTiers={blPiece.doTiers}
                arSuivistock={blPiece.arSuivistock}
                defaultDoPiece={blPiece.doPiece}
                serialNumbers={blPiece.serialNumbers}
                defaultQuantity={blPiece.quantity ?? 0}
                fDocenteteFounds={fDocenteteFounds}
                setFDocenteteFounds={setFDocenteteFounds}
                doType={blPiece.doType}
                applyDoPieces={applyDoPieces}
              />
            ) : (
              <CopyClipboardComponent
                className="RobotoMono"
                component="span"
                text={getDoPieceWithProp([fDocligne], 0, "bonDeLivraison")}
                sx={{
                  wordBreak: "normal",
                }}
              />
            )}
          </StyledTableCell>
          <StyledTableCell>
            {facturePiece && <ArrowForwardIcon />}
          </StyledTableCell>
          <StyledTableCell>
            {facturePiece && (
              <ArrivageStepFormComponent
                ref={facturePiece.ref}
                doTiers={facturePiece.doTiers}
                arSuivistock={facturePiece.arSuivistock}
                defaultDoPiece={facturePiece.doPiece}
                serialNumbers={facturePiece.serialNumbers}
                defaultQuantity={facturePiece.quantity ?? 0}
                fDocenteteFounds={fDocenteteFounds}
                setFDocenteteFounds={setFDocenteteFounds}
                doType={facturePiece.doType}
                applyDoPieces={applyDoPieces}
              />
            )}
          </StyledTableCell>
        </>
      );
    }
  )
);

const ArrivageActionFormComponent = React.memo(
  React.forwardRef(
    (
      { doType, fDoclignes, fDocenteteFounds, setFDocenteteFounds }: State3,
      ref
    ) => {
      const getFDoclignesRef = React.useCallback(() => {
        return fDoclignes
          .filter((fDocligne) => fDocligne.doType < doType)
          .map((fDocligne) => {
            return {
              fDocligne: fDocligne,
              ref: React.createRef(),
            };
          });
      }, [doType, fDoclignes]);
      const [fDoclignesRef, setFDoclignesRef] = React.useState<
        (FDocligneRefInterface | undefined)[]
      >(getFDoclignesRef());

      const deleteFDocligneRef = React.useCallback((index: number) => {
        setFDoclignesRef((v) => {
          if (!v) {
            return v;
          }
          v[index] = undefined;
          return [...v];
        });
      }, []);

      const applyDoPieces = React.useCallback(
        (thisDoType: number, doPiece: string) => {
          for (const fDocligneRef of fDoclignesRef) {
            fDocligneRef?.ref.current.setSearch(thisDoType, doPiece);
          }
        },
        [fDoclignesRef]
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          const r = fDoclignesRef
            .filter((f) => f !== undefined)
            .map((f) => f!.ref.current.getValue());
          if (r.filter((f) => f !== undefined).length !== r.length) {
            return undefined;
          }
          let nbNewFacture = 0;
          let nbNewBonLivraison = 0;
          for (const line of r) {
            for (const value of line.values) {
              if (value.doPiece === "") {
                if (value.doType === SAGE_DO_TYPE_ACHAT_LI) {
                  nbNewBonLivraison++;
                } else if (value.doType === SAGE_DO_TYPE_ACHAT_FA) {
                  nbNewFacture++;
                }
              }
            }
          }
          if (nbNewFacture > 1 || nbNewBonLivraison > 1) {
            for (const fDocligneRef of fDoclignesRef) {
              fDocligneRef?.ref?.current?.duplicateNewDocument(
                nbNewFacture,
                nbNewBonLivraison
              );
            }
            return undefined;
          }
          return r;
        },
      }));

      useEffect(() => {
        setFDoclignesRef(getFDoclignesRef());
      }, [fDoclignes]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {fDoclignesRef.map((fDocligneRef, indexFDocligneRef) => (
                  <React.Fragment key={indexFDocligneRef}>
                    {fDocligneRef && (
                      <StyledTableRow>
                        <SingleArrivageActionFormComponent
                          ref={fDocligneRef.ref}
                          fDocligne={fDocligneRef.fDocligne}
                          doType={doType}
                          fDocenteteFounds={fDocenteteFounds}
                          setFDocenteteFounds={setFDocenteteFounds}
                          applyDoPieces={applyDoPieces}
                        />
                        <StyledTableCell>
                          <IconButton
                            onClick={() => {
                              deleteFDocligneRef(indexFDocligneRef);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    }
  )
);

export default ArrivageActionFormComponent;
