import {
  ArrivalInterface,
  DisplayTechnicalSheetInterface,
  FArtfournissFArticleInterface,
  FArticleInterface,
  FArticleSmallInterface,
  FilterValueArticleInterface,
  FilterValueAssoInterface,
  FilterValueAssoLoadedInterface,
  TechnicalSheetInterface,
  UrlFournisseurInterface,
  ValueConfigurationFArticleOptionInterface,
} from "../interfaces/FArticleInterface";
import {
  CAN_BUY_MORE_THAN_DISPO,
  ELASTIC_STOCK_AVAILABLE,
  TXT_STOCK_COMMANDE,
  TXT_STOCK_IDENTIFIER,
} from "../utils/FArticleUtils";
import { RootState, store } from "../app/store";
import i18n from "../languagues/i18n";
import {
  SEARCH_PRODUCT_ALWAYS,
  SEARCH_PRODUCT_AT_LEAST_ONE,
  SEARCH_PRODUCT_MULTIPLE_RESULTS,
  SEARCH_PRODUCT_NO_RESULT,
  SEARCH_PRODUCT_ONE_RESULT,
} from "../utils/ContentUtils";
import {
  FARTICLE_URL,
  FILTER_URL,
  ICECAT_LOGIN_COOKIE_URL,
} from "../utils/UrlsUtils";
import slugify from "slugify";
import { CONDITION_AND, CONDITION_OR } from "../utils/FilterUtils";
import { objectToQuery, requestApi } from "./RequestApi";
import { GET } from "../utils/MethodUtils";
import { toastr } from "react-redux-toastr";
import { filterIsEqual } from "./FilterHelper";
import { set } from "../app/globalSlice";
import { UserInterface } from "../interfaces/UserInterface";

const clone = require("clone");

export const getQuantityDispo = (
  fArticle: FArticleSmallInterface | undefined,
  negative?: boolean
): number => {
  if (fArticle?.fArticleProp === undefined || !fArticle?.fArtstocks) {
    return 0;
  }
  const fArtstock = fArticle.fArtstocks.find((x) => x.fDepot.deNo === 1);
  let dispo =
    Number(fArtstock?.asQtesto) -
    (Number(fArtstock?.asQteres) + Number(fArtstock?.asQteprepa));
  if (dispo < 0 && negative !== true) {
    dispo = 0;
  }
  return dispo;
};

export const getQuantityStock = (
  fArticle: FArticleSmallInterface | undefined
): number => {
  if (fArticle?.fArticleProp === undefined || !fArticle?.fArtstocks) {
    return 0;
  }
  const fArtstock = fArticle.fArtstocks.find((x) => x.fDepot.deNo === 1);
  return Number(fArtstock?.asQtesto);
};

export const fArticleEnabled = (
  fArticle: FArticleSmallInterface | undefined
): boolean => {
  return !!fArticle?.fArticleProp?.indexable;
};

export const canBuyMoreThanDispo = (
  fArticle: FArticleSmallInterface | undefined
): boolean => {
  if (fArticle?.fArticleProp === undefined) {
    return false;
  }
  return (
    (CAN_BUY_MORE_THAN_DISPO.includes(Number(fArticle.stockManuel)) &&
      fArticleEnabled(fArticle)) ||
    getTermStock(fArticle) > 0
  );
};

export const getQuantityFArticleInCart = (
  fArticle: FArticleSmallInterface | undefined
): number => {
  if (fArticle?.fArticleProp === undefined) {
    return 0;
  }
  let quantity = 0;
  const cartFArticles = store.getState().globalState.cart?.cartFArticles;
  if (cartFArticles === undefined) {
    return 0;
  }
  for (const cartFArticle of cartFArticles) {
    if (cartFArticle.fArticle.arRef === fArticle.arRef) {
      quantity += cartFArticle.quantity;
    }
    for (const cartFArticleOption of cartFArticle.cartFArticleOptions) {
      if (cartFArticleOption.fArticle.arRef === fArticle.arRef) {
        quantity += cartFArticle.quantity;
      }
    }
  }
  return quantity;
};

export const getMaxQuantityCanBuy = (
  fArticle: FArticleSmallInterface | undefined,
  isAdmin: boolean | undefined,
  valueOptions?: ValueConfigurationFArticleOptionInterface[],
  maxWithCart?: boolean
): number | undefined => {
  if (!fArticle?.fArticleProp) {
    return 0;
  }
  const quantityDispoWithoutArrivage = getQuantityDispo(fArticle, true);
  let quantityDispo = quantityDispoWithoutArrivage;
  // region add arrivals in quantityDispo
  if (Array.isArray(fArticle.arrivals)) {
    for (const arrival of fArticle.arrivals) {
      quantityDispo += Number(arrival.dlQte);
    }
  }
  // endregion
  if (quantityDispo < 0) {
    quantityDispo = 0;
  }
  if (!fArticleEnabled(fArticle) && quantityDispo <= 0) {
    if (isAdmin) {
      return undefined;
    }
    return 0;
  }
  let quantityFArticleInCart = 0;
  if (maxWithCart !== false) {
    quantityFArticleInCart = getQuantityFArticleInCart(fArticle);
  }
  let arrayMaxQuantityCanBuy: number[] = [];
  if (
    !canBuyMoreThanDispo(fArticle) &&
    quantityDispoWithoutArrivage <= 0 &&
    quantityDispo > 0
  ) {
    arrayMaxQuantityCanBuy = [quantityDispo - quantityFArticleInCart];
  }
  if (valueOptions !== undefined) {
    for (const valueOption of valueOptions) {
      if (
        valueOption.configurationFArticleValue?.fArticle?.fArticleProp ===
          undefined &&
        !valueOption.mandatory
      ) {
        continue;
      }
      const optionMaxQuantity = getMaxQuantityCanBuy(
        valueOption.configurationFArticleValue.fArticle,
        isAdmin
      );
      if (optionMaxQuantity !== undefined) {
        arrayMaxQuantityCanBuy.push(optionMaxQuantity);
      }
    }
  }
  arrayMaxQuantityCanBuy = arrayMaxQuantityCanBuy.filter(
    (x) => x !== undefined
  );
  if (arrayMaxQuantityCanBuy.length === 0) {
    if (!fArticleEnabled(fArticle)) {
      return quantityDispo;
    }
    return undefined;
  }
  let maxQuantityCanBuy = Math.min(...arrayMaxQuantityCanBuy);
  if (maxQuantityCanBuy < 0) {
    maxQuantityCanBuy = 0;
  }
  return maxQuantityCanBuy;
};

export const canBuyFArticle = (
  fArticle: FArticleSmallInterface | undefined,
  isAdmin: boolean | undefined,
  valueOptions?: ValueConfigurationFArticleOptionInterface[],
  maxWithCart?: boolean
): boolean => {
  if (!fArticle) {
    return false;
  }
  const state: RootState = store.getState();
  if (isAdmin) {
    return true;
  }
  const expeditions = state.globalState.expeditions;
  if (
    expeditions &&
    expeditions.find((e) => e.fArticleId === fArticle.arRef) !== undefined
  ) {
    return false;
  }
  const maxQuantity = getMaxQuantityCanBuy(
    fArticle,
    isAdmin,
    valueOptions,
    maxWithCart
  );
  return (
    (fArticleEnabled(fArticle) &&
      (maxQuantity === undefined || maxQuantity > 0)) ||
    (!fArticleEnabled(fArticle) &&
      maxQuantity !== undefined &&
      maxQuantity > 0) ||
    getTermStock(fArticle) > 0
  );
};

export const generateTechnicalSheet = (
  fArticle: FArticleInterface | undefined,
  nbColumn: number
): TechnicalSheetInterface | undefined => {
  const ficheTechnique = fArticle?.ficheTechnique;
  if (ficheTechnique === undefined || ficheTechnique === null) {
    return undefined;
  }
  let result: TechnicalSheetInterface | undefined;
  if (ficheTechnique?.Product) {
    result = generateTechnicalSheetXml(ficheTechnique, nbColumn);
  } else {
    result = generateTechnicalSheetJson(ficheTechnique, nbColumn);
  }
  if (result) {
    // @ts-ignore
    result.titles = [...new Set(result.titles)].filter((t) => t);
    result.groups = result.groups.filter((x: any) => x.props.length > 0);
    for (const group of result.groups) {
      const lowerDisplay = result.display.reduce((prev: any, curr: any) => {
        return prev.size < curr.size ? prev : curr;
      });
      lowerDisplay.groups.push(group);
      lowerDisplay.size += group.props.length + 1;
    }
  }
  return result;
};

const generateTechnicalSheetXml = (
  ficheTechnique: any,
  nbColumn: number
): TechnicalSheetInterface | undefined => {
  const display: DisplayTechnicalSheetInterface[] = [];
  for (let i = 0; i < nbColumn; i++) {
    display.push({
      groups: [],
      size: 0,
    });
  }
  let description = "";
  if (
    ficheTechnique?.Product?.ProductDescription &&
    ficheTechnique.Product.ProductDescription.hasOwnProperty("@attributes")
  ) {
    description =
      ficheTechnique?.Product?.ProductDescription["@attributes"]?.LongDesc;
  }
  let weight = 0;
  if (ficheTechnique?.Product?.ProductFeature) {
    for (const productFeature of ficheTechnique.Product.ProductFeature) {
      if (
        (productFeature?.Feature?.Name["@attributes"]?.Value ?? "")
          .toLowerCase()
          .includes("poids")
      ) {
        const newWeight = Number(
          productFeature?.LocalValue["@attributes"]?.Value ?? 0
        );
        if (newWeight > weight) {
          weight = newWeight;
        }
      }
    }
  }
  const result: TechnicalSheetInterface = {
    titles: [ficheTechnique?.Product["@attributes"]?.Title],
    weight: weight,
    brand: ficheTechnique?.Product?.Supplier["@attributes"]?.Name,
    icecatId: ficheTechnique?.Product["@attributes"]?.ID,
    model: ficheTechnique?.Product["@attributes"]?.Name,
    description:
      description ??
      ficheTechnique?.Product?.SummaryDescription?.LongSummaryDescription ??
      "",
    groups: [],
    display: display,
    constructeurRef: ficheTechnique?.Product?.["@attributes"]?.Prod_id,
    arCodebarre:
      (Array.isArray(ficheTechnique?.Product?.EANCode)
        ? ficheTechnique?.Product?.EANCode?.map(
            (attr: any) => attr?.["@attributes"]?.EAN
          )
        : [ficheTechnique?.Product?.EANCode?.["@attributes"]?.EAN]) ?? [],
    images:
      ficheTechnique?.data?.Gallery ??
      ficheTechnique?.Gallery ??
      ficheTechnique?.Product?.ProductGallery?.ProductPicture,
  };
  if (ficheTechnique?.Product?.CategoryFeatureGroup) {
    for (const group of ficheTechnique.Product.CategoryFeatureGroup) {
      result.groups.push({
        name: group.FeatureGroup.Name["@attributes"].Value,
        id: group["@attributes"].ID,
        props: [],
      });
    }
  }
  // todo http://localhost:3000/product/00MT208
  if (!Array.isArray(ficheTechnique?.Product?.ProductFeature)) {
    return undefined;
  }
  if (ficheTechnique?.Product?.ProductFeature) {
    for (const prop of ficheTechnique.Product.ProductFeature) {
      const group = result.groups.find(
        (x) => x.id === prop["@attributes"].CategoryFeatureGroup_ID
      );
      if (group === undefined) {
        continue;
      }
      group.props.push({
        name: prop.Feature.Name["@attributes"].Value,
        value: prop["@attributes"].Presentation_Value,
        valueNumeric: prop["@attributes"].Value,
      });
    }
  }
  return result;
};

const generateTechnicalSheetJson = (
  ficheTechnique: any,
  nbColumn: number
): TechnicalSheetInterface | undefined => {
  const display: DisplayTechnicalSheetInterface[] = [];
  for (let i = 0; i < nbColumn; i++) {
    display.push({
      groups: [],
      size: 0,
    });
  }
  let weight = 0;
  for (const featuresGroup of ficheTechnique.FeaturesGroups) {
    for (const feature of featuresGroup.Features) {
      if (
        (feature?.Feature?.Name?.Value ?? "").toLowerCase().includes("poids")
      ) {
        const newWeight = Number(feature.RawValue);
        if (newWeight > weight) {
          weight = newWeight;
        }
      }
    }
  }
  const result: TechnicalSheetInterface = {
    titles: [
      ficheTechnique?.GeneralInfo?.Title,
      ficheTechnique?.GeneralInfo?.TitleInfo?.GeneratedIntTitle,
      ficheTechnique?.GeneralInfo?.TitleInfo?.GeneratedLocalTitle?.Value,
      ficheTechnique?.GeneralInfo?.TitleInfo?.BrandLocalTitle?.Value,
    ],
    weight: weight,
    model: ficheTechnique?.GeneralInfo?.ProductName ?? "",
    icecatId: ficheTechnique?.GeneralInfo?.IcecatId,
    brand: ficheTechnique?.GeneralInfo?.BrandInfo?.BrandName,
    description:
      ficheTechnique?.GeneralInfo?.Description?.LongDesc ??
      ficheTechnique?.GeneralInfo?.SummaryDescription?.LongSummaryDescription ??
      "",
    groups: [],
    display: display,
    arCodebarre: ficheTechnique?.GeneralInfo?.GTIN ?? [],
    constructeurRef: ficheTechnique?.GeneralInfo?.BrandPartCode ?? "",
    images:
      ficheTechnique?.data?.Gallery ??
      ficheTechnique?.Gallery ??
      ficheTechnique?.Product?.ProductGallery?.ProductPicture,
  };
  for (const featuresGroup of ficheTechnique.FeaturesGroups) {
    const thisGroup: any = {
      name: featuresGroup.FeatureGroup.Name.Value,
      id: featuresGroup.FeatureGroup.ID,
      props: [],
    };
    result.groups.push(thisGroup);
    for (const feature of featuresGroup.Features) {
      thisGroup.props.push({
        name: feature.Feature.Name.Value,
        value: feature.PresentationValue,
        valueNumeric: feature.RawValue,
      });
    }
  }
  return result;
};

export const labelStockManuel = (stockManuel: string | undefined): string => {
  return (
    store
      .getState()
      .globalState.configurations?.find(
        (x) => x.identifier === TXT_STOCK_IDENTIFIER[Number(stockManuel)]
      )?.value ?? ""
  );
};

export const getTermStock = (
  fArticle: FArticleSmallInterface | undefined
): number => {
  if (!fArticle) {
    return 0;
  }
  let termStock = getQuantityDispo(fArticle, true);
  if (Array.isArray(fArticle.arrivals)) {
    for (const arrival of fArticle.arrivals) {
      termStock += Number(arrival.dlQte);
    }
  }
  if (termStock < 0) {
    return 0;
  }
  return termStock;
};

export const labelArrival = (
  fArticle: FArticleSmallInterface | undefined
): string[] => {
  if (fArticle?.fArticleProp === undefined || !fArticle.arrivals) {
    return [];
  }
  const now = new Date();
  let result: string[] = [];
  let quantityDispo = getQuantityDispo(fArticle, true);
  if (
    quantityDispo < 0 &&
    (Number(fArticle.stockManuel) === TXT_STOCK_COMMANDE ||
      fArticle.arrivals.length === 0)
  ) {
    result.push(labelStockManuel(fArticle.stockManuel));
  }
  const arrivals: ArrivalInterface[] = [];
  // region group same date arrivals
  for (const arrival of fArticle.arrivals) {
    const alreadyExists = arrivals.find(
      (a) => a.doDatelivr === arrival.doDatelivr
    );
    if (alreadyExists) {
      alreadyExists.dlQte = (
        Number(alreadyExists.dlQte) + Number(arrival.dlQte)
      ).toString();
      alreadyExists.dlMontantht = (
        Number(alreadyExists.dlMontantht) + Number(arrival.dlMontantht)
      ).toString();
      alreadyExists.dlMontantttc = (
        Number(alreadyExists.dlMontantttc) + Number(arrival.dlMontantttc)
      ).toString();
    } else {
      arrivals.push(clone(arrival));
    }
  }
  // endregion
  const newArrivals: ArrivalInterface[] = [];
  for (const arrival of arrivals) {
    let quantityArrival = Number(arrival.dlQte);
    if (quantityDispo < 0) {
      if (Math.abs(quantityDispo) >= quantityArrival) {
        quantityDispo += quantityArrival;
        continue;
      }
      quantityDispo = 0;
      quantityArrival = quantityArrival - Math.abs(quantityDispo);
    }
    // @ts-ignore
    newArrivals.push({ ...arrival, ...{ dlQte: quantityArrival } });
  }
  if (newArrivals.length === 0) {
    const dates = arrivals.map((arrival) => new Date(arrival.doDatelivr));
    const maxDate = dates.reduce((a, b) => (a > b ? a : b), now);
    if (arrivals.length === 0 || maxDate < now) {
      if (quantityDispo < 0) {
        result.push(labelStockManuel(fArticle.stockManuel));
      }
    } else {
      result.push(
        i18n.t("sentence.arrival.maxDate").replace(
          "%date%",
          maxDate.toLocaleString(i18n.language, {
            dateStyle: "long",
            timeZone: "Etc/UTC",
          })
        )
      );
    }
  } else {
    result.push(
      newArrivals.reduce((label, arrival) => {
        const quantity = Number(arrival.dlQte);
        const date = new Date(arrival.doDatelivr);
        let trans = "multiple";
        if (quantity === 1) {
          trans = "single";
        }
        return (
          label +
          i18n
            .t("sentence.arrival." + trans)
            .replace("%quantity%", quantity.toString())
            .replace(
              "%date%",
              date.toLocaleString(i18n.language, {
                dateStyle: "long",
                timeZone: "Etc/UTC",
              })
            ) +
          ". "
        );
      }, "")
    );
    if (fArticle.fArtstocks && fArticle.fArtstocks.length > 0) {
      if (Number(fArticle.fArtstocks[0].asQteres) > 0) {
        result.push(
          i18n
            .t(
              "sentence.arrival.qtRes." +
                (Number(fArticle.fArtstocks[0].asQteres) > 1
                  ? "multiple"
                  : "single")
            )
            .replace(
              "%quantity%",
              Number(fArticle.fArtstocks[0].asQteres).toString()
            )
        );
      }
      result.push(
        i18n
          .t("sentence.arrival.term")
          .replace("%quantity%", getTermStock(fArticle).toString())
      );
    }
  }
  // @ts-ignore
  return [...new Set(result.filter((x) => x))];
};

export const addConditionalSearchFArticle = (
  thisValue: any,
  fArticle: FArticleSmallInterface | undefined
): any => {
  for (const [key, value] of Object.entries(thisValue)) {
    if (typeof value === "object" && value !== null) {
      if (key === "fArticle" && fArticle !== undefined) {
        // @ts-ignore
        value.arRef = fArticle.arRef;
        // @ts-ignore
        value.fArticlePropId = fArticle.fArticleProp.id;
      }
      thisValue[key] = JSON.stringify(value);
    }
  }
  return thisValue;
};

export const deleteUnwantedParamSearchFArticle = (thisValue: any): any => {
  delete thisValue.nbProductsDisplay;
  delete thisValue.texts;
  return thisValue;
};

export const getTextsDisplaySearchFArticle = (
  thisTexts: string,
  thisFArticles: FArticleSmallInterface[]
): any[] => {
  let data = [];
  let result = [];
  if (thisTexts) {
    data = JSON.parse(thisTexts);
    if (thisFArticles.length === 0) {
      result = data.filter((x: any) =>
        [SEARCH_PRODUCT_ALWAYS, SEARCH_PRODUCT_NO_RESULT].includes(x.type)
      );
    }
    if (thisFArticles.length === 1) {
      result = data.filter((x: any) =>
        [SEARCH_PRODUCT_ALWAYS, SEARCH_PRODUCT_ONE_RESULT].includes(x.type)
      );
    }
    if (thisFArticles.length >= 2) {
      result = data.filter((x: any) =>
        [SEARCH_PRODUCT_ALWAYS, SEARCH_PRODUCT_MULTIPLE_RESULTS].includes(
          x.type
        )
      );
    }
    if (result.length === 0 && thisFArticles.length >= 1) {
      result = data.filter((x: any) =>
        [SEARCH_PRODUCT_ALWAYS, SEARCH_PRODUCT_AT_LEAST_ONE].includes(x.type)
      );
    }
  }
  return result;
};

export const getNumberFilterWithNoValue = (
  fArticle: FArticleInterface | undefined
): number => {
  if (!fArticle?.fArticleProp?.filters) {
    return 0;
  }
  let nb = 0;
  for (const filter of fArticle.fArticleProp?.filters) {
    if (filter.hasValue) {
      nb++;
      continue;
    }
    filter.hasValue =
      fArticle.fArticleProp.filterRelevants?.find(
        (filterRelevant) => filterRelevant.filterId === filter.id
      ) !== undefined;
    if (filter.hasValue) {
      nb++;
      continue;
    }
    filter.hasValue =
      fArticle.fArticleProp.filterValueArticles?.find((filterValueArticle) =>
        filterIsEqual(filterValueArticle.filter, filter.id)
      ) !== undefined;
    if (filter.hasValue) {
      nb++;
      continue;
    }
    filter.hasValue =
      fArticle.fArticleProp.filterValueNumerics.find((filterValueNumeric) =>
        filterIsEqual(filterValueNumeric.filter, filter.id)
      ) !== undefined;
    if (filter.hasValue) {
      nb++;
    }
  }
  return fArticle.fArticleProp.filters.length - nb;
};

export const getMatching = (
  searchFArticle: string,
  fArticle: FArticleSmallInterface,
  isAdmin: boolean
): string => {
  if (searchFArticle.length < 3) {
    return "";
  }
  let fields: string[] = ["arDesign", "arRef", "constructeurRef"];
  const searchSlug = slugify(searchFArticle, { lower: true, replacement: "" });
  for (const field of fields) {
    // @ts-ignore
    const fArticleSlug = slugify(fArticle[field] ?? "", {
      lower: true,
      replacement: "",
    });
    // @ts-ignore
    if (searchSlug === fArticleSlug) {
      return "exact";
    }
  }
  if (!isAdmin) {
    fields = fields.filter((f) => ["constructeurRef"].includes(f));
  }
  for (const field of fields) {
    // @ts-ignore
    const fArticleSlug = slugify(fArticle[field] ?? "", {
      lower: true,
      replacement: "",
    });
    if (
      searchSlug.includes(fArticleSlug) ||
      fArticleSlug.includes(searchSlug)
    ) {
      return "partial";
    }
  }
  return "";
};

const getFilterId = (
  filterValueArticle: FilterValueArticleInterface
): number => {
  if (typeof filterValueArticle.filter === "string") {
    Number(filterValueArticle.filter.replace(FILTER_URL + "/", ""));
  }
  // @ts-ignore
  return filterValueArticle.filter.id;
};

export const loadFilterValueAsso = async (
  fArticle: FArticleInterface,
  filterValueAsso: FilterValueAssoInterface
): Promise<FilterValueAssoLoadedInterface> => {
  let hasFrom = false;
  const filterValueArticleIds =
    fArticle.fArticleProp.filterValueArticles?.map(
      (filterValueArticle) => filterValueArticle.filterValue?.id
    ) ?? [];
  if (!filterValueArticleIds) {
    return {
      ...filterValueAsso,
      hasFrom: hasFrom,
      fArticles: [],
    };
  }
  const state: RootState = store.getState();

  const allFilterValues: any[] = [];
  for (const filterValueAssoValue of filterValueAsso.filterValueAssoValues) {
    let filterValues: number[] = [];
    let globalHasFrom: boolean = false;
    let globalFromCondition = filterValueAssoValue.fromCondition;
    let globalToCondition = filterValueAssoValue.toCondition;
    const filterValuesFromIds = filterValueAssoValue.filterValuesFrom.map(
      (filterValuesFrom) => filterValuesFrom.id
    );
    let thisHasFrom: number | boolean = filterValueArticleIds.filter(
      (value) => value && filterValuesFromIds.includes(value)
    ).length;
    if (filterValueAssoValue.fromCondition === CONDITION_AND) {
      thisHasFrom = thisHasFrom === filterValuesFromIds.length;
    } else {
      thisHasFrom = thisHasFrom > 0;
    }

    const filterValuesToIds = filterValueAssoValue.filterValuesTo.map(
      (filterValuesTo) => filterValuesTo.id
    );
    let thisHasTo: number | boolean = filterValueArticleIds.filter(
      (value) => value && filterValuesToIds.includes(value)
    ).length;
    if (filterValueAssoValue.toCondition === CONDITION_AND) {
      thisHasTo = thisHasTo === filterValuesToIds.length;
    } else {
      thisHasTo = thisHasTo > 0;
    }

    if (thisHasFrom) {
      hasFrom = true;
      globalHasFrom = true;
      filterValues = [
        ...filterValues,
        ...filterValueAssoValue.filterValuesTo.map(
          (filterValuesTo) => filterValuesTo.id
        ),
      ];
    }
    if (thisHasTo && filterValueAsso.bijection) {
      filterValues = [
        ...filterValues,
        ...filterValueAssoValue.filterValuesFrom.map(
          (filterValuesFrom) => filterValuesFrom.id
        ),
      ];
    }
    if (filterValues.length > 0) {
      let commonFilterFilterValues: number[] = [];
      if (fArticle.fArticleProp.filterValueArticles) {
        for (const commonFilter of filterValueAssoValue.commonFilters) {
          let filterValueArticles =
            fArticle.fArticleProp.filterValueArticles.filter(
              (f) => getFilterId(f) === commonFilter.id
            );
          const parentIds = filterValueArticles.map(
            (f) => f.filterValue?.parentId
          );
          filterValueArticles = filterValueArticles.filter(
            (f) => !parentIds.includes(f.filterValue?.id)
          );
          if (filterValueArticles) {
            commonFilterFilterValues = [
              ...commonFilterFilterValues,
              ...filterValueArticles.map((f) => f.filterValue?.id ?? 0),
            ];
          }
        }
      }
      if (commonFilterFilterValues.length > 0) {
        if (filterValueAssoValue.commonFiltersCondition === CONDITION_OR) {
          for (const commonFilterFilterValue of commonFilterFilterValues) {
            allFilterValues.push(
              JSON.stringify({
                condition: CONDITION_AND,
                values: [...filterValues, commonFilterFilterValue],
              })
            );
          }
        } else {
          allFilterValues.push(
            JSON.stringify({
              condition: CONDITION_AND,
              values: [...filterValues, ...commonFilterFilterValues],
            })
          );
        }
      } else {
        allFilterValues.push(
          JSON.stringify({
            condition: globalHasFrom ? globalToCondition : globalFromCondition,
            values: filterValues,
          })
        );
      }
    }
  }
  if (allFilterValues.length > 0) {
    const response = await requestApi({
      method: GET,
      path:
        FARTICLE_URL +
        objectToQuery({
          stock: ELASTIC_STOCK_AVAILABLE,
          indexable: 1,
          itemsPerPage: 20,
          isOrFilterValues: 1,
          "filterValues[]": allFilterValues,
          "order[stock]": "asc",
        }),
      allowError: false,
      token: state.globalState.token,
      paginate: true,
    });
    if (response.statusCode === 200) {
      return {
        ...filterValueAsso,
        hasFrom: hasFrom,
        fArticles: response.content.fArticles,
      };
    } else if (response.statusCode === 401) {
      toastr.info(i18n.t("word.info"), i18n.t("error.reconnect"));
    } else {
      toastr.error(i18n.t("word.error"), i18n.t("error.tryAgain"));
    }
  }
  return {
    ...filterValueAsso,
    hasFrom: hasFrom,
    fArticles: [],
  };
};

export const updateLoginCookie = async (): Promise<void> => {
  const state: RootState = store.getState();
  if (state.globalState.loginCookieIcecat !== undefined) {
    return;
  }
  const response = await requestApi({
    method: GET,
    path: ICECAT_LOGIN_COOKIE_URL,
    allowError: true,
    token: state.globalState.token,
  });
  if (response.statusCode === 200) {
    store.dispatch(set({ loginCookieIcecat: response.content }));
  }
};

export const getUrlsFournisseur = (
  fArtfourniss: FArtfournissFArticleInterface,
  fournisseur: UserInterface | undefined,
  fArticle: FArticleInterface | undefined
): UrlFournisseurInterface => {
  const result: UrlFournisseurInterface = {};
  let idUrl = "";
  let searchUrl = "";
  if (fournisseur?.fournisseurProp) {
    idUrl = fournisseur.fournisseurProp.linkId;
    searchUrl = fournisseur.fournisseurProp.linkSearch;
  }
  if (fArtfourniss.afReffourniss && idUrl) {
    result.afReffourniss = idUrl.replace(
      "%identifier%",
      fArtfourniss.afReffourniss
    );
  }
  if (searchUrl) {
    if (fArticle?.constructeurRef) {
      result.constructeurRef = searchUrl.replace(
        "%search%",
        fArticle?.constructeurRef
      );
    }
    if (fArticle?.arCodebarre) {
      result.arCodebarre = searchUrl.replace("%search%", fArticle?.arCodebarre);
    }
  }
  return result;
};

export const getPropertiesStartWith = (
  object: any,
  startsWith: string
): string[] => {
  const result: string[] = [];
  for (const [key, value] of Object.entries(object)) {
    if (key.startsWith(startsWith)) {
      result.push(key);
    }
  }
  return result;
};
