import React, { forwardRef, useImperativeHandle } from "react";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { CAN_SEARCH_SAGE_DO_TYPE } from "../../../utils/DoceneteteUtils";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";

interface State {
  apply: Function;
}

const FilterDoTypeComponent = React.memo(
  forwardRef(({ apply }: State, ref) => {
    const theme = useTheme();
    const [searchParams] = useSearchParams();
    const getChecked = React.useCallback(() => {
      const searchParamsObject = searchParamToObject(searchParams);
      return CAN_SEARCH_SAGE_DO_TYPE.map((doType) => {
        return (
          searchParamsObject["doType[]"] !== undefined &&
          searchParamsObject["doType[]"].includes(doType.toString())
        );
      });
    }, [searchParams]);
    const [checked, setChecked] = React.useState<boolean[]>(getChecked());
    const getNbSelected = React.useCallback(() => {
      return checked.filter(Boolean).length;
    }, [checked]);
    const [nbSelected, setNbSelected] = React.useState<number>(getNbSelected());
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const { t } = useTranslation();

    const handleChange = React.useCallback(
      (
        event: React.ChangeEvent<HTMLInputElement>,
        indexFilterValue: number
      ) => {
        setChecked((x) => {
          x[indexFilterValue] = event.target.checked;
          return [...x];
        });
      },
      []
    );

    const onCheckedChange = React.useCallback(() => {
      setNbSelected(getNbSelected());
      if (!isSmall) {
        apply();
      }
    }, [apply, getNbSelected, isSmall]);

    const updateChecked = React.useCallback(() => {
      setChecked(getChecked());
    }, [getChecked]);

    React.useEffect(() => {
      onCheckedChange();
    }, [checked]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      updateChecked();
    }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => ({
      getValue() {
        const doTypes = CAN_SEARCH_SAGE_DO_TYPE.filter(
          (doType, indexDoType) => checked[indexDoType]
        ).map((doType) => doType.toString());
        if (doTypes.length > 0) {
          return {
            "doType[]": doTypes,
          };
        }
        return {};
      },
    }));

    return (
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          sx={{
            backgroundColor: theme.palette.primary.main,
            justifyContent: "flex-start",
          }}
        >
          <Typography
            sx={{
              color: "white",
              marginTop: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("word.doType")}
            {nbSelected > 0 && (
              <Typography
                component="span"
                className="badge"
                sx={{ marginLeft: 1 }}
              >
                {nbSelected}
              </Typography>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {CAN_SEARCH_SAGE_DO_TYPE.map((doType, indexDoType) => (
            <FormGroup key={indexDoType}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked[indexDoType]}
                    onChange={(e) => handleChange(e, indexDoType)}
                  />
                }
                label={t("word.fdocentete.doType." + doType)}
              />
            </FormGroup>
          ))}
        </AccordionDetails>
      </Accordion>
    );
  })
);

export default FilterDoTypeComponent;
