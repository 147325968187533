import * as React from "react";
import { SyntheticEvent, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { Autocomplete, ListItem, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { InputInterface } from "../../../interfaces/InputInterface";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import {
  FArticleInterface,
  FGlossaireInterface,
} from "../../../interfaces/FArticleInterface";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

interface State {
  edit: boolean;
  setFArticle: Function;
  createFrom?: FArticleInterface;
  fArticle: FArticleInterface | undefined;
}

interface FormState {
  fGlossaires: InputInterface;
}

const FArticleGlossaireComponent = React.memo(
  React.forwardRef(
    ({ edit, setFArticle, createFrom, fArticle }: State, ref) => {
      const { t } = useTranslation();
      const fGlossaires = useAppSelector(
        (state: RootState) => state.globalState.fGlossaires
      );
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const theme = useTheme();

      const getDefaultValues = React.useCallback((): FormState => {
        const thisArticle = createFrom ?? fArticle;
        return {
          fGlossaires: {
            value:
              thisArticle?.fArtgloss?.map(
                (fArtgloss) => fArtgloss.fGlossaire
              ) ?? [],
            error: "",
          },
        };
      }, [createFrom, fArticle]);
      const [values, setValues] = React.useState<FormState>(getDefaultValues());

      const [thisEdit, setThisEdit] = React.useState(
        edit !== undefined ? edit : false
      );
      const [loading, setLoading] = React.useState(false);

      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const getValue = React.useCallback(() => {
        return {
          fArtgloss: values.fGlossaires.value.map(
            (fGlossaire: FGlossaireInterface) => {
              return {
                fGlossaire: {
                  glDomaine: fGlossaire.glDomaine,
                  glIntitule: fGlossaire.glIntitule,
                },
              };
            }
          ),
        };
      }, [values.fGlossaires.value]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const fArticleApi = new FormData();
        const thisValues = getValue();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            ...thisValues,
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          setFArticle(response.content);
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setFArticle, t, token]);

      const handleChangeAutocomplete = React.useCallback(
        (prop: keyof FormState) =>
          (event: SyntheticEvent<Element, Event>, value: any) => {
            setValues((v) => {
              return {
                ...v,
                // @ts-ignore
                [prop]: { ...v[prop], value: value, error: "" },
              };
            });
          },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [createFrom, fArticle]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {!(thisEdit || edit) ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography component="span">
                <IconButton onClick={handleThisEdit}>
                  <EditIcon />
                </IconButton>
              </Typography>
              <Box>
                {fArticle?.loaded &&
                  fArticle?.fArtgloss.map((fArtgloss, indexFArtgloss) => (
                    <ListItem
                      key={indexFArtgloss}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <Typography sx={{ wordBreak: "break-word" }}>
                        {fArtgloss.fGlossaire.glText}
                      </Typography>
                    </ListItem>
                  ))}
              </Box>
            </Box>
          ) : (
            <>
              <Autocomplete
                multiple
                fullWidth
                options={fGlossaires ?? []}
                getOptionLabel={(option: FGlossaireInterface) => {
                  return (
                    (option.glIntitule ?? "") + " " + (option.glText ?? "")
                  );
                }}
                isOptionEqualToValue={(
                  option: FGlossaireInterface,
                  value: FGlossaireInterface
                ) => {
                  return (
                    option.glDomaine === value.glDomaine &&
                    option.glIntitule === value.glIntitule
                  );
                }}
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      key={option.glDomaine + option.glIntitule}
                      style={{ display: "block" }}
                    >
                      <span
                        style={{
                          color: theme.palette.primary.main,
                          fontWeight: "bold",
                        }}
                      >
                        {option.glIntitule}
                      </span>{" "}
                      {option.glText}
                    </li>
                  );
                }}
                onChange={handleChangeAutocomplete("fGlossaires")}
                value={values.fGlossaires.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="off"
                    error={!!values.fGlossaires.error}
                    helperText={t(values.fGlossaires.error ?? "")}
                    label={t("word.glossaires")}
                    placeholder={t("word.glossaires")}
                  />
                )}
              />
              {!edit && (
                <>
                  <Tooltip title={t("word.cancel")}>
                    <IconButton onClick={handleThisEdit} disabled={loading}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("word.save")}>
                    <LoadingButton
                      variant="text"
                      color="inherit"
                      sx={{
                        borderRadius: "50%",
                        minWidth: "auto",
                        padding: "8px",
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                      loading={loading}
                      onClick={save}
                    >
                      <SaveIcon />
                    </LoadingButton>
                  </Tooltip>
                </>
              )}
            </>
          )}
        </>
      );
    }
  )
);

export default FArticleGlossaireComponent;
