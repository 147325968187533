import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { requestApi } from "../helpers/RequestApi";
import { GET } from "../utils/MethodUtils";
import getErrorApi from "../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { getTitle, searchParamToObject } from "../helpers/SearchParamHelper";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";

const RawHtmlScreen: React.FC = React.memo(() => {
  const { state }: any = useLocation();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);

  const load = React.useCallback(async () => {
    const searchParamsObject = searchParamToObject(searchParams);
    if (searchParamsObject && searchParamsObject.url) {
      const response = await requestApi({
        method: GET,
        path: searchParamsObject.url,
        allowError: false,
        getRawResponse: /\/api\/f_docentetes\/.*\/html/.test(
          searchParamsObject.url
        ),
        token: token,
      });
      if (response.statusCode === 200) {
        if (response?.content?.headers) {
          for (const header of response.content.headers) {
            if (
              header[0] === "content-type" &&
              header[1] === "application/json"
            ) {
              response.content = await response.content.json();
              break;
            }
          }
        }
        if (response.content?.html) {
          document.getElementsByTagName("html")[0].innerHTML =
            response.content.html;
          const url = new URL("http://" + searchParamsObject.url);
          const params = Object.fromEntries(new URLSearchParams(url.search));
          if (params.print === "1") {
            window.print();
          }
        } else {
          const base64 = response.content.base64;
          let blob;
          if (base64) {
            const binStr = atob(base64);
            const len = binStr.length;
            const arr = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }
            blob = new Blob([arr], { type: "application/pdf" });
          } else {
            blob = new Blob([await response.content.blob()], {
              type: "application/pdf",
            });
          }
          const url = window.URL.createObjectURL(blob);
          document.body.innerHTML =
            '<object data="' +
            url +
            '" type="application/pdf" frameborder="0" width="100%" style="height: 100vw">\n' +
            '    <embed src="' +
            url +
            '" width="100%" /> \n' +
            "</object>";
          // https://stackoverflow.com/a/67617893/6824121
          // (window as Window).location = window.URL.createObjectURL(blob);
        }
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
    } else {
      document.getElementsByTagName("html")[0].innerHTML = state?.html;
    }
  }, [searchParams, state?.html, t, token]);

  React.useEffect(() => {
    document.title = getTitle("rawHtml");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    load();
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
});

export default RawHtmlScreen;
