import React, { ChangeEvent } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { getPriceExpedition } from "../../../../helpers/ExpeditionHelper";
import { priceFormat } from "../../../../utils/FormatUtils";
import { FormHelperText, List, ListItem, ListItemText } from "@mui/material";
import { getUrlFArticleImage } from "../../../../helpers/FileHelper";
import { IMAGE_SIZE_LOGO, IMAGE_SIZE_SMALL } from "../../../../utils/FileUtils";
import HideImageIcon from "@mui/icons-material/HideImage";
import Typography from "@mui/material/Typography";
import {
  ArrayPostOrderInterface,
  PostOrderInterface,
} from "../../../../interfaces/OrderInterface";
import { InputInterface } from "../../../../interfaces/InputInterface";
import LivraisonsFormComponent from "../../user/livraison/LivraisonsFormComponent";
import FormLabel from "@mui/material/FormLabel";
import { getMainLivraison } from "../../../../helpers/UserHelper";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { FLivraisonInterface } from "../../../../interfaces/UserInterface";
import Tooltip from "@mui/material/Tooltip";
import { isFLivraisonValid } from "../../../../helpers/FDocenteteHelper";

interface Props {
  postOrder: PostOrderInterface;
  handleNext: any;
  handleBack: any;
  setArrayPostOrder: Function;
  showFArticles: boolean;
  hasLivraison: boolean;
}

interface FormState {
  expedition: InputInterface;
  fLivraison: InputInterface;
}

const StepperLivraisonOrderComponent: React.FC<Props> = React.memo(
  ({
    postOrder,
    setArrayPostOrder,
    handleNext,
    handleBack,
    showFArticles,
    hasLivraison,
  }) => {
    const expeditions = useAppSelector(
      (state: RootState) => state.globalState.expeditions
    );
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useState<FormState>(() => {
      let expedition = "";
      if (!hasLivraison) {
        expedition =
          postOrder.expedition === ""
            ? expeditions
                ?.find(
                  (expedition) => expedition.expeditionGrilles.length === 0
                )
                ?.id.toString() ?? ""
            : postOrder.expedition;
      }
      const mainLivraison = getMainLivraison();
      return {
        expedition: { value: expedition, error: "" },
        fLivraison: {
          value: mainLivraison ? mainLivraison.liNo : "",
          error: "",
        },
      };
    });
    const [error, setError] = React.useState<string>("");
    const [fLivraisonForm, setFLivraisonForm] = React.useState<
      FLivraisonInterface | undefined
    >(undefined);

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: {
                ...v[prop],
                value: (event.target as HTMLInputElement).value,
                error: "",
              },
            };
          });
        },
      []
    );

    const getLivraisonIsFree = React.useCallback(() => {
      const expedition = expeditions?.find(
        (x) => x.id.toString() === values.expedition.value
      );
      return expedition && expedition.expeditionGrilles.length === 0;
    }, [expeditions, values.expedition.value]);

    const getDeliveryAddressError = React.useCallback((): string => {
      if (getLivraisonIsFree()) {
        return "";
      }
      if (user?.fLivraisons && user?.fLivraisons.length === 0) {
        return t("sentence.deliveryAddressRequired");
      }
      const fLivraison = user?.fLivraisons?.find(
        (x) => x.liNo === Number(values.fLivraison.value)
      );
      if (!fLivraison) {
        return t("sentence.deliveryAddressRequired");
      }
      return isFLivraisonValid(fLivraison);
    }, [getLivraisonIsFree, t, user?.fLivraisons, values.fLivraison.value]);

    const onChangeValues = React.useCallback(() => {
      setError("");
      setArrayPostOrder((x: ArrayPostOrderInterface) => {
        const thisPostOrder = x.postOrders.find(
          (y) => y.doType === postOrder.doType
        );
        if (thisPostOrder !== undefined) {
          thisPostOrder.expedition = values.expedition.value;
          thisPostOrder.fLivraison = values.fLivraison.value;
        }
        return { ...x };
      });
      setError(getDeliveryAddressError());
    }, [
      getDeliveryAddressError,
      postOrder.doType,
      setArrayPostOrder,
      values.expedition.value,
      values.fLivraison.value,
    ]);

    const onAddressChanged = React.useCallback(
      (fLivraison?: FLivraisonInterface) => {
        setFLivraisonForm(undefined);
        setValues((v) => {
          if (fLivraison) {
            v.fLivraison.value = fLivraison.liNo;
          } else {
            v.fLivraison.value = "";
          }
          return { ...v };
        });
      },
      []
    );

    const handleChangeRadio = React.useCallback(
      (prop: keyof FormState) =>
        (event: ChangeEvent<HTMLInputElement>, value: string) => {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: value, error: "" },
            };
          });
        },
      []
    );

    const editLivraison = React.useCallback(
      (fLivraison: FLivraisonInterface) => {
        setFLivraisonForm(fLivraison);
      },
      []
    );

    const thisHandleNext = React.useCallback(() => {
      if (getLivraisonIsFree()) {
        handleNext();
        return;
      }
      const deliveryAddressError = getDeliveryAddressError();
      setError(deliveryAddressError);
      if (deliveryAddressError) {
        return;
      }
      handleNext();
    }, [getDeliveryAddressError, getLivraisonIsFree, handleNext]);

    React.useEffect(() => {
      onChangeValues();
    }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <FormControl>
          {showFArticles && (
            <List>
              {postOrder.orderFArticles.map(
                (orderFArticle, indexOrderFArticle) => (
                  <ListItem alignItems="center" key={indexOrderFArticle}>
                    <Box sx={{ flex: 0 }}>
                      <Typography>{orderFArticle.quantity}</Typography>
                    </Box>
                    <Box sx={{ marginX: 1 }}>
                      {orderFArticle.fArticle.fArticleProp?.images !== null ? (
                        <img
                          src={
                            (process.env.REACT_APP_API_URL ?? "") +
                            getUrlFArticleImage(
                              orderFArticle.fArticle.arRef,
                              orderFArticle.fArticle.fArticleProp?.images[0],
                              IMAGE_SIZE_SMALL
                            )
                          }
                          style={{
                            margin: "auto",
                            width: IMAGE_SIZE_SMALL / 2,
                          }}
                          alt={
                            "[" +
                            orderFArticle.fArticle.arRef +
                            "] " +
                            orderFArticle.fArticle.arDesign
                          }
                          loading="lazy"
                        />
                      ) : (
                        <HideImageIcon
                          sx={{
                            width: IMAGE_SIZE_LOGO,
                            height: IMAGE_SIZE_LOGO,
                          }}
                        />
                      )}
                    </Box>
                    <ListItemText primary={orderFArticle.fArticle.arDesign} />
                  </ListItem>
                )
              )}
            </List>
          )}
          <RadioGroup
            value={values.expedition.value}
            onChange={handleChange("expedition")}
          >
            {hasLivraison && (
              <Typography>{t("sentence.keepDelivery")}</Typography>
            )}
            {expeditions?.map((expedition, indexExpedition) => {
              const fLivraison = user?.fLivraisons?.find(
                (fLivraison) =>
                  fLivraison?.liNo.toString() ===
                  values.fLivraison.value.toString()
              );
              const price = getPriceExpedition(
                postOrder,
                expedition,
                fLivraison
              );
              let label = expedition.name;
              if (price !== undefined && price > 0) {
                label +=
                  " (" +
                  priceFormat(price, i18n.language, "EUR") +
                  " HT | " +
                  priceFormat(Math.round(price * 1.085), i18n.language, "EUR") +
                  " TTC)";
              }
              const livraisonsIsEmpty =
                !user?.fLivraisons || user.fLivraisons.length === 0;
              return (
                <Box key={indexExpedition}>
                  <FormControlLabel
                    value={expedition.id.toString()}
                    control={<Radio />}
                    label={label}
                  />
                  <Box>
                    {expedition.id.toString() === values.expedition.value &&
                      !getLivraisonIsFree() && (
                        <>
                          <FormControl sx={{ pl: 2 }}>
                            <FormLabel
                              id="radio-livraison"
                              error={livraisonsIsEmpty}
                            >
                              {t(
                                livraisonsIsEmpty
                                  ? "sentence.needLivraison"
                                  : "word.livraison"
                              )}
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="radio-livraison"
                              value={values.fLivraison.value}
                              onChange={handleChangeRadio("fLivraison")}
                            >
                              {user?.fLivraisons?.map(
                                (fLivraison, indexFlivraison) => (
                                  <Box key={indexFlivraison}>
                                    <FormControlLabel
                                      value={fLivraison.liNo}
                                      control={<Radio />}
                                      label={fLivraison.liAdresse}
                                    />
                                    <Tooltip title={t("word.edit.word")}>
                                      <IconButton
                                        size="large"
                                        onClick={() =>
                                          editLivraison(fLivraison)
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                )
                              )}
                            </RadioGroup>
                          </FormControl>
                          <LivraisonsFormComponent
                            onAddressChanged={onAddressChanged}
                            fLivraison={fLivraisonForm}
                            setFLivraisonForm={setFLivraisonForm}
                          />
                        </>
                      )}
                  </Box>
                </Box>
              );
            })}
          </RadioGroup>
        </FormControl>
        {error !== "" && <FormHelperText error>{error}</FormHelperText>}
        <Box>
          <Button
            disabled={
              (!getLivraisonIsFree() && !values.fLivraison.value) ||
              (!getLivraisonIsFree() &&
                getPriceExpedition(
                  postOrder,
                  expeditions?.find(
                    (expedition) =>
                      expedition.id.toString() === values.expedition.value
                  ),
                  user?.fLivraisons?.find(
                    (fLivraison) =>
                      fLivraison?.liNo.toString() ===
                      values.fLivraison.value.toString()
                  )
                ) === undefined) ||
              error !== ""
            }
            variant="contained"
            onClick={thisHandleNext}
            sx={{ mt: 1, mr: 1 }}
          >
            {t("word.next")}
          </Button>
          <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
            {t("word.back")}
          </Button>
        </Box>
      </>
    );
  }
);

export default StepperLivraisonOrderComponent;
