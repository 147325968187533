import * as React from "react";
import { SyntheticEvent, useEffect, useImperativeHandle } from "react";
import {
  DriverInterface,
  FArticleSmallInterface,
  FournisseurArticleInterface,
} from "../../../interfaces/FArticleInterface";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Typography from "@mui/material/Typography";
import { InputInterface } from "../../../interfaces/InputInterface";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { DRIVER_URL, FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { getUrlConstructorImage } from "../../../helpers/FileHelper";
import { IMAGE_SIZE_LOGO } from "../../../utils/FileUtils";
import { Autocomplete, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useSearchParams } from "react-router-dom";
import { generateTechnicalSheet } from "../../../helpers/FArticleHelper";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  edit: boolean | undefined;
  setFArticle: Function | undefined;
  constructorSize?: number;
  setFullSizeConstructor?: Function;
  temporaryIcecat?: any;
  fournisseurProduct?: FournisseurArticleInterface;
}

interface State2 {
  fArticle: FArticleSmallInterface | undefined;
  urlConstructorImage: string | null;
  constructorSize: number | undefined;
}

interface FormState {
  driver: InputInterface;
}

const FArticleDriverImageComponent: React.FC<State2> = React.memo(
  ({ fArticle, urlConstructorImage, constructorSize }) => {
    return (
      <>
        {urlConstructorImage !== null ? (
          <img
            src={
              (process.env.REACT_APP_API_URL ?? "") + urlConstructorImage ?? ""
            }
            style={{
              maxWidth: "100%",
              margin: "auto",
              padding: 5,
              display: "block",
              ...(constructorSize && {
                width: constructorSize + "px",
              }),
            }}
            alt={fArticle?.fArticleProp?.driver?.name}
            loading="lazy"
          />
        ) : (
          <Typography component="span">
            {fArticle?.fArticleProp?.driver?.name}
          </Typography>
        )}
      </>
    );
  }
);

const FArticleDriverComponent = React.memo(
  React.forwardRef(
    (
      {
        fArticle,
        edit,
        setFArticle,
        constructorSize,
        setFullSizeConstructor,
        temporaryIcecat,
        fournisseurProduct,
      }: State,
      ref
    ) => {
      const { t } = useTranslation();
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const drivers = useAppSelector(
        (state: RootState) => state.globalState.drivers
      );
      const getDefaultValue = React.useCallback((): FormState => {
        return {
          driver: {
            value:
              drivers?.find(
                (driver) => driver.id === fArticle?.fArticleProp?.driver?.id
              ) ?? null,
            error: "",
          },
        };
      }, [drivers, fArticle]);
      const [values, setValues] = React.useState<FormState>(getDefaultValue());
      const [icecatBrand, setIcecatBrand] = React.useState<
        string | undefined | null
      >();
      const [fournisseurArticleBrand, setFournisseurArticleBrand] =
        React.useState<string | undefined | null>();
      const [thisEdit, setThisEdit] = React.useState(
        edit !== undefined ? edit : false
      );
      const [loading, setLoading] = React.useState(false);
      const [searchParams, setSearchParams] = useSearchParams();

      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const getValue = React.useCallback(() => {
        return {
          driver: values.driver.value,
        };
      }, [values.driver.value]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const fArticleApi = new FormData();
        const thisValues = getValue();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            fArticleProp: {
              driver: thisValues?.driver?.id
                ? DRIVER_URL + "/" + thisValues.driver.id
                : null,
            },
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          if (setFArticle) {
            setFArticle(response.content);
          }
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setFArticle, t, token]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues(getDefaultValue());
        if (setFullSizeConstructor) {
          setFullSizeConstructor(thisEdit);
        }
      }, [fArticle, thisEdit || edit]); // eslint-disable-line react-hooks/exhaustive-deps

      const [urlConstructorImage, setUrlConstructorImage] = React.useState(
        getUrlConstructorImage(
          fArticle?.fArticleProp?.driver,
          constructorSize ?? IMAGE_SIZE_LOGO
        )
      );

      const handleChangeAutocomplete = React.useCallback(
        (prop: keyof FormState) =>
          (event: SyntheticEvent<Element, Event>, value: any) => {
            setValues((v) => {
              return {
                ...v,
                // @ts-ignore
                [prop]: { ...v[prop], value: value, error: "" },
              };
            });
          },
        []
      );

      useEffect(() => {
        setUrlConstructorImage(
          getUrlConstructorImage(
            fArticle?.fArticleProp?.driver,
            constructorSize ?? IMAGE_SIZE_LOGO
          )
        );
      }, [fArticle?.fArticleProp?.driver]); // eslint-disable-line react-hooks/exhaustive-deps

      useEffect(() => {
        let thisBrand: DriverInterface[] | undefined;
        if (temporaryIcecat) {
          const temp: any = {
            ficheTechnique: temporaryIcecat,
          };
          const technicalSheet = generateTechnicalSheet(temp, 1);
          setIcecatBrand(technicalSheet?.brand);
          if (technicalSheet?.brand) {
            thisBrand = drivers?.filter((d) =>
              d.name.toLowerCase().includes(technicalSheet.brand.toLowerCase())
            );
          }
        }
        if (fournisseurProduct && thisBrand?.length !== 1) {
          thisBrand = drivers?.filter(
            (d) =>
              (fournisseurProduct.fabricant ?? "") !== "" &&
              d.name
                .toLowerCase()
                .includes((fournisseurProduct.fabricant ?? "").toLowerCase())
          );
          setFournisseurArticleBrand(fournisseurProduct.fabricant ?? undefined);
        }
        if (thisBrand?.length === 1) {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              driver: { ...v.driver, value: thisBrand[0] },
            };
          });
        }
      }, [temporaryIcecat, fournisseurProduct]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {thisEdit || edit ? (
            <>
              {icecatBrand && (
                <Typography>
                  {t("word.brand") + " Icecat: " + icecatBrand}
                </Typography>
              )}
              {fournisseurArticleBrand && (
                <Typography>
                  {t("word.brand") +
                    " Nove Fournisseur: " +
                    fournisseurArticleBrand}
                </Typography>
              )}
              <Autocomplete
                fullWidth
                options={drivers ?? []}
                getOptionLabel={(driver: DriverInterface) => {
                  return driver.name ?? "";
                }}
                isOptionEqualToValue={(
                  option: DriverInterface,
                  value: DriverInterface
                ) => {
                  return option.id === value.id;
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                onChange={handleChangeAutocomplete("driver")}
                value={values.driver.value}
                defaultValue={values.driver.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="off"
                    error={!!values.driver.error}
                    helperText={t(values.driver.error ?? "")}
                    label={t("word.constructor")}
                    placeholder={t("word.constructor")}
                  />
                )}
              />
              {!edit && (
                <Box sx={{ textAlign: "center" }}>
                  <Tooltip title={t("word.cancel")} placement="left">
                    <IconButton onClick={handleThisEdit} disabled={loading}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("word.save")} placement="right">
                    <LoadingButton
                      variant="text"
                      color="inherit"
                      sx={{
                        borderRadius: "50%",
                        minWidth: "auto",
                        padding: "8px",
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                      loading={loading}
                      onClick={save}
                    >
                      <SaveIcon />
                    </LoadingButton>
                  </Tooltip>
                </Box>
              )}
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isAdmin && setFullSizeConstructor && (
                <IconButton onClick={handleThisEdit}>
                  <EditIcon />
                </IconButton>
              )}
              {fArticle?.fArticleProp?.driver && (
                <>
                  <Box
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();
                      const newSearchParams: any = {
                        "brands[]": fArticle?.fArticleProp?.driver?.name,
                      };
                      setSearchParams(newSearchParams);
                    }}
                  >
                    <FArticleDriverImageComponent
                      fArticle={fArticle}
                      urlConstructorImage={urlConstructorImage}
                      constructorSize={constructorSize}
                    />
                  </Box>
                </>
              )}
            </Box>
          )}
        </>
      );
    }
  )
);

export default FArticleDriverComponent;
