import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import {
  Button,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { AUTHENTICATION_TOKEN_URL } from "../../../utils/UrlsUtils";
import { initAppHelper } from "../../../helpers/InitAppHelper";
import {
  HOME_PAGE,
  LOGIN_PAGE,
  REGISTER_PAGE,
} from "../../../utils/RouteUtils";
import { InputInterface } from "../../../interfaces/InputInterface";
import { set } from "../../../app/globalSlice";
import { useTheme } from "@mui/material/styles";
import LostPasswordComponent from "./LostPasswordComponent";

interface State {
  redirect?: string | null;
  children?: React.ReactNode;
  requireAdmin?: boolean;
}

interface FormState {
  emailOrCtNum: InputInterface;
  password: InputInterface;
  showPassword: boolean;
}

const LoginComponent: React.FC<State> = React.memo(
  ({ redirect, children, requireAdmin }) => {
    const theme = useTheme();
    const cart = useAppSelector((state: RootState) => state.globalState.cart);
    const [values, setValues] = React.useState<FormState>({
      emailOrCtNum: { value: process.env.REACT_APP_DEFAULT_LOGIN, error: "" },
      password: { value: process.env.REACT_APP_DEFAULT_PASSWORD, error: "" },
      showPassword: false,
    });
    const location = useLocation();
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const refreshPage = useAppSelector(
      (state: RootState) => state.globalState.refreshPage
    );
    const [openDialog, setOpenDialog] = React.useState(false);
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const dispatch = useAppDispatch();
    const [init, setInit] = React.useState(false);

    const handleClose = React.useCallback(() => {
      setOpenDialog(false);
    }, []);

    const handleOpen = React.useCallback(() => {
      setOpenDialog(true);
    }, []);

    const load = React.useCallback(
      (force: boolean = false) => {
        setInit(true);
        if (children === undefined) {
          dispatch(set({ refreshPage: false }));
        }
      },
      [children, dispatch]
    );

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const handleClickShowPassword = React.useCallback(() => {
      setValues((v) => {
        return {
          ...v,
          showPassword: !values.showPassword,
        };
      });
    }, [values]);

    const handleMouseDownPassword = React.useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
      },
      []
    );

    const handleLogin = React.useCallback(async () => {
      if (loading) {
        return;
      }
      setLoading(true);
      let param = "";
      if (cart !== undefined) {
        param = "?cart=" + cart.id;
      }
      const response = await requestApi({
        method: POST,
        path: AUTHENTICATION_TOKEN_URL + param,
        allowError: true,
        body: {
          identifier: values.emailOrCtNum.value,
          password: values.password.value,
        },
      });
      if (response.statusCode === 200) {
        if (await initAppHelper(response.content.token)) {
          if (redirect !== null) {
            navigate(redirect ?? HOME_PAGE);
          } else if (location.pathname === LOGIN_PAGE) {
            navigate(HOME_PAGE);
          }
        } else {
          navigate(LOGIN_PAGE);
          toastr.error(t("word.error"), t("error.tryAgain"));
        }
      } else if (response.statusCode === 401) {
        toastr.error(t("word.error"), t("error.invalid_credentials"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      setLoading(false);
    }, [
      cart,
      loading,
      location.pathname,
      navigate,
      redirect,
      t,
      values.emailOrCtNum.value,
      values.password.value,
    ]);

    React.useEffect(() => {
      load();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (init && refreshPage) {
        load(true);
      }
    }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {!user || (requireAdmin && !isAdmin) ? (
          <>
            <LostPasswordComponent
              handleClose={handleClose}
              openDialog={openDialog}
            />
            <CssBaseline />
            <Container maxWidth="md" sx={{ mt: 5 }}>
              {requireAdmin && !isAdmin && user && (
                <Typography sx={{ mb: 1, color: theme.palette.error.main }}>
                  {t("sentence.requireAdmin")}
                </Typography>
              )}
              <Typography component="h1" variant="h4" sx={{ mb: 1 }}>
                {t("word.login")}
              </Typography>
              <Box sx={{ width: "100%" }}>
                <React.Fragment>
                  <Box component="form" noValidate>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          autoComplete="off"
                          error={!!values.emailOrCtNum.error}
                          helperText={t(values.emailOrCtNum.error ?? "")}
                          sx={{ width: "100%" }}
                          required
                          type="text"
                          value={values.emailOrCtNum.value}
                          onChange={handleChange("emailOrCtNum")}
                          label={t("field.emailOrCtNum")}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              handleLogin();
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          sx={{ width: "100%" }}
                          variant="outlined"
                          required
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              handleLogin();
                            }
                          }}
                        >
                          <InputLabel>{t("field.password")}</InputLabel>
                          <OutlinedInput
                            error={!!values.password.error}
                            type={values.showPassword ? "text" : "password"}
                            value={values.password.value}
                            onChange={handleChange("password")}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label={t("field.action.toggle_password")}
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {values.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label={t("field.password")}
                          />
                          {!!values.password.error && (
                            <FormHelperText error>
                              {t(values.password.error ?? "")}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 2,
                          }}
                        >
                          <LoadingButton
                            variant="text"
                            disabled={loading}
                            onClick={handleOpen}
                          >
                            {t("word.lostPassword")}
                          </LoadingButton>
                          <LoadingButton
                            variant="contained"
                            loading={loading}
                            onClick={handleLogin}
                          >
                            {t("word.login")}
                          </LoadingButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </React.Fragment>
              </Box>
              {!(requireAdmin && !isAdmin) && (
                <Box>
                  <Typography component="h1" variant="h4" sx={{ mb: 1, mt: 3 }}>
                    {t("word.new_client")}
                  </Typography>
                  <Link
                    style={{ textDecoration: "none", color: "inherit" }}
                    to={REGISTER_PAGE}
                  >
                    <Button variant="contained">
                      {t("word.create_account")}
                    </Button>
                  </Link>
                </Box>
              )}
            </Container>
          </>
        ) : (
          <>{children}</>
        )}
      </>
    );
  }
);

export default LoginComponent;
