import { ContentInterface } from "../interfaces/ContentInterface";

export const formatCkEditor = (html: string | null) => {
  if (!html) {
    return "";
  }
  const regexp = /<oembed([\S\s]*?)oembed>/g;
  const matches = html.matchAll(regexp);
  const tableSizes: any[] = [
    {
      name: "dotted",
      value: 2,
    },
    {
      name: "dashed",
      value: 3,
    },
    {
      name: "double",
      value: 4,
    },
    {
      name: "groove",
      value: 5,
    },
    {
      name: "ridge",
      value: 6,
    },
    {
      name: "inset",
      value: 7,
    },
    {
      name: "outset",
      value: 8,
    },
  ];
  const newDiv = document.createElement("div");
  newDiv.style.display = "none";
  newDiv.innerHTML = html;
  const tables = newDiv.querySelectorAll("table");
  tables.forEach((el) => {
    const styles = (el.getAttribute("style")?.split(";") ?? []).filter(
      (x) => x
    );
    for (const style of styles) {
      if (style.startsWith("border-style")) {
        for (const tableSize of tableSizes) {
          if (style.includes(tableSize.name)) {
            el.classList.add("table-big-border-" + tableSize.value);
            break;
          }
        }
      }
    }
  });
  html = newDiv.innerHTML;
  newDiv.remove();

  for (const match of Array.from(matches)) {
    const urls = match[0].match(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
    );
    let url = "";
    if (urls) {
      url = urls[0];
    }
    if (!url.includes("embed")) {
      const urlObject = new URL(url);
      const urlSearchParams = Object.fromEntries(
        new URLSearchParams(urlObject.search).entries()
      );
      url = "https://www.youtube.com/embed/" + urlSearchParams.v;
    }
    html = html.replace(
      match[0],
      '<div class="ck-media__wrapper" data-oembed-url="' +
        url +
        '"><div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;"><iframe src="' +
        url +
        '" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe></div></div>'
    );
  }
  return html;
};

export const contentHasContent = (
  content: ContentInterface | null | undefined
) => {
  let tabs: any = null;
  if (content) {
    if (content.hasOwnProperty("content")) {
      tabs = content?.content?.tabs;
    } else {
      // @ts-ignore
      tabs = content?.tabs;
    }
  }
  if (tabs && tabs.length > 0) {
    for (const tab of tabs) {
      if (tab.rows && tab.rows.length > 0) {
        for (const row of tab.rows) {
          if (row.columns && row.columns.length > 0) {
            for (const column of row.columns) {
              if (typeof column?.data === "string") {
                if (column?.data.trim() !== "") {
                  return true;
                }
              }
            }
          }
        }
      }
    }
  }
  return false;
};
