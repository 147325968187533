// doExpedit
import {
  SAGE_DO_TYPE_ACHAT_CC,
  SAGE_DO_TYPE_ACHAT_FA,
  SAGE_DO_TYPE_ACHAT_FC,
  SAGE_DO_TYPE_ACHAT_LI,
  SAGE_DO_TYPE_VENTE_BC,
  SAGE_DO_TYPE_VENTE_BL,
  SAGE_DO_TYPE_VENTE_DE,
  SAGE_DO_TYPE_VENTE_FA,
  SAGE_DO_TYPE_VENTE_FC,
  SAGE_DO_TYPE_VENTE_PL,
} from "./DoceneteteUtils";
import theme from "../app/Theme";

export const PORT_COMPTOIR = 1; // Enlèvement au Comptoir
export const PORT_SOCIETE = 2; // Livraison par la société
export const PORT_LIVRAISON = 3; // Livraison

export const SUIVI_STOCK_TYPE_AUCUN = 0;
export const SUIVI_STOCK_TYPE_CMUP = 2;
export const SUIVI_STOCK_TYPE_FIFO = 3;
export const SUIVI_STOCK_TYPE_LIFO = 4;
export const SUIVI_STOCK_TYPE_LOT = 5;
export const SUIVI_STOCK_TYPE_SERIE = 1;

export const ALL_SUIVISTOCK = [
  SUIVI_STOCK_TYPE_AUCUN,
  SUIVI_STOCK_TYPE_CMUP,
  SUIVI_STOCK_TYPE_FIFO,
  SUIVI_STOCK_TYPE_LIFO,
  SUIVI_STOCK_TYPE_LOT,
  SUIVI_STOCK_TYPE_SERIE,
];

export const FDOCENETETE_COLORS: any = {
  [SAGE_DO_TYPE_VENTE_DE]: theme.palette.warning,
  [SAGE_DO_TYPE_VENTE_BC]: theme.palette.info,
  [SAGE_DO_TYPE_VENTE_BL]: {
    main: "#EB6020",
    contrastText: "#ffffff",
  },
  [SAGE_DO_TYPE_VENTE_PL]: theme.palette.success,
  [SAGE_DO_TYPE_VENTE_FA]: theme.palette.secondary,
  [SAGE_DO_TYPE_VENTE_FC]: theme.palette.secondary,
};

export const CC_ENVOYE = 0;
export const CC_RELIQUAT = 1;
export const CC_FACTURE_SAISIE = 2;

export const LI_SAISI = 0;
export const LI_CONFIRME = 1;
export const LI_RECEPTIONNE = 2;

export const FA_SAISI = 0;
export const FA_CONFIRME = 1;
export const FA_A_COMPTABILISER = 2;

export const FDOCENETETE_STATUS: any = {
  [SAGE_DO_TYPE_ACHAT_CC]: [CC_ENVOYE, CC_RELIQUAT, CC_FACTURE_SAISIE],
  [SAGE_DO_TYPE_ACHAT_LI]: [LI_SAISI, LI_CONFIRME, LI_RECEPTIONNE],
  [SAGE_DO_TYPE_ACHAT_FA]: [FA_SAISI, FA_CONFIRME, FA_A_COMPTABILISER],
  [SAGE_DO_TYPE_ACHAT_FC]: [],
};
