import React, { SyntheticEvent, useRef } from "react";
import {
  Autocomplete,
  CircularProgress,
  DialogProps,
  FormHelperText,
  Grid,
  TextField,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ContactInterface } from "../../../../interfaces/ContactInterface";
import { InputInterface } from "../../../../interfaces/InputInterface";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../../helpers/GetErrorApi";
import { GET, PATCH, POST } from "../../../../utils/MethodUtils";
import {
  CONTACT_MESSAGE_URL,
  CONTACT_URL,
  FCOMPTET_URL,
} from "../../../../utils/UrlsUtils";
import { objectToQuery, requestApi } from "../../../../helpers/RequestApi";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  ALL_TYPE_CONTACT,
  STATUS_CLOSED,
} from "../../../../utils/ContactUtils";
import InputLabel from "@mui/material/InputLabel";
import ContentComponent from "../../../content/ContentComponent";
import CkEditorComponent from "../../../content/CkEditorComponent";
import Typography from "@mui/material/Typography";
import notEmptyValidator from "../../../../helpers/validator/NotEmptyValidator";
import FeedbackMessagesComponent from "../FeedbackMessagesComponent";
import { DrawerContainerId } from "../../DrawerComponent";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { UserInterface } from "../../../../interfaces/UserInterface";

interface State {
  contact?: ContactInterface | null;
  successFunction: Function;
  asAdmin: boolean;
}

interface FormState {
  subject: InputInterface;
  type: InputInterface;
  user: InputInterface;
}

const FeedbackFormComponent: React.FC<State> = React.memo(
  ({ contact, successFunction, asAdmin }) => {
    const { t } = useTranslation();
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [loading, setLoading] = React.useState(false);
    const [canEdit] = React.useState(!contact);
    const ckEditorRef: any = useRef();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const getDefaultValue = React.useCallback((): FormState => {
      return {
        user: {
          value: null,
          error: "",
        },
        subject: {
          value: contact?.subject ?? "",
          error: "",
        },
        type: {
          value: contact?.type ?? "",
          error: "",
        },
      };
    }, [contact]);
    const [values, setValues] = React.useState<FormState>(getDefaultValue());
    const [searchUser, setSearchUser] = React.useState<string>("");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [loadingClose, setLoadingClose] = React.useState(false);
    const handleClose = React.useCallback(() => {
      setOpenDialog(false);
    }, []);
    const handleOpen = React.useCallback(() => {
      setOpenDialog(true);
    }, []);
    const closeContact = React.useCallback(async () => {
      if (!contact) {
        return;
      }
      setLoadingClose(true);
      const response = await requestApi({
        method: PATCH,
        path: CONTACT_URL + "/" + contact.id,
        allowError: true,
        token: token,
        body: {
          status: STATUS_CLOSED,
        },
      });
      if (response.statusCode === 200) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.contact_closed")
        );
        successFunction();
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      setLoadingClose(false);
    }, [contact, successFunction, t, token]);
    const [users, setUsers] = React.useState<UserInterface[]>([]);
    const [isTyping, setIsTyping] = React.useState<boolean>(false);
    const [isSearching, setIsSearching] = React.useState<boolean>(false);
    const handleSearchUser = React.useCallback(
      (event: SyntheticEvent<Element, Event>, value: string) => {
        setSearchUser(value);
        setUsers([]);
        setIsTyping(true);
      },
      []
    );

    const handleChangeAutocomplete = React.useCallback(
      (prop: keyof FormState) =>
        (event: SyntheticEvent<Element, Event>, value: any) => {
          setValues((v) => {
            return {
              ...v,
              // @ts-ignore
              [prop]: { ...v[prop], value: value, error: "" },
            };
          });
        },
      []
    );

    const createContact = React.useCallback(async () => {
      setLoading(true);
      const ckEditorValue = ckEditorRef.current.getValue();
      const subjectError = notEmptyValidator(values.subject.value);
      const typeError = notEmptyValidator(values.type.value);
      const messageError = notEmptyValidator(ckEditorValue);
      let userError = "";
      if (asAdmin) {
        userError = notEmptyValidator(values.user.value);
      }
      if (subjectError || typeError || messageError || userError) {
        const newValue: FormState = { ...values };
        if (subjectError) {
          newValue.subject.error = subjectError;
        }
        if (typeError) {
          newValue.type.error = typeError;
        }
        if (userError) {
          newValue.user.error = userError;
        }
        setValues(newValue);
        setLoading(false);
        return undefined;
      }
      const response = await requestApi({
        method: POST,
        path: CONTACT_URL,
        allowError: true,
        token: token,
        body: {
          subject: values.subject.value,
          ...(asAdmin &&
            isAdmin && {
              userIdentifier: values.user.value.userIdentifier,
            }),
          type: values.type.value,
          ...(asAdmin &&
            isAdmin && {
              newMessageAdmin: 0,
              newMessageUser: 1,
            }),
          contactMessages: [
            {
              ...(isAdmin && { isAdmin: asAdmin && isAdmin }),
              content: ckEditorValue,
            },
          ],
        },
      });
      if (response.statusCode === 201) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.contact_created")
        );
        successFunction();
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [asAdmin, isAdmin, successFunction, t, token, values]);

    const createContactMessage = React.useCallback(async () => {
      setLoading(true);
      const ckEditorValue = ckEditorRef.current.getValue();
      const messageError = notEmptyValidator(ckEditorValue);
      if (messageError) {
        setLoading(false);
        return undefined;
      }
      const response = await requestApi({
        method: POST,
        path: CONTACT_MESSAGE_URL,
        allowError: true,
        token: token,
        body: {
          content: ckEditorValue,
          contact: CONTACT_URL + "/" + contact?.id,
          ...(isAdmin && { isAdmin: asAdmin && isAdmin }),
        },
      });
      if (response.statusCode !== 201) {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      } else {
        const objDiv = document.getElementById(DrawerContainerId);
        if (objDiv) {
          objDiv.scrollTop = objDiv.scrollHeight;
        }
        ckEditorRef.current.reset();
      }
      setLoading(false);
    }, [asAdmin, contact?.id, isAdmin, t, token]);

    const save = React.useCallback(() => {
      if (contact) {
        createContactMessage();
      } else {
        createContact();
      }
    }, [contact, createContact, createContactMessage]);

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const handleChangeSelect = React.useCallback(
      (prop: keyof FormState) => (event: SelectChangeEvent) => {
        setValues((v) => {
          return {
            ...v,
            [prop]: {
              ...v[prop],
              value: event.target.value as string,
              error: "",
            },
          };
        });
      },
      []
    );

    const searchUsers = React.useCallback(async () => {
      setIsTyping(false);
      if (!user || !asAdmin) {
        return;
      }
      setIsSearching(true);
      const param: any = {
        ctNum: searchUser.trim().toUpperCase(),
      };
      const response = await requestApi({
        method: GET,
        path: FCOMPTET_URL + objectToQuery(param),
        allowError: false,
        paginate: false,
        token: token,
        timeout: 30_000,
      });
      if (response.statusCode === 200) {
        setUsers(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setIsSearching(false);
    }, [asAdmin, searchUser, t, token, user]);

    React.useEffect(() => {
      setValues(getDefaultValue());
    }, [user, asAdmin, contact]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        searchUsers();
      }, 500);
      return () => clearTimeout(timeoutTyping);
    }, [searchUsers]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Dialog
          maxWidth={maxWidth}
          fullScreen={fullScreen}
          onClose={handleClose}
          open={openDialog}
        >
          <DialogContent>
            <DialogContentText>
              {t("sentence.closeContact.question")}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Button onClick={handleClose}>{t("word.no")}</Button>
            <LoadingButton
              loading={loadingClose}
              variant="contained"
              onClick={closeContact}
            >
              {t("word.yes")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Grid container spacing={1} sx={{ marginBottom: 2 }}>
          {!contact?.id && asAdmin && isAdmin && (
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                loadingText={t("word.loading") + "..."}
                inputValue={searchUser}
                onInputChange={handleSearchUser}
                noOptionsText={
                  isSearching ? (
                    <Box sx={{ textAlign: "center" }}>
                      <CircularProgress />
                    </Box>
                  ) : searchUser !== "" && !isTyping ? (
                    t("sentence.no_option")
                  ) : (
                    t("word.sav.searchUser.label")
                  )
                }
                options={users ?? []}
                getOptionLabel={(user: UserInterface) => {
                  return user.userIdentifier ?? "";
                }}
                isOptionEqualToValue={(
                  option: UserInterface,
                  value: UserInterface
                ) => {
                  return option.userIdentifier === value.userIdentifier;
                }}
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      key={option.userIdentifier}
                      style={{ display: "block" }}
                    >
                      <span style={{ color: theme.palette.primary.main }}>
                        {"[" + option.userIdentifier + "] "}
                      </span>
                      {option.ctIntitule}
                    </li>
                  );
                }}
                onChange={handleChangeAutocomplete("user")}
                value={values.user.value}
                defaultValue={values.user.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    autoComplete="off"
                    error={!!values.user.error}
                    helperText={t(values.user.error ?? "")}
                    label={t("word.userIdentifier")}
                    placeholder={t("word.userIdentifier")}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              disabled={!canEdit}
              autoComplete="off"
              required
              error={!!values.subject.error}
              helperText={t(values.subject.error ?? "")}
              sx={{ width: "100%" }}
              type="text"
              value={values.subject.value}
              onChange={handleChange("subject")}
              label={t("field.subject")}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required error={!!values.type.error}>
              <InputLabel id="type-type">{t("field.type")}</InputLabel>
              <Select
                labelId="type-type"
                disabled={!canEdit}
                value={values.type.value}
                label={t("field.type")}
                onChange={handleChangeSelect("type")}
              >
                {ALL_TYPE_CONTACT.map((type, indexType) => (
                  <MenuItem value={type} key={indexType}>
                    {t("word.contact.type." + type)}
                  </MenuItem>
                ))}
              </Select>
              {!!values.type.error && (
                <FormHelperText error>
                  {t(values.type.error ?? "")}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {canEdit && values.type.value !== "" && (
            <Grid item xs={12}>
              <ContentComponent
                contentName={"contact_" + values.type.value}
                saveLocalStorage={true}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FeedbackMessagesComponent contact={contact} asAdmin={asAdmin} />
          </Grid>
          {contact?.status !== STATUS_CLOSED ? (
            <>
              <Box sx={{ textAlign: "center", width: "100%" }}>
                {asAdmin && (
                  <LoadingButton onClick={handleOpen}>
                    {t("sentence.closeContact.button")}
                  </LoadingButton>
                )}
              </Box>
              <Grid item xs={12}>
                <Typography>{t("word.yourMessage")}</Typography>
                <CkEditorComponent
                  ref={ckEditorRef}
                  text={""}
                  small={!(isAdmin && asAdmin)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  onClick={save}
                >
                  {t("word.send")}
                </LoadingButton>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Typography sx={{ textAlign: "center" }}>
                {t("word.contactClosed")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
);

export default FeedbackFormComponent;
