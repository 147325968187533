import React, { useImperativeHandle, useRef } from "react";
import Layout from "../components/common/Layout";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { set } from "../app/globalSlice";
import { Checkbox, Container, Grid, Tooltip } from "@mui/material";
import LetteringFormComponent from "../components/common/accountingSituation/filter/LetteringFormComponent";
import FiscalYearFormComponent from "../components/common/accountingSituation/filter/FiscalYearFormComponent";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { getLocaleDataGrid } from "../helpers/GetLanguage";
import { getTitle, searchParamToObject } from "../helpers/SearchParamHelper";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoginComponent from "../components/common/user/LoginComponent";
import { FEcritureRecsInterface } from "../interfaces/FEcritureRecInterface";
import { objectToQuery, requestApi } from "../helpers/RequestApi";
import { GET } from "../utils/MethodUtils";
import { FECRITUREC_URL } from "../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import {
  EC_SENS_CREDIT,
  EC_SENS_DEBIT,
} from "../utils/AccountingSituationUtils";
import { priceFormat } from "../utils/FormatUtils";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { RAW_HTML_PAGE } from "../utils/RouteUtils";
import { addHours, differenceInDays } from "date-fns";
import Box from "@mui/material/Box";
import RegularizeSituationComponent from "../components/common/accountingSituation/RegularizeSituationComponent";
import CreditAccountComponent from "../components/common/accountingSituation/CreditAccountComponent";
import Divider from "@mui/material/Divider";
import { FCreglementInterface } from "../interfaces/FCreglementInterface";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { GridColumnHeaderParams } from "@mui/x-data-grid/models/params/gridColumnHeaderParams";
import { getUrlPdfDoPiece } from "../helpers/FDocenteteHelper";
import ContentComponent from "../components/content/ContentComponent";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface State {
  checkAll: Function;
}

interface State2 {
  rows: FCreglementInterface[] | undefined;
  sentenceNbRow: boolean;
}

const ActionAccountingSituationComponent = React.memo(
  React.forwardRef(({ checkAll }: State, ref) => {
    const [checked, setChecked] = React.useState<boolean>(true);
    const accountingSituation = useAppSelector(
      (state: RootState) => state.globalState.accountingSituation
    );
    const handleChangeCheckbox = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        checkAll(event.target.checked);
      },
      [checkAll]
    );

    useImperativeHandle(ref, () => ({
      updateChecked(v: boolean) {
        setChecked(v);
      },
    }));

    return (
      <Box className="MuiDataGrid-columnHeaderTitle">
        {accountingSituation && accountingSituation.sumCreditFdocentetes < 0 ? (
          <Checkbox
            checked={checked ?? false}
            onChange={handleChangeCheckbox}
          />
        ) : (
          <>Actions</>
        )}
      </Box>
    );
  })
);

const RowNotCompta = React.memo(
  React.forwardRef(({ rows, sentenceNbRow }: State2, ref) => {
    const { t, i18n } = useTranslation();

    return (
      <>
        {rows && rows.length > 0 && (
          <>
            <Grid item xs={12}>
              {sentenceNbRow && (
                <Typography>
                  {t("sentence.paymentsNotCompta")
                    .replace("%quantity%", rows.length.toString())
                    .replace("%verb%", rows.length > 1 ? "ne sont" : "n'est")
                    .replaceAll("%s%", rows.length > 1 ? "s" : "")}
                </Typography>
              )}
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>{t("word.date")}</StyledTableCell>
                      <StyledTableCell>{t("word.libelle")}</StyledTableCell>
                      <StyledTableCell>{t("word.amount")}</StyledTableCell>
                      <StyledTableCell>{t("word.commande")}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, indexRow) => (
                      <StyledTableRow key={indexRow}>
                        <StyledTableCell>
                          {new Date(row.cbCreation).toLocaleDateString(
                            i18n.language,
                            {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              timeZone: "America/Cuiaba",
                            }
                          )}
                        </StyledTableCell>
                        <StyledTableCell>{row.rgLibelle}</StyledTableCell>
                        <StyledTableCell>
                          {priceFormat(
                            Number(row.rgMontant) * 100,
                            i18n.language,
                            "EUR"
                          )}
                        </StyledTableCell>
                        <StyledTableCell>{row.rgReference}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}
      </>
    );
  })
);

interface Total {
  local: {
    credit: number;
    debit: number;
  };
  global: {
    credit: number;
    debit: number;
  };
}

const AccountingSituationScreen: React.FC = React.memo(() => {
  const { t, i18n } = useTranslation();
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const accountingSituation = useAppSelector(
    (state: RootState) => state.globalState.accountingSituation
  );
  const fJournaux = useAppSelector(
    (state: RootState) => state.globalState.fJournaux
  );
  const actionsRef: any = useRef();
  const [fEcriturecs, setFEcriturecs] = React.useState<
    FEcritureRecsInterface[] | undefined
  >(undefined);
  const [paymentsNotCompta, setPaymentsNotCompta] = React.useState<
    FCreglementInterface[] | undefined
  >(undefined);
  const [avoirsNotCompta, setAvoirsNotCompta] = React.useState<
    FCreglementInterface[] | undefined
  >(undefined);
  const [checked, setChecked] = React.useState<string[] | undefined>(undefined);
  const [totalItems, setTotalItems] = React.useState(0);
  const [total, setTotal] = React.useState<Total | undefined>(undefined);
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [defaultItemsPerPage] = React.useState(50);
  const [amount, setAmount] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const getClassFEcriturec = React.useCallback(
    (fEcriturec: FEcritureRecsInterface) => {
      return differenceInDays(new Date(), new Date(fEcriturec.ecEcheance)) >
        0 &&
        fEcriturec.ecLettrage.trim() === "" &&
        ((fEcriturec.ecSens === EC_SENS_DEBIT && fEcriturec.ecMontant > 0) ||
          (fEcriturec.ecSens === EC_SENS_CREDIT && fEcriturec.ecMontant < 0))
        ? "text-danger"
        : "";
    },
    []
  );

  const handleChangeCheckbox = React.useCallback(
    (ecNo: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setChecked((x) => {
          if (x) {
            x.push(ecNo);
            return [...x];
          }
          return x;
        });
      } else {
        setChecked((x) => {
          x = x?.filter((y: string) => y !== ecNo);
          return x;
        });
      }
    },
    []
  );

  const checkAll = React.useCallback(
    (v: boolean) => {
      if (v) {
        if (fEcriturecs) {
          setChecked(
            fEcriturecs
              .filter((fEcriturec) => fEcriturec.checkable)
              .map((fEcriturec) => fEcriturec.ecNo)
          );
        }
      } else {
        setChecked([]);
      }
    },
    [fEcriturecs]
  );

  const columns: GridColDef[] = [
    {
      field: "joNum",
      headerName: t("column.joNum"),
      flex: 0,
      headerClassName: "background-nove",
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip
          title={
            fJournaux?.find((fJournau) => fJournau.joNum === params.row.joNum)
              ?.joIntitule ?? ""
          }
        >
          <Typography component="span">{params.row.joNum}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "ecDate",
      headerName: t("column.date"),
      flex: 0,
      headerClassName: "background-nove",
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) =>
        params.row.ecDate
          ? new Date(params.row.ecDate).toLocaleDateString(i18n.language, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              timeZone: "Etc/UTC",
            })
          : "",
    },
    {
      field: "ecRefpiece",
      headerName: t("column.ecRefpiece"),
      flex: 0,
      headerClassName: "background-nove",
      filterable: false,
      sortable: false,
    },
    {
      field: "ecIntitule",
      headerName: t("column.ecIntitule"),
      flex: 1,
      headerClassName: "background-nove",
      filterable: false,
      sortable: false,
    },
    {
      field: "ecLettrage",
      headerName: t("column.ecLettrage"),
      flex: 0,
      headerClassName: "background-nove",
      filterable: false,
      sortable: false,
    },
    {
      field: "ecEcheance",
      headerName: t("column.ecEcheance"),
      flex: 0,
      headerClassName: "background-nove",
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (
          params.row.ecEcheance &&
          new Date(params.row.ecEcheance) >= new Date("2000-01-01")
        ) {
          return new Date(params.row.ecEcheance).toLocaleDateString(
            i18n.language,
            {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              timeZone: "Etc/UTC",
            }
          );
        }
        return "";
      },
      cellClassName: (params: GridCellParams) => {
        return getClassFEcriturec(params.row);
      },
    },
    {
      field: "debit",
      headerName: t("column.debit"),
      flex: 0,
      headerClassName: "background-nove",
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.debit !== undefined) {
          return priceFormat(params.row.debit, i18n.language, "EUR");
        }
        if (params.row.ecSens === EC_SENS_DEBIT) {
          return priceFormat(params.row.ecMontant, i18n.language, "EUR");
        }
        return "";
      },
      cellClassName: (params: GridCellParams) => {
        return getClassFEcriturec(params.row);
      },
    },
    {
      field: "credit",
      headerName: t("column.credit"),
      flex: 0,
      headerClassName: "background-nove",
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.credit !== undefined) {
          return priceFormat(params.row.credit, i18n.language, "EUR");
        }
        if (params.row.ecSens === EC_SENS_CREDIT) {
          return priceFormat(params.row.ecMontant, i18n.language, "EUR");
        }
        return "";
      },
    },
    {
      field: "actions",
      headerName: t("column.actions"),
      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <>
            <ActionAccountingSituationComponent
              ref={actionsRef}
              checkAll={checkAll}
            />
          </>
        );
      },
      flex: 0,
      headerClassName: "background-nove",
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            {params.row.ecNo && (
              <>
                {params.row.ecRefpiece && (
                  <Link
                    style={{ textDecoration: "none", color: "inherit" }}
                    to={
                      RAW_HTML_PAGE +
                      objectToQuery({
                        url: getUrlPdfDoPiece(params.row.ecRefpiece),
                      })
                    }
                    target="_blank"
                  >
                    <IconButton>
                      <VisibilityIcon />
                    </IconButton>
                  </Link>
                )}
                {accountingSituation &&
                  accountingSituation.sumCreditFdocentetes < 0 && (
                    <Checkbox
                      disabled={!params.row.checkable}
                      checked={!checked || checked.includes(params.row.ecNo)}
                      onChange={handleChangeCheckbox(params.row.ecNo)}
                    />
                  )}
              </>
            )}
          </>
        );
      },
    },
  ];

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (fEcriturecs !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (!searchParamsObject.hasOwnProperty("page")) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (
        !searchParamsObject.hasOwnProperty("userIdentifier") ||
        searchParamsObject.userIdentifier !== user.userIdentifier
      ) {
        searchParamsObject.userIdentifier = user.userIdentifier;
        hasChanged = true;
      }
      if (hasChanged) {
        dispatch(set({ refreshPage: false }));
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        return;
      }
      if (
        !searchParamsObject.itemsPerPage ||
        !searchParamsObject.page ||
        !searchParamsObject.lettering ||
        !searchParamsObject.fiscal
      ) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      setTotal(undefined);
      const response = await requestApi({
        method: GET,
        path: FECRITUREC_URL + objectToQuery(searchParamsObject),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        dispatch(
          set({ accountingSituation: response.content.accountingSituation })
        );
        let maxId = 0;
        const newFEcriturecs = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          ).map(() => {
            let firstEcNo = "";
            if (response.content.fEcriturecs.length > 0) {
              firstEcNo = response.content.fEcriturecs[0].ecNo;
            }
            maxId++;
            return {
              ecNo: firstEcNo + "_" + maxId,
            };
          }),
          ...response.content.fEcriturecs,
        ];
        setTotal(response.content.total);
        setChecked(
          newFEcriturecs
            .filter(
              (newFEcriturec) =>
                newFEcriturec.checkable &&
                (getClassFEcriturec(newFEcriturec) !== "text-danger" ||
                  (newFEcriturec.ecSens === EC_SENS_DEBIT &&
                    newFEcriturec.ecMontant > 0) ||
                  (newFEcriturec.ecSens === EC_SENS_CREDIT &&
                    newFEcriturec.ecMontant < 0))
            )
            .map((newFEcriturec) => newFEcriturec.ecNo)
        );
        setFEcriturecs(newFEcriturecs);
        setTotalItems(response.content["totalItems"]);
        setPaymentsNotCompta(response.content.paymentsNotCompta);
        const thisAvoirsNotCompta: FCreglementInterface[] = [];
        if (accountingSituation) {
          for (const creditFdocentete of accountingSituation.creditFdocentetes) {
            thisAvoirsNotCompta.push({
              cbCreation: addHours(
                new Date(creditFdocentete.created),
                -4
              ).toString(),
              ctNumPayeur: creditFdocentete.userIdentifier,
              rgDate: creditFdocentete.created,
              rgLibelle: t("sentence.useAvoir").replace(
                "%doPiece%",
                creditFdocentete.fDocenteteId
              ),
              rgMontant: (creditFdocentete.amount / 100).toString(),
              rgReference: creditFdocentete.fDocenteteId,
            });
          }
        }
        setAvoirsNotCompta(thisAvoirsNotCompta);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [
      accountingSituation,
      defaultItemsPerPage,
      dispatch,
      fEcriturecs,
      getClassFEcriturec,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
    ]
  );

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setFEcriturecs(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setFEcriturecs(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const isAllChecked = React.useCallback(() => {
    return (
      (accountingSituation?.sumCreditFdocentetes &&
        accountingSituation.sumCreditFdocentetes > 0) ||
      (checked &&
        checked.length === fEcriturecs?.filter((x) => x.checkable)?.length)
    );
  }, [accountingSituation?.sumCreditFdocentetes, checked, fEcriturecs]);

  const getAmount = React.useCallback((): number => {
    if (isAllChecked()) {
      if (accountingSituation?.sumCreditFdocentetes) {
        return -accountingSituation.sumCreditFdocentetes;
      }
      return 0;
    }
    return (
      fEcriturecs
        ?.filter((fEcriturec) => checked?.includes(fEcriturec.ecNo))
        .reduce((a, b) => {
          let multiplier = 1;
          if (b.ecSens === EC_SENS_CREDIT) {
            multiplier = -1;
          }
          return a + b.ecMontant * multiplier;
        }, 0) ?? 0
    );
  }, [
    accountingSituation?.sumCreditFdocentetes,
    checked,
    fEcriturecs,
    isAllChecked,
  ]);

  React.useEffect(() => {
    setAmount(getAmount());
  }, [accountingSituation?.sumCreditFdocentetes, checked]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (actionsRef.current) {
      actionsRef.current.updateChecked(isAllChecked());
    }
  }, [checked]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    document.title = getTitle("accountingSituation");
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!user) {
      setSearchParams(
        {},
        {
          replace: true,
        }
      );
    }
    if (init && !refreshPage) {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams, user?.userIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);

  return (
    <Layout>
      <Container maxWidth="xl">
        <LoginComponent redirect={null}>
          <Grid container spacing={1} sx={{ marginY: 2 }}>
            <Grid item xs={12}>
              <CreditAccountComponent />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {((paymentsNotCompta && paymentsNotCompta.length > 0) ||
              (avoirsNotCompta && avoirsNotCompta.length > 0)) && (
              <Grid item xs={12}>
                <ContentComponent
                  contentName="paymentsNotCompta"
                  saveLocalStorage={true}
                />
              </Grid>
            )}
            <RowNotCompta rows={paymentsNotCompta} sentenceNbRow={true} />
            <RowNotCompta rows={avoirsNotCompta} sentenceNbRow={false} />
            <Grid item xs={12}>
              <ContentComponent
                contentName="accountingSituation"
                saveLocalStorage={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LetteringFormComponent />
            </Grid>
            <Grid item xs={12} md={6}>
              <FiscalYearFormComponent />
            </Grid>
            <Grid item xs={12}>
              {searchParamsObject.itemsPerPage &&
                searchParamsObject.page &&
                searchParamsObject.lettering &&
                searchParamsObject.fiscal && (
                  <>
                    {amount > 0 && (
                      <Box sx={{ textAlign: "right", mb: 2 }}>
                        <RegularizeSituationComponent
                          amount={amount}
                          checked={checked}
                          fEcriturecs={fEcriturecs}
                        />
                      </Box>
                    )}
                    <DataGrid
                      loading={refreshPage}
                      rows={refreshPage ? [] : fEcriturecs ?? []}
                      getRowId={(row) => row.ecNo}
                      sortingMode="server"
                      page={Number(searchParamsObject.page) - 1}
                      rowsPerPageOptions={[10, 25, 50]}
                      pageSize={Number(searchParamsObject.itemsPerPage)}
                      autoHeight={true}
                      columns={columns}
                      disableExtendRowFullWidth={true}
                      localeText={getLocaleDataGrid(i18n.language)}
                      onPageSizeChange={onPageSizeChange}
                      onPageChange={onPageChange}
                      rowCount={totalItems}
                    />
                    {amount > 0 && (
                      <Box sx={{ textAlign: "right", mt: 2 }}>
                        <RegularizeSituationComponent
                          amount={amount}
                          checked={checked}
                          fEcriturecs={fEcriturecs}
                        />
                      </Box>
                    )}
                    {amount < 0 && (
                      <Box sx={{ textAlign: "right", mt: 2 }}>
                        <Typography>
                          {t("word.creditSolde") + ": "}
                          {priceFormat(Math.abs(amount), i18n.language, "EUR")}
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
            </Grid>
            {total && (
              <Grid item xs={12}>
                <Table
                  size="small"
                  sx={{
                    width: "auto",
                    borderRadius: 1,
                    marginRight: 0,
                    marginLeft: "auto",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>{t("column.debit")}</StyledTableCell>
                      <StyledTableCell>{t("column.credit")}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!(
                      total.global.credit === total.local.credit &&
                      total.global.debit === total.local.debit
                    ) && (
                      <StyledTableRow>
                        <StyledTableCell>
                          {t("word.totalShowedLine")}
                        </StyledTableCell>
                        <StyledTableCell>
                          {priceFormat(total.local.debit, i18n.language, "EUR")}
                        </StyledTableCell>
                        <StyledTableCell>
                          {priceFormat(
                            total.local.credit,
                            i18n.language,
                            "EUR"
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                    <StyledTableRow>
                      {total.global.credit === total.local.credit &&
                      total.global.debit === total.local.debit ? (
                        <StyledTableCell>{t("word.total")}</StyledTableCell>
                      ) : (
                        <StyledTableCell>
                          {t("word.totalGlobal")}
                        </StyledTableCell>
                      )}
                      <StyledTableCell>
                        {priceFormat(total.global.debit, i18n.language, "EUR")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {priceFormat(total.global.credit, i18n.language, "EUR")}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Grid>
            )}
          </Grid>
        </LoginComponent>
      </Container>
    </Layout>
  );
});

export default AccountingSituationScreen;
