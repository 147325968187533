import React from "react";
import Layout from "../components/common/Layout";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { set } from "../app/globalSlice";
import { Alert, Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoginComponent from "../components/common/user/LoginComponent";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import {
  STORAGE_NEW_BROWSER,
  STORAGE_STICKY_EDITOR,
} from "../utils/StorageUtils";
import { useSearchParams } from "react-router-dom";
import { getTitle, searchParamToObject } from "../helpers/SearchParamHelper";
import CloseIcon from "@mui/icons-material/Close";
import CardAccountComponent from "./account/CardAccountComponent";
import CardAccountLivraisonComponent from "./account/CardAccountLivraisonComponent";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{
        width: "100%",
      }}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const AccountScreen: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const mailActivatedTrigger = useAppSelector(
    (state: RootState) => state.globalState.mailActivatedTrigger
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [mailValidated] = React.useState(
    searchParamToObject(searchParams)?.activated === "true"
  );
  const [value, setValue] = React.useState(0);
  const [showMailValidated, setShowMailValidated] =
    React.useState(mailValidated);
  const isAdmin = useAppSelector(
    (state: RootState) => state.globalState.isAdmin
  );
  const newBrowser = useAppSelector(
    (state: RootState) => state.globalState.newBrowser
  );
  const stickyEditor = useAppSelector(
    (state: RootState) => state.globalState.stickyEditor
  );

  const handleChangeNewBrowser = React.useCallback(() => {
    dispatch(set({ newBrowser: !newBrowser }));
    localStorage.setItem(STORAGE_NEW_BROWSER, JSON.stringify(!newBrowser));
  }, [dispatch, newBrowser]);

  const handleChangeStickyEditor = React.useCallback(() => {
    dispatch(set({ stickyEditor: !stickyEditor }));
    localStorage.setItem(STORAGE_STICKY_EDITOR, JSON.stringify(!stickyEditor));
  }, [dispatch, stickyEditor]);

  const load = React.useCallback(
    (force: boolean = false) => {
      setInit(true);
      dispatch(set({ refreshPage: false }));
    },
    [dispatch]
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    document.title = getTitle("account");
    load();
    const seachParamObject = searchParamToObject(searchParams);
    delete seachParamObject.activated;
    setSearchParams(seachParamObject, {
      replace: true,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init) {
      setShowMailValidated(true);
    }
  }, [mailActivatedTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <Container maxWidth="xl" sx={{ marginY: 2 }}>
        <LoginComponent redirect={null}>
          {showMailValidated && (
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowMailValidated(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {t("sentence.mailValidated")}
            </Alert>
          )}
          {init && (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
              }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider" }}
              >
                <Tab label={t("title.account")} {...a11yProps(0)} />
                <Tab label={t("word.livraison")} {...a11yProps(1)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={8} xl={6}>
                    <CardAccountComponent
                      showMailValidated={showMailValidated}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={8} xl={6}>
                    <CardAccountLivraisonComponent />
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
          )}
          {isAdmin && (
            <>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newBrowser}
                      onChange={handleChangeNewBrowser}
                    />
                  }
                  label={t("sentence.newBrowser")}
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={stickyEditor}
                      onChange={handleChangeStickyEditor}
                    />
                  }
                  label={t("sentence.stickyEditor")}
                />
              </FormGroup>
            </>
          )}
        </LoginComponent>
      </Container>
    </Layout>
  );
});

export default AccountScreen;
