import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { set } from "../../../app/globalSlice";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  FDocenteteInterface,
  FDocenteteLigneInterface,
} from "../../../interfaces/FDocenteteInterface";
import { useTranslation } from "react-i18next";
import { getLocaleDataGrid } from "../../../helpers/GetLanguage";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { DELETE, GET, PATCH, POST } from "../../../utils/MethodUtils";
import {
  BON_DE_COMMANDE_URL,
  FCOMPTET_URL,
  FDOCENTETES_URL,
} from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { useSearchParams } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ConsoleComptoirFilterComponent from "./filter/ConsoleComptoirFilterComponent";
import {
  CAN_SEARCH_SAGE_DO_TYPE,
  SAGE_DO_TYPE_VENTE_FC,
} from "../../../utils/DoceneteteUtils";
import { PORT_LIVRAISON } from "../../../utils/FDoceneteteUtils";
import {
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  DialogProps,
  Grid,
  Link,
  List,
  ListItem,
  Skeleton,
  TextField,
} from "@mui/material";
import DrawerComponent from "../DrawerComponent";
import DownloadIcon from "@mui/icons-material/Download";
import { LoadingButton } from "@mui/lab";
import getErrorApi from "../../../helpers/GetErrorApi";
import CopyClipboardComponent from "../CopyClipboardComponent";
import PriceComponent from "../PriceComponent";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PdfDisplayComponent from "../PdfDisplayComponent";
import Typography from "@mui/material/Typography";
import { InputInterface } from "../../../interfaces/InputInterface";
import Box from "@mui/material/Box";
import sage69Validator from "../../../helpers/validator/Sage69Validator";
import { priceFormat } from "../../../utils/FormatUtils";
import { UserInterface } from "../../../interfaces/UserInterface";
import DialogActions from "@mui/material/DialogActions";
import FDocenteteConsoleComptoirComponent from "./FDocenteteConsoleComptoirComponent";
import { getMaxWeightFDocentete } from "../../../helpers/ExpeditionHelper";
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import theme from "../../../app/Theme";

export interface CountDoTypeInterface {
  count: string;
  DO_Type: string;
}

export interface State {
  fDocentete: FDocenteteInterface;
}

export interface State2 {
  fDocentete: FDocenteteInterface;
  setFDocentetes: Function;
}

export interface State3 {
  fDocentete: FDocenteteInterface;
}

export interface State4 {
  fDocentete: FDocenteteInterface;
}

interface FDocligneGroupInterface {
  weight: number;
  fDoclignes: FDocenteteLigneInterface[];
}

const CtStatistique02Component: React.FC<State> = React.memo(
  ({ fDocentete }) => {
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [realIsPointage, setRealIsPointage] = React.useState<boolean>(
      !!fDocentete.pointage
    );
    const [loading, setLoading] = React.useState<boolean>(false);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleChange = React.useCallback(async () => {
      setLoading(true);
      const response = await requestApi({
        method: PATCH,
        path: FDOCENTETES_URL + "/" + fDocentete.doPiece + "/chorus",
        allowError: false,
        token: token,
        body: {
          newPointage: !realIsPointage,
        },
      });
      if (response.statusCode === 200) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.chorus_updated")
        );
        setRealIsPointage((x) => !x);
      } else if (response.statusCode === 418) {
        dispatch(set({ showFloComponent: true }));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [dispatch, fDocentete.doPiece, realIsPointage, t, token]);

    return (
      <>
        {fDocentete.doType === SAGE_DO_TYPE_VENTE_FC && (
          <LoadingButton
            variant="text"
            color="inherit"
            sx={{
              borderRadius: "50%",
              minWidth: "auto",
              padding: "12px",
            }}
            loading={loading}
            onClick={handleChange}
          >
            {realIsPointage ? (
              <DoneIcon
                color={
                  !!fDocentete.pointage !== realIsPointage ? "info" : "success"
                }
              />
            ) : (
              <CloseIcon
                color={
                  !!fDocentete.pointage !== realIsPointage ? "info" : "error"
                }
              />
            )}
          </LoadingButton>
        )}
      </>
    );
  }
);

interface FormState {
  doRef: InputInterface;
}

const ValidationComponent: React.FC<State2> = React.memo(
  ({ fDocentete, setFDocentetes }) => {
    const dispatch = useAppDispatch();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("xl");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [realIsValidation, setRealIsValidation] = React.useState<boolean>(
      fDocentete.validation === true
    );
    const [loading, setLoading] = React.useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = React.useState<boolean>(false);
    const [values, setValues] = React.useState<FormState>({
      doRef: { value: fDocentete.doRef ?? "", error: "" },
    });
    const [fComtpet, setFComtpet] = React.useState<UserInterface | undefined>(
      undefined
    );
    const [pdfContent, setPdfContent] = React.useState<string | undefined>(
      undefined
    );
    const [showBdc, setShowBdc] = React.useState<boolean>(false);
    const [dialogDeleteFile, setDialogDeleteFile] =
      React.useState<boolean>(false);
    const { t, i18n } = useTranslation();

    const save = React.useCallback(async () => {
      setLoading(true);
      if (values.doRef.value !== "") {
        const refError = sage69Validator(values.doRef.value);
        if (refError) {
          const newValue: FormState = { ...values };
          if (refError) {
            newValue.doRef.error = refError;
          }
          setValues(newValue);
          setLoading(false);
          return;
        }
      }
      const orderApi = new FormData();
      orderApi.append("doPiece", fDocentete.doPiece);
      orderApi.append("reference", values.doRef.value);
      orderApi.append("Validation", "1");
      const response = await requestApi({
        method: POST,
        path: FDOCENTETES_URL,
        allowError: true,
        token: token,
        body: orderApi,
        formData: true,
        timeout: 30_000,
      });
      if (response.statusCode === 200) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.fdocentete_validated")
        );
        setRealIsValidation(true);
        setFDocentetes((fDocentetes: FDocenteteInterface[]) => {
          const f = fDocentetes.find((f) => f.doPiece === fDocentete.doPiece);
          if (f) {
            f.doRef = values.doRef.value;
          }
          return [...fDocentetes];
        });
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [fDocentete.doPiece, setFDocentetes, t, token, values]);

    const cancel = React.useCallback(async () => {
      setLoading(true);
      const orderApi = new FormData();
      orderApi.append("doPiece", fDocentete.doPiece);
      orderApi.append("Validation", "0");
      const response = await requestApi({
        method: POST,
        path: FDOCENTETES_URL,
        allowError: true,
        token: token,
        body: orderApi,
        formData: true,
        timeout: 30_000,
      });
      if (response.statusCode === 200) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.fdocentete_unvalidated")
        );
        setRealIsValidation(false);
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [fDocentete.doPiece, t, token]);

    const handleDialogDeleteFile = React.useCallback(() => {
      if (loadingDelete) {
        return;
      }
      setDialogDeleteFile((x) => !x);
    }, [loadingDelete]);

    const deleteFile = React.useCallback(async () => {
      setLoadingDelete(true);
      const response = await requestApi({
        method: DELETE,
        path: BON_DE_COMMANDE_URL.replace("{id}", fDocentete.doPiece),
        allowError: true,
        token: token,
      });
      if (response.statusCode === 200) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.configuration_updated")
        );
        setDialogDeleteFile(false);
        dispatch(set({ refreshPage: true }));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoadingDelete(false);
    }, [dispatch, fDocentete.doPiece, t, token]);

    const handleShowBdc = React.useCallback(() => {
      setShowBdc((x) => !x);
    }, []);

    const getPdf = React.useCallback(async () => {
      const response: any = await requestApi({
        method: GET,
        path: BON_DE_COMMANDE_URL.replace("{id}", fDocentete.doPiece),
        allowError: false,
        token: token,
        getRawResponse: true,
      });
      setPdfContent(window.URL.createObjectURL(await response.content.blob()));
    }, [fDocentete.doPiece, token]);

    const updateFComptet = React.useCallback(async () => {
      const response = await requestApi({
        method: GET,
        path: FCOMPTET_URL + "/" + fDocentete?.doTiers?.ctNum,
        allowError: true,
        token: token,
      });
      if (response.statusCode === 200) {
        setFComtpet(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
    }, [fDocentete?.doTiers?.ctNum, t, token]);

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    useEffect(() => {
      if (!showBdc) {
        return;
      }
      getPdf();
    }, [fDocentete.doPiece, showBdc]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (!fDocentete?.doTiers?.ctNum && fComtpet) {
        setFComtpet(undefined);
        return;
      }
      if (showBdc && fDocentete?.doTiers?.ctNum !== fComtpet?.userIdentifier) {
        updateFComptet();
      }
    }, [fDocentete?.doTiers?.ctNum, showBdc]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Dialog
          maxWidth={maxWidth}
          fullScreen={fullScreen}
          onClose={handleDialogDeleteFile}
          open={dialogDeleteFile}
        >
          <DialogContent>{t("word.deleteFileDocumentVente")}</DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Button onClick={handleDialogDeleteFile}>{t("word.cancel")}</Button>
            <LoadingButton
              variant="contained"
              loading={loadingDelete}
              onClick={deleteFile}
              color="error"
            >
              {t("word.delete")}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {fDocentete.isUserBonDeCommande &&
          fDocentete.hasOwnProperty("nbFDocenteteMedias") &&
          // @ts-ignore
          fDocentete.nbFDocenteteMedias > 0 && (
            <>
              <Dialog
                maxWidth={maxWidth}
                fullWidth={true}
                fullScreen={fullScreen}
                onClose={handleShowBdc}
                open={showBdc}
              >
                <DialogContent>
                  {pdfContent && (
                    <Grid container spacing={1} sx={{ padding: 1 }}>
                      <Grid item xs={12} md={6}>
                        <PdfDisplayComponent
                          base64={pdfContent}
                          nbPagePreview={1}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {realIsValidation ? (
                          <Chip
                            label={t("word.verified")}
                            variant="outlined"
                            color="success"
                          />
                        ) : (
                          <Chip
                            label={t("word.notVerified")}
                            variant="outlined"
                            color="error"
                          />
                        )}
                        <Typography>
                          {t("field.fdocentete") + ": "}
                          <CopyClipboardComponent
                            className="RobotoMono"
                            component="span"
                            text={fDocentete.doPiece}
                          />
                        </Typography>
                        <Typography>
                          {t("field.ctIntitule") + ": "}
                          <CopyClipboardComponent
                            className="RobotoMono"
                            component="span"
                            text={fDocentete.doTiers?.ctNum ?? ""}
                          />
                        </Typography>
                        <Typography sx={{ display: "flex" }}>
                          {t("word.modeDeCommande") + ": "}
                          {fComtpet ? (
                            <>{fComtpet.modeDeCommande}</>
                          ) : (
                            <Box sx={{ flex: 1 }}>
                              <Skeleton variant="text" />
                            </Box>
                          )}
                        </Typography>
                        <Typography>
                          {t("word.wTtc") +
                            ": " +
                            priceFormat(
                              fDocentete.doTotalttc * 100,
                              i18n.language,
                              "EUR"
                            )}
                        </Typography>
                        <Typography>
                          {t("word.wHt") +
                            ": " +
                            priceFormat(
                              fDocentete.doTotalhtnet * 100,
                              i18n.language,
                              "EUR"
                            )}
                        </Typography>
                        <TextField
                          fullWidth={true}
                          autoComplete="off"
                          error={!!values.doRef.error}
                          helperText={t(values.doRef.error ?? "")}
                          sx={{ width: "100%", marginTop: 1 }}
                          required
                          type="text"
                          value={values.doRef.value}
                          onChange={handleChange("doRef")}
                          label={t("field.doRef")}
                        />
                        <Typography>
                          {t("sentence.explainValidateBdc")}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <LoadingButton
                            loading={loading}
                            disabled={!realIsValidation}
                            variant="contained"
                            onClick={cancel}
                            sx={{ marginTop: 1 }}
                          >
                            {t("word.cancelVerification")}
                          </LoadingButton>
                          <LoadingButton
                            disabled={!pdfContent}
                            variant="contained"
                            onClick={() => {
                              window.open(pdfContent);
                            }}
                            sx={{ marginTop: 1 }}
                          >
                            {t("word.openNewTab")}
                          </LoadingButton>
                          <LoadingButton
                            loading={loading}
                            disabled={realIsValidation}
                            variant="contained"
                            onClick={save}
                            sx={{ marginTop: 1 }}
                          >
                            {t("word.saveAndVerify")}
                          </LoadingButton>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mt: 1,
                          }}
                        >
                          <LoadingButton
                            variant="contained"
                            onClick={handleDialogDeleteFile}
                            color="error"
                          >
                            {t("word.deleteFile")}
                          </LoadingButton>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </DialogContent>
              </Dialog>
              {realIsValidation ? (
                <DoneIcon
                  color={
                    fDocentete.validation !== realIsValidation
                      ? "info"
                      : "success"
                  }
                />
              ) : (
                <CloseIcon
                  color={
                    fDocentete.validation !== realIsValidation
                      ? "info"
                      : "error"
                  }
                />
              )}
              <IconButton onClick={handleShowBdc}>
                <VisibilityIcon />
              </IconButton>
            </>
          )}
      </>
    );
  }
);

const MapFDocligneLivraisonComponent: React.FC<State4> = React.memo(
  ({ fDocentete }) => {
    const [fullFDocentete, setFullFDocentete] = React.useState<
      FDocenteteInterface | undefined
    >(undefined);
    const [urlDocuments, setUrlsDocuments] = React.useState<string[]>([]);
    const getFDocligneGroups = React.useCallback(() => {
      const r: FDocligneGroupInterface[] = [];
      if (!fullFDocentete?.fDoclignes) {
        return r;
      }
      let currentColumn: FDocligneGroupInterface = {
        fDoclignes: [],
        weight: 0,
      };
      r.push(currentColumn);
      let droppableId = 0;
      const maxWeightFDocentete = getMaxWeightFDocentete(fDocentete);
      for (const fDocligne of fullFDocentete.fDoclignes) {
        for (let i = 0; i < Number(fDocligne.dlQte); i++) {
          if (
            currentColumn.weight + Number(fDocligne.fArticle.arPoidsnet) >
              maxWeightFDocentete &&
            currentColumn.weight > 0
          ) {
            currentColumn = {
              fDoclignes: [],
              weight: 0,
            };
            r.push(currentColumn);
          }
          currentColumn.fDoclignes.push({
            ...fDocligne,
            dlQte: "1",
            droppableId: droppableId,
          });
          droppableId++;
          currentColumn.weight += Number(fDocligne.fArticle.arPoidsnet);
        }
      }
      r.push({
        fDoclignes: [],
        weight: 0,
      });
      return r;
    }, [fDocentete, fullFDocentete?.fDoclignes]);
    const [fDocligneGroups, setFDocligneGroups] = React.useState<
      FDocligneGroupInterface[]
    >(getFDocligneGroups());
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [loading, setLoading] = React.useState<boolean>(false);

    const loadFullFDocentete = React.useCallback(async () => {
      setFullFDocentete(undefined);
      const response = await requestApi({
        method: GET,
        path: FDOCENTETES_URL + "/" + fDocentete.doPiece,
        allowError: true,
        token: token,
      });
      if (response.statusCode === 200) {
        setFullFDocentete(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
    }, [fDocentete.doPiece, t, token]);

    const reorder = (
      list: FDocenteteLigneInterface[],
      startIndex: number,
      endIndex: number
    ): FDocenteteLigneInterface[] => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const move = (
      source: FDocligneGroupInterface,
      destination: FDocligneGroupInterface,
      droppableSource: DraggableLocation,
      droppableDestination: DraggableLocation
    ): any => {
      const sourceClone = Array.from(source.fDoclignes);
      const destClone = Array.from(destination.fDoclignes);
      const [removed] = sourceClone.splice(droppableSource.index, 1);
      destClone.splice(droppableDestination.index, 0, removed);

      const result: any = {};
      result[droppableSource.droppableId] = {
        ...source,
        fDoclignes: sourceClone,
      };
      result[droppableDestination.droppableId] = {
        ...destination,
        fDoclignes: destClone,
      };

      return result;
    };

    const getValue = (): string[][] => {
      let result: string[][] = [];
      for (const fDocligneGroup of fDocligneGroups) {
        result.push(fDocligneGroup.fDoclignes.map((f) => f.cbarRef));
      }
      return result.filter((r) => r.length > 0);
    };

    const generateDocuments = async () => {
      setLoading(true);
      setUrlsDocuments([]);
      const response = await requestApi({
        method: POST,
        path: "/api/f_docentetes/" + fDocentete.doPiece + "/chrono-pdf",
        allowError: true,
        token: token,
        body: getValue(),
      });
      if (response.statusCode === 200) {
        const thisUrlsDocuments = [];
        for (const data of response.content) {
          const filePath = data.filePath;
          const response: any = await requestApi({
            method: GET,
            path:
              BON_DE_COMMANDE_URL.replace("{id}", fDocentete.doPiece) +
              objectToQuery({
                filename:
                  "file://.\\Multimedia\\" +
                  filePath.replace("/srv/app/public/DATA/chrono/", ""),
              }),
            allowError: false,
            token: token,
            getRawResponse: true,
          });
          const url = await response.content.blob();
          thisUrlsDocuments.push(url);
          setTimeout(() => {
            window.open(window.URL.createObjectURL(url), "_blank")?.focus();
          });
        }
        setUrlsDocuments(thisUrlsDocuments);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    };

    const onDragEnd = (result: DropResult) => {
      const { source, destination } = result;

      // dropped outside the list
      if (!destination) {
        return;
      }
      const sInd = +source.droppableId;
      const dInd = +destination.droppableId;

      let newState: FDocligneGroupInterface[];
      if (sInd === dInd) {
        const items: FDocenteteLigneInterface[] = reorder(
          fDocligneGroups[sInd].fDoclignes,
          source.index,
          destination.index
        );
        newState = [...fDocligneGroups];
        newState[sInd].fDoclignes = items;
      } else {
        const result = move(
          fDocligneGroups[sInd],
          fDocligneGroups[dInd],
          source,
          destination
        );
        newState = [...fDocligneGroups];
        newState[sInd] = result[sInd];
        newState[dInd] = result[dInd];
        newState = newState.filter((group) => group.fDoclignes.length);
      }
      if (newState[newState.length - 1].fDoclignes.length > 0) {
        newState.push({
          fDoclignes: [],
          weight: 0,
        });
      }
      for (const column of newState) {
        column.weight = column.fDoclignes
          .map((f) => f.fArticle.arPoidsnet)
          .reduce((partialSum, a) => partialSum + Number(a), 0);
      }
      setFDocligneGroups(newState);
    };

    useEffect(() => {
      loadFullFDocentete();
    }, [fDocentete.doPiece]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      setFDocligneGroups(getFDocligneGroups());
    }, [fullFDocentete]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Typography sx={{ textAlign: "center" }}>
          {t("field.maxWeight") +
            ": " +
            getMaxWeightFDocentete(fDocentete) +
            " g"}
        </Typography>
        {urlDocuments && urlDocuments.length > 0 && (
          <List>
            {urlDocuments.map((urlDocument, indexUrlDocument) => (
              <ListItem disablePadding key={indexUrlDocument}>
                <a target="_blank" href={urlDocument}>
                  {urlDocument}
                </a>
              </ListItem>
            ))}
          </List>
        )}
        {fullFDocentete && (
          <Box sx={{ textAlign: "right" }}>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={generateDocuments}
            >
              {t("word.load")}
            </LoadingButton>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {fullFDocentete === undefined && <CircularProgress />}
          <DragDropContext onDragEnd={onDragEnd}>
            {fDocligneGroups.map((el, ind) => (
              <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <Box
                    ref={provided.innerRef}
                    sx={{
                      marginX: 1,
                      width: 250,
                      display: "flex",
                      flexDirection: "column",
                    }}
                    {...provided.droppableProps}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      {t("word.colis") +
                        ": " +
                        (ind + 1).toString() +
                        " [" +
                        Number(el.weight).toLocaleString() +
                        " g]"}
                    </Typography>
                    <Box
                      sx={{
                        background: snapshot.isDraggingOver
                          ? theme.palette.primary.main
                          : theme.palette.secondary.main,
                        padding: 2,
                        display: "flex",
                        flexFlow: "column",
                        height: "100%",
                      }}
                    >
                      {el.fDoclignes.map((item, index) => (
                        <Draggable
                          key={item.droppableId}
                          draggableId={item.droppableId?.toString() ?? ""}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Card
                              variant="outlined"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={provided.draggableProps.style}
                            >
                              <CardContent>{item.dlDesign}</CardContent>
                            </Card>
                          )}
                        </Draggable>
                      ))}
                    </Box>
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            ))}
          </DragDropContext>
        </Box>
        {fullFDocentete && (
          <Box sx={{ textAlign: "right" }}>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={generateDocuments}
            >
              {t("word.load")}
            </LoadingButton>
          </Box>
        )}
      </>
    );
  }
);

const DownloadLivraisonComponent: React.FC<State3> = React.memo(
  ({ fDocentete }) => {
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const handleClose = React.useCallback(() => {
      setOpenDialog(false);
    }, []);
    const handleOpen = React.useCallback(() => {
      setOpenDialog(true);
    }, []);

    return (
      <>
        <Dialog
          maxWidth={maxWidth}
          fullWidth={true}
          fullScreen={fullScreen}
          onClose={handleClose}
          open={openDialog}
        >
          <DialogContent>
            <MapFDocligneLivraisonComponent fDocentete={fDocentete} />
          </DialogContent>
        </Dialog>
        {fDocentete.arRefLivraison === "LIVRCHR" ? (
          <LoadingButton
            variant="text"
            color="inherit"
            sx={{
              borderRadius: "50%",
              minWidth: "auto",
              padding: "12px",
            }}
            onClick={() => {
              handleOpen();
            }}
          >
            <DownloadIcon color="success" />
          </LoadingButton>
        ) : (
          <DoneIcon color="success" />
        )}
      </>
    );
  }
);

const ConsoleComptoirComponent: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [defaultItemsPerPage] = React.useState(50);
  const [countDoType, setCountDoType] = React.useState<CountDoTypeInterface[]>(
    []
  );
  const [fDocentetes, setFDocentetes] = React.useState<
    FDocenteteInterface[] | undefined
  >(undefined);
  const [openDrawer, setOpenDrawer] = React.useState("");
  const [totalItems, setTotalItems] = React.useState(0);
  const { t, i18n } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);

  const handleClose = React.useCallback(() => {
    setOpenDrawer("");
  }, []);

  const handleOpen = React.useCallback((doPiece: string) => {
    setOpenDrawer(doPiece);
  }, []);

  const getColumns = React.useCallback((): GridColDef[] => {
    return [
      {
        field: "doPiece",
        headerName: t("column.doPiece"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <Link
            onClick={() => handleOpen(params.row.doPiece)}
            sx={{ cursor: "pointer" }}
          >
            {params.row.doPiece}
          </Link>
        ),
      },
      {
        field: "doType",
        headerName: t("column.doType"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>{t("word.fdocentete.doType." + params.row.doType)}</>
        ),
      },
      {
        field: "doDate",
        headerName: t("column.doDate"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {new Date(params.row.doDate).toLocaleString(i18n.language, {
              dateStyle: "long",
              timeZone: "Etc/UTC",
            })}
          </>
        ),
      },
      {
        field: "doTotalttc",
        headerName: t("column.doTotalttc"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <PriceComponent price={parseFloat(params.row.doTotalttc) * 100} />
        ),
      },
      {
        field: "doTiers",
        headerName: t("column.doTiers"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CopyClipboardComponent
            className="RobotoMono"
            component="span"
            text={params.row.doTiers?.ctNum ?? ""}
          />
        ),
      },
      {
        field: "doRef",
        headerName: t("column.doRef"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
      },
      {
        field: "paid",
        headerName: t("column.paid"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.paid ? (
            <DoneIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          );
        },
      },
      {
        field: "doExpedit",
        headerName: t("column.doExpedit"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.doExpedit === PORT_LIVRAISON ? (
            <DownloadLivraisonComponent fDocentete={params.row} />
          ) : (
            <CloseIcon color="error" />
          );
        },
      },
      {
        field: "ctStatistique02",
        headerName: t("column.chorus"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.row.doTiers?.ctStatistique02 === "oui" ? (
            <DoneIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          ),
      },
      {
        field: "pointage",
        headerName: t("column.pointage"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return <CtStatistique02Component fDocentete={params.row} />;
        },
      },
      {
        field: "validation",
        headerName: t("column.bdcValidated"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <ValidationComponent
              fDocentete={params.row}
              setFDocentetes={setFDocentetes}
            />
          );
        },
      },
    ];
  }, [handleOpen, i18n.language, t]);
  const [columns] = React.useState<GridColDef[]>(getColumns());

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (fDocentetes !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (!searchParamsObject.hasOwnProperty("page")) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (!force) {
        let hasOrder = false;
        for (const [key] of Object.entries(searchParamsObject)) {
          if (key.startsWith("order")) {
            hasOrder = true;
            break;
          }
        }
        if (!hasOrder) {
          searchParamsObject["order[doDate]"] = "desc";
          hasChanged = true;
        }
      }
      if (hasChanged) {
        dispatch(set({ refreshPage: false }));
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        return;
      }
      const oldParams = window.location.search;
      const response = await requestApi({
        method: GET,
        path:
          FDOCENTETES_URL +
          objectToQuery({
            ...searchParamsObject,
            fDoclignesPaginate: false,
            arLivraison: true,
            pointage: true,
            "totalDoType[]": CAN_SEARCH_SAGE_DO_TYPE,
            elasticSearch: 1,
          }),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        if (window.location.search !== oldParams) {
          return;
        }
        let maxId = 0;
        const newFDocentetes = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          ).map(() => {
            maxId++;
            return {
              doPiece: maxId.toString(),
            };
          }),
          ...response.content.fDocentetes,
        ];
        setFDocentetes(newFDocentetes);
        setTotalItems(response.content.totalItems);
        setCountDoType(response.content.total);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [
      defaultItemsPerPage,
      dispatch,
      fDocentetes,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
    ]
  );

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setFDocentetes(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setFDocentetes(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onSortModelChange = React.useCallback(
    (model: GridSortModel) => {
      if (!init) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          delete searchParamsObject[key];
        }
      }
      for (const sort of model) {
        // @ts-ignore
        searchParamsObject["order[" + sort.field + "]"] = sort.sort;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const getSortModel = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    const result: any[] = [];
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (key.startsWith("order")) {
        let field: string[] | string = key.split("[");
        field = field[1].replace("]", "");
        result.push({
          field: field,
          sort: value,
        });
      }
    }
    return result;
  }, [searchParams]);

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage, searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!user) {
      setSearchParams(
        {},
        {
          replace: true,
        }
      );
    }
    if (init && !refreshPage) {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams, user?.userIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      <DrawerComponent
        open={openDrawer !== ""}
        onClose={handleClose}
        handleDrawerClose={handleClose}
        drawerwidth={window.innerWidth}
        content={
          <FDocenteteConsoleComptoirComponent
            doPiece={openDrawer}
            handleClose={handleClose}
          />
        }
      />
      <ConsoleComptoirFilterComponent countDoType={countDoType} />
      {searchParamsObject.itemsPerPage && searchParamsObject.page && (
        // https://mui.com/x/react-data-grid/components/#pagination
        <DataGrid
          initialState={{
            sorting: {
              sortModel: getSortModel(),
            },
          }}
          getRowId={(row) => row.doPiece}
          loading={refreshPage}
          rows={refreshPage ? [] : fDocentetes ?? []}
          onSortModelChange={onSortModelChange}
          sortingMode="server"
          page={Number(searchParamsObject.page) - 1}
          rowsPerPageOptions={[10, 25, 50]}
          pageSize={Number(searchParamsObject.itemsPerPage)}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          rowCount={totalItems}
          columns={columns}
          autoHeight={true}
          disableExtendRowFullWidth={true}
          localeText={getLocaleDataGrid(i18n.language)}
        />
      )}
    </>
  );
});

export default ConsoleComptoirComponent;
