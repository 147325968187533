import React, { useEffect } from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ABOUT_PAGE,
  ACCOUNT_PAGE,
  ACCOUNTING_SITUATION_PAGE,
  ADMIN_BRAND_PAGE,
  ADMIN_CATEGORY_PAGE,
  ADMIN_CONSOLE_PAGE,
  ADMIN_CRON_PAGE,
  ADMIN_FEEDBACK_PAGE,
  ADMIN_MAIL_PAGE,
  ADMIN_PAYMENT_PAGE,
  ADMIN_SAV_PAGE,
  ADMIN_USER_PAGE,
  ARRIVAGE_PAGE,
  CATEGORY_PAGE,
  COMPARE_PAGE,
  CONFIGURATION_PAGE,
  EXPEDITION_PAGE,
  FAVORIS_PAGE,
  FEEDBACK_PAGE,
  FILTER_PAGE,
  FISCAL_CODE_PAGE,
  HOME_PAGE,
  LOGIN_PAGE,
  NEWS_PAGE,
  ORDERS_PAGE,
  PDF_PAGE,
  PRODUCTS_PAGE,
  REGISTER_PAGE,
  SAV_PAGE,
  STATS_PAGE,
  TAG_PAGE,
} from "../../utils/RouteUtils";
import LogoutIcon from "@mui/icons-material/Logout";
import { resetApp, set } from "../../app/globalSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import LoginIcon from "@mui/icons-material/Login";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PersonIcon from "@mui/icons-material/Person";
import InventoryIcon from "@mui/icons-material/Inventory";
import HomeIcon from "@mui/icons-material/Home";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Typography from "@mui/material/Typography";
import BuildIcon from "@mui/icons-material/Build";
import BarChartIcon from "@mui/icons-material/BarChart";
import InfoIcon from "@mui/icons-material/Info";
import { WAITING_SAV } from "../../utils/SavUtils";
import { priceFormat } from "../../utils/FormatUtils";
import { useTheme } from "@mui/material/styles";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CategoryIcon from "@mui/icons-material/Category";
import {
  Autocomplete,
  Chip,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import FactoryIcon from "@mui/icons-material/Factory";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { initAppHelper } from "../../helpers/InitAppHelper";
import { LoadingButton } from "@mui/lab";
import SettingsIcon from "@mui/icons-material/Settings";
import FeedbackIcon from "@mui/icons-material/Feedback";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import { STORAGE_STATE } from "../../utils/StorageUtils";
import LabelIcon from "@mui/icons-material/Label";
import { objectToQuery } from "../../helpers/RequestApi";
import MailIcon from "@mui/icons-material/Mail";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { STATUS_FAILED } from "../../utils/PaymentHistoryUtils";
import { STATUS_OPEN } from "../../utils/ContactUtils";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FavoriteIcon from "@mui/icons-material/Favorite";
// @ts-ignore
import Bounce from "react-reveal/Bounce";
import CompareIcon from "@mui/icons-material/Compare";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

interface State {
  collapsed: boolean;
  toggled: boolean;
  handleToggleSidebar: Function;
}

const Aside: React.FC<State> = React.memo(
  ({ collapsed, toggled, handleToggleSidebar }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const accountingSituation = useAppSelector(
      (state: RootState) => state.globalState.accountingSituation
    );
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const fArticlesToCompare = useAppSelector(
      (state: RootState) => state.globalState.fArticlesToCompare
    );
    const userProp = useAppSelector(
      (state: RootState) => state.globalState.userProp
    );
    const news = useAppSelector((state: RootState) => state.globalState.news);
    const nbOrderNeedPayAcompte = useAppSelector(
      (state: RootState) => state.globalState.nbOrderNeedPayAcompte
    );
    const nbAdministrationNotYetTransform = useAppSelector(
      (state: RootState) => state.globalState.nbAdministrationNotYetTransform
    );
    const impersonate = useAppSelector(
      (state: RootState) => state.globalState.impersonate
    );
    const nbFilterArticle = useAppSelector(
      (state: RootState) => state.globalState.nbFilterArticle
    );
    const nbFilterArticle2 = useAppSelector(
      (state: RootState) => state.globalState.nbFilterArticle2
    );
    const newMessageAdmin = useAppSelector(
      (state: RootState) => state.globalState.newMessageAdmin
    );
    const drivers = useAppSelector(
      (state: RootState) => state.globalState.drivers
    );
    const newMessageUser = useAppSelector(
      (state: RootState) => state.globalState.newMessageUser
    );
    const getHasNews = React.useCallback(() => {
      return !(
        !userProp ||
        !news ||
        news.length === 0 ||
        userProp.lastNewsDate === news[0].date
      );
    }, [news, userProp]);
    const [hasNews, setHasNews] = React.useState(getHasNews());
    const [loadingLeaveImpersonate, setLoadingLeaveImpersonate] =
      React.useState(false);
    const getNbBrandWithoutImage = React.useCallback(() => {
      return drivers?.filter((x) => x.nbFArticle > 0 && !x.images).length ?? 0;
    }, [drivers]);
    const [nbBrandWithoutImage, setNbBrandWithoutImage] = React.useState(
      getNbBrandWithoutImage()
    );
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const nbPl = useAppSelector((state: RootState) => state.globalState.nbPl);
    const nbWaitingSav = useAppSelector(
      (state: RootState) => state.globalState.nbWaitingSav
    );
    const nbSavServiceTodo = useAppSelector(
      (state: RootState) => state.globalState.nbSavServiceTodo
    );
    const addedFavoris = useAppSelector(
      (state: RootState) => state.globalState.addedFavoris
    );
    const addedCompare = useAppSelector(
      (state: RootState) => state.globalState.addedCompare
    );
    const nbWaitingSavUser = useAppSelector(
      (state: RootState) => state.globalState.nbWaitingSavUser
    );
    const nbErrorPayments = useAppSelector(
      (state: RootState) => state.globalState.nbErrorPayments
    );
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const getBrands = React.useCallback(() => {
      return drivers?.filter((x) => x.nbFArticle > 0);
    }, [drivers]);
    const [brands, setBrands] = React.useState(getBrands());

    const leaveImpersonate = React.useCallback(() => {
      setLoadingLeaveImpersonate(true);
      initAppHelper(token, undefined, false, true, null).finally(() => {
        dispatch(set({ impersonate: undefined }));
        setLoadingLeaveImpersonate(false);
      });
    }, [dispatch, token]);

    const logout = React.useCallback(() => {
      if (!!impersonate) {
        if (loadingLeaveImpersonate) {
          return;
        }
        leaveImpersonate();
        return;
      }
      if (location.pathname !== LOGIN_PAGE) {
        navigate(LOGIN_PAGE);
      }
      localStorage.removeItem(STORAGE_STATE);
      dispatch(resetApp());
    }, [
      dispatch,
      impersonate,
      leaveImpersonate,
      loadingLeaveImpersonate,
      location.pathname,
      navigate,
    ]);

    const paramFilter =
      (nbFilterArticle ?? 0) > 0
        ? "?tab=2"
        : "?tab=0&itemsPerPage=50&page=1&order%5Bid%5D=desc";
    const paramPayments =
      (nbErrorPayments ?? 0) > 0 ? "?status[]=" + STATUS_FAILED : "";

    useEffect(() => {
      setNbBrandWithoutImage(getNbBrandWithoutImage());
      setBrands(getBrands());
    }, [drivers]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      setHasNews(getHasNews());
    }, [news, userProp]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        if (addedFavoris) {
          dispatch(set({ addedFavoris: false }));
        }
      }, 3000);
      return () => clearTimeout(timeoutTyping);
    }, [addedFavoris]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      const timeoutTyping = setTimeout(() => {
        if (addedCompare) {
          dispatch(set({ addedCompare: false }));
        }
      }, 3000);
      return () => clearTimeout(timeoutTyping);
    }, [addedCompare]); // eslint-disable-line react-hooks/exhaustive-deps

    // @ts-ignore
    return (
      <ProSidebar
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={() => handleToggleSidebar()}
      >
        <SidebarContent
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Menu
            iconShape="round"
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
          >
            {user !== undefined ? (
              <>
                {!isAdmin && (
                  <>
                    <MenuItem>{user.ctIntitule}</MenuItem>
                    <MenuItem>
                      {t("field.ctIntitule") + ": " + user.userIdentifier}
                    </MenuItem>
                  </>
                )}
                <MenuItem
                  className="color-orange"
                  icon={
                    <LoadingButton
                      variant="text"
                      className="hover-transparent"
                      sx={{
                        borderRadius: "50%",
                        minWidth: "auto",
                        padding: 0,
                      }}
                      loading={loadingLeaveImpersonate}
                    >
                      {!loadingLeaveImpersonate && <LogoutIcon />}
                    </LoadingButton>
                  }
                  onClick={logout}
                >
                  {t(impersonate ? "word.leaveImpersonate" : "word.logout")}
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem
                  className={
                    "color-orange " +
                    (location.pathname === LOGIN_PAGE ? "active" : "")
                  }
                  icon={<LoginIcon />}
                >
                  {t("word.login")}
                  <Link
                    to={LOGIN_PAGE}
                    state={{ prevPath: location.pathname }}
                  />
                </MenuItem>
                <MenuItem
                  className={
                    "color-orange " +
                    (location.pathname === REGISTER_PAGE ? "active" : "")
                  }
                  icon={<GroupAddIcon />}
                >
                  {t("word.register")}
                  <Link to={REGISTER_PAGE} />
                </MenuItem>
              </>
            )}
          </Menu>
          <Menu iconShape="round">
            <MenuItem
              className={location.pathname === HOME_PAGE ? "active" : ""}
              icon={<HomeIcon />}
            >
              {t("word.home")}
              <Link to={HOME_PAGE} />
            </MenuItem>
            <MenuItem
              className={
                location.pathname === ACCOUNTING_SITUATION_PAGE ? "active" : ""
              }
              icon={
                <Badge
                  invisible={
                    !(
                      isSmall &&
                      accountingSituation &&
                      accountingSituation.sumCreditFdocentetes < 0
                    )
                  }
                  variant="dot"
                  color="error"
                >
                  <BarChartIcon />
                </Badge>
              }
            >
              <Typography>{t("word.accountingSituation.label")}</Typography>
              {accountingSituation &&
                accountingSituation.sumCreditFdocentetes !== 0 && (
                  <Box sx={{ textAlign: "right" }}>
                    {accountingSituation.sumCreditFdocentetes > 0 ? (
                      <Typography
                        component="span"
                        variant="caption"
                        sx={{
                          color: "white",
                          padding: 0.5,
                          borderRadius: "5px",
                          backgroundColor: theme.palette.success.main,
                        }}
                      >
                        {priceFormat(
                          accountingSituation.sumCreditFdocentetes,
                          i18n.language,
                          "EUR"
                        )}
                      </Typography>
                    ) : (
                      <>
                        {accountingSituation.ecSensDebitEchuWithCredit > 0 && (
                          <Typography
                            component="span"
                            variant="caption"
                            sx={{
                              color: "white",
                              padding: 0.5,
                              borderRadius: "5px",
                              backgroundColor: theme.palette.error.main,
                            }}
                          >
                            {priceFormat(
                              accountingSituation.ecSensDebitEchuWithCredit,
                              i18n.language,
                              "EUR"
                            )}
                          </Typography>
                        )}
                        {accountingSituation.ecSensDebitNonEchuWithCredit >
                          0 && (
                          <Tooltip title="Delete">
                            <Typography
                              component="span"
                              variant="caption"
                              sx={{
                                color: "white",
                                padding: 0.5,
                                marginLeft: 0.5,
                                borderRadius: "5px",
                                backgroundColor: theme.palette.warning.main,
                              }}
                            >
                              {priceFormat(
                                accountingSituation.ecSensDebitNonEchuWithCredit,
                                i18n.language,
                                "EUR"
                              )}
                            </Typography>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </Box>
                )}
              <Link to={ACCOUNTING_SITUATION_PAGE} />
            </MenuItem>
            <MenuItem
              className={location.pathname === CATEGORY_PAGE ? "active" : ""}
              icon={<MenuBookIcon />}
            >
              {t("word.catalog")}
              <Link to={CATEGORY_PAGE} />
            </MenuItem>
            <Autocomplete
              fullWidth
              value={null}
              onChange={(event: any, newValue: string | null) => {
                navigate(
                  PRODUCTS_PAGE + objectToQuery({ "brands[]": newValue })
                );
              }}
              options={brands?.map((driver) => driver.name) ?? []}
              renderInput={(params) => (
                <TextField label={t("word.constructor")} {...params} />
              )}
            />
            <MenuItem
              className={
                "favoris " +
                (location.pathname === FAVORIS_PAGE ? "active" : "")
              }
              icon={<FavoriteIcon />}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("word.favoris")}
                {addedFavoris && (
                  <Bounce top>
                    <Typography
                      component="span"
                      variant="caption"
                      sx={{
                        color: "white",
                        padding: 0.5,
                        marginLeft: 1,
                        fontWeight: "bold",
                        borderRadius: "5px",
                        // @ts-ignore
                        backgroundColor: theme.palette.orange.main,
                      }}
                    >
                      +1
                    </Typography>
                  </Bounce>
                )}
              </Box>
              <Link to={FAVORIS_PAGE} />
            </MenuItem>
            <MenuItem
              className={location.pathname === ORDERS_PAGE ? "active" : ""}
              icon={<InventoryIcon />}
            >
              <Typography>{t("word.orders")}</Typography>
              {nbOrderNeedPayAcompte !== undefined &&
                nbOrderNeedPayAcompte > 0 && (
                  <Typography component="p" variant="caption">
                    {nbOrderNeedPayAcompte +
                      " " +
                      t(
                        "sentence.needAcompte" +
                          (nbOrderNeedPayAcompte > 1 ? "s" : "")
                      )}
                  </Typography>
                )}
              {nbPl !== undefined && nbPl > 0 && (
                <Typography component="p" variant="caption">
                  {nbPl + " " + t("sentence.needPickup")}
                </Typography>
              )}
              <Link to={ORDERS_PAGE} />
            </MenuItem>
            <MenuItem
              className={location.pathname === ACCOUNT_PAGE ? "active" : ""}
              icon={<PersonIcon />}
            >
              {t("word.account")}
              <Link to={ACCOUNT_PAGE} />
            </MenuItem>
            <MenuItem
              className={location.pathname === SAV_PAGE ? "active" : ""}
              icon={<BuildIcon />}
            >
              {t("word.sav.label")}
              {nbWaitingSavUser !== undefined && nbWaitingSavUser > 0 && (
                <Chip
                  label={nbWaitingSavUser ?? 0}
                  variant="outlined"
                  color="primary"
                  sx={{ marginLeft: 1 }}
                />
              )}
              <Link to={SAV_PAGE} />
            </MenuItem>
            <MenuItem
              className={location.pathname === NEWS_PAGE ? "active" : ""}
              icon={<NotificationsIcon />}
            >
              {t("word.news")}
              {hasNews && (
                <Chip
                  label="NEW"
                  variant="outlined"
                  size="small"
                  color="primary"
                  sx={{ marginLeft: 1 }}
                />
              )}
              <Link to={NEWS_PAGE} />
            </MenuItem>
            <MenuItem
              className={location.pathname === ABOUT_PAGE ? "active" : ""}
              icon={<InfoIcon />}
            >
              {t("word.informations")}
              <Link to={ABOUT_PAGE} />
            </MenuItem>
            <MenuItem
              className={location.pathname === FEEDBACK_PAGE ? "active" : ""}
              icon={<FeedbackIcon />}
            >
              {t("word.contact.label")}
              {newMessageUser !== undefined && newMessageUser > 0 && (
                <Chip
                  label={newMessageUser ?? 0}
                  variant="outlined"
                  color="primary"
                  sx={{ marginLeft: 1 }}
                />
              )}
              <Link to={FEEDBACK_PAGE} />
            </MenuItem>
            <MenuItem
              className={location.pathname === COMPARE_PAGE ? "active" : ""}
              icon={<CompareIcon />}
            >
              {t("word.compare")}
              {fArticlesToCompare !== undefined &&
                fArticlesToCompare.length > 0 && (
                  <Chip
                    label={fArticlesToCompare.length ?? 0}
                    variant="outlined"
                    color="primary"
                    sx={{ marginLeft: 1 }}
                  />
                )}
              {addedCompare && (
                <Bounce top>
                  <Typography
                    component="span"
                    variant="caption"
                    sx={{
                      color: "white",
                      padding: 0.5,
                      marginLeft: 1,
                      fontWeight: "bold",
                      borderRadius: "5px",
                      // @ts-ignore
                      backgroundColor: theme.palette.orange.main,
                    }}
                  >
                    +1
                  </Typography>
                </Bounce>
              )}
              <Link
                to={{
                  pathname: COMPARE_PAGE,
                  search: objectToQuery({
                    ...(fArticlesToCompare && {
                      "arRefs[]": fArticlesToCompare.map((f) => f.arRef),
                    }),
                  }),
                }}
              />
            </MenuItem>
          </Menu>
        </SidebarContent>

        {isAdmin && (
          <SidebarFooter>
            <Menu
              iconShape="round"
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.2)" }}
            >
              <MenuItem
                className={location.pathname === FILTER_PAGE ? "active" : ""}
                icon={<FilterAltIcon />}
              >
                {t("word.filters")}
                {nbFilterArticle !== undefined && nbFilterArticle > 0 && (
                  <Chip
                    label={nbFilterArticle ?? 0}
                    variant="outlined"
                    color="primary"
                    sx={{ marginLeft: 1 }}
                  />
                )}
                {nbFilterArticle2 !== undefined && nbFilterArticle2 > 0 && (
                  <Chip
                    label={nbFilterArticle2 ?? 0}
                    variant="outlined"
                    color="info"
                    sx={{ marginLeft: 1 }}
                  />
                )}
                <Link to={FILTER_PAGE + paramFilter} />
              </MenuItem>
              <MenuItem
                className={
                  location.pathname === ADMIN_PAYMENT_PAGE ? "active" : ""
                }
                icon={<CreditCardIcon />}
              >
                {t("word.payments")}
                {nbErrorPayments !== undefined && nbErrorPayments > 0 && (
                  <Chip
                    label={nbErrorPayments ?? 0}
                    variant="outlined"
                    color="primary"
                    sx={{ marginLeft: 1 }}
                  />
                )}
                <Link to={ADMIN_PAYMENT_PAGE + paramPayments} />
              </MenuItem>
              <MenuItem
                className={
                  location.pathname === ADMIN_FEEDBACK_PAGE ? "active" : ""
                }
                icon={<FeedbackIcon />}
              >
                {t("word.contact.label")}
                {newMessageAdmin !== undefined && newMessageAdmin > 0 && (
                  <Chip
                    label={newMessageAdmin ?? 0}
                    variant="outlined"
                    color="primary"
                    sx={{ marginLeft: 1 }}
                  />
                )}
                <Link
                  to={{
                    pathname: ADMIN_FEEDBACK_PAGE,
                    search: objectToQuery({
                      "status[]": [STATUS_OPEN],
                    }),
                  }}
                />
              </MenuItem>
              <MenuItem
                className={
                  location.pathname === ADMIN_CONSOLE_PAGE ? "active" : ""
                }
                icon={<FeaturedPlayListIcon />}
              >
                {t("word.console")}
                <Link to={ADMIN_CONSOLE_PAGE} />
              </MenuItem>
              <MenuItem
                className={
                  location.pathname === CONFIGURATION_PAGE ? "active" : ""
                }
                icon={<SettingsIcon />}
              >
                {t("word.configurations")}
                <Link to={CONFIGURATION_PAGE + "?used=used"} />
              </MenuItem>
              <MenuItem
                className={
                  location.pathname === ADMIN_USER_PAGE ? "active" : ""
                }
                icon={<PersonIcon />}
              >
                {t("word.customers")}
                {nbAdministrationNotYetTransform !== undefined &&
                  nbAdministrationNotYetTransform > 0 && (
                    <Chip
                      label={nbAdministrationNotYetTransform ?? 0}
                      variant="outlined"
                      color="primary"
                      sx={{ marginLeft: 1 }}
                    />
                  )}
                <Link to={ADMIN_USER_PAGE} />
              </MenuItem>
              <MenuItem
                className={
                  location.pathname === ADMIN_CATEGORY_PAGE ? "active" : ""
                }
                icon={<CategoryIcon />}
              >
                {t("word.categories")}
                <Link to={ADMIN_CATEGORY_PAGE} />
              </MenuItem>
              <MenuItem
                className={location.pathname === PRODUCTS_PAGE ? "active" : ""}
                icon={<MenuBookIcon />}
              >
                {t("word.products")}
                <Link to={PRODUCTS_PAGE} />
              </MenuItem>
              <MenuItem
                className={
                  location.pathname === EXPEDITION_PAGE ? "active" : ""
                }
                icon={<LocalShippingIcon />}
              >
                {t("word.expeditions")}
                <Link to={EXPEDITION_PAGE} />
              </MenuItem>
              <MenuItem
                className={
                  location.pathname === FISCAL_CODE_PAGE ? "active" : ""
                }
                icon={<LocalPoliceIcon />}
              >
                {t("title.fiscalCode")}
                <Link to={FISCAL_CODE_PAGE} />
              </MenuItem>
              <MenuItem
                className={
                  location.pathname === ADMIN_BRAND_PAGE ? "active" : ""
                }
                icon={<FactoryIcon />}
              >
                {t("word.brands")}
                {nbBrandWithoutImage > 0 && (
                  <Chip
                    label={nbBrandWithoutImage}
                    variant="outlined"
                    color="primary"
                    sx={{ marginLeft: 1 }}
                  />
                )}
                <Link to={ADMIN_BRAND_PAGE} />
              </MenuItem>
              <MenuItem
                className={
                  location.pathname === ADMIN_CRON_PAGE ? "active" : ""
                }
                icon={<AssignmentIcon />}
              >
                {t("word.crons")}
                <Link to={ADMIN_CRON_PAGE} />
              </MenuItem>
              <MenuItem
                className={
                  location.pathname === ADMIN_MAIL_PAGE ? "active" : ""
                }
                icon={<MailIcon />}
              >
                {t("word.mail")}
                <Link to={ADMIN_MAIL_PAGE} />
              </MenuItem>
              <MenuItem
                className={location.pathname === ADMIN_SAV_PAGE ? "active" : ""}
                icon={<BuildIcon />}
              >
                {t("word.sav.label")}
                {nbWaitingSav !== undefined && nbWaitingSav > 0 && (
                  <Chip
                    label={nbWaitingSav ?? 0}
                    variant="outlined"
                    color="primary"
                    sx={{ marginLeft: 1 }}
                  />
                )}
                {nbSavServiceTodo !== undefined && nbSavServiceTodo > 0 && (
                  <Chip
                    label={nbSavServiceTodo ?? 0}
                    variant="outlined"
                    color="info"
                    sx={{ marginLeft: 1 }}
                  />
                )}
                <Link
                  to={{
                    pathname: ADMIN_SAV_PAGE,
                    ...(nbWaitingSav &&
                      nbWaitingSav > 0 && {
                        search: objectToQuery({
                          "state[]": WAITING_SAV,
                        }),
                      }),
                  }}
                />
              </MenuItem>
              <MenuItem
                className={location.pathname === TAG_PAGE ? "active" : ""}
                icon={<LabelIcon />}
              >
                {t("word.tag.label")}
                <Link
                  to={{
                    pathname: TAG_PAGE,
                    search: "?page=1&itemsPerPage=50",
                  }}
                />
              </MenuItem>
              <MenuItem
                className={location.pathname === PDF_PAGE ? "active" : ""}
                icon={<PictureAsPdfIcon />}
              >
                {t("word.pdf")}
                <Link to={PDF_PAGE} />
              </MenuItem>
              <MenuItem
                className={location.pathname === ARRIVAGE_PAGE ? "active" : ""}
                icon={<InventoryIcon />}
              >
                {t("word.entreeStock")}
                <Link to={ARRIVAGE_PAGE} />
              </MenuItem>
              <MenuItem
                className={location.pathname === STATS_PAGE ? "active" : ""}
                icon={<QueryStatsIcon />}
              >
                {t("word.stats")}
                <Link to={STATS_PAGE} />
              </MenuItem>
            </Menu>
          </SidebarFooter>
        )}
      </ProSidebar>
    );
  }
);

export default Aside;
