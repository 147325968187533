import * as React from "react";
import { useEffect } from "react";
import { FDocenteteInterface } from "../../../interfaces/FDocenteteInterface";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { isUserBonDeCommande } from "../../../helpers/UserHelper";
import {
  bonDeCommandeIsValidated,
  displayTransform,
  getAvoirFdocentete,
  getUrlPdfDoPiece,
  paiementDone,
} from "../../../helpers/FDocenteteHelper";
import FDocentetePayComponent from "./FDocentetePayComponent";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import FDocenteteTransformComponent from "./FDocenteteTransformComponent";
import { RAW_HTML_PAGE } from "../../../utils/RouteUtils";
import { Link } from "react-router-dom";
import { objectToQuery } from "../../../helpers/RequestApi";
import Box from "@mui/material/Box";
import DeleteFDocenteteComponent from "./DeleteFDocenteteComponent";
import { differenceInSeconds } from "date-fns";
import {
  SAGE_DO_TYPE_VENTE_DE,
  SAGE_DO_TYPE_VENTE_FA,
} from "../../../utils/DoceneteteUtils";
import Typography from "@mui/material/Typography";
import { priceFormat } from "../../../utils/FormatUtils";
import FDocenteteOrderAgainComponent from "./FDocenteteOrderAgainComponent";

interface State {
  fDocentete: FDocenteteInterface;
}

const FDocenteteActionComponent: React.FC<State> = React.memo(
  ({ fDocentete }) => {
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const { t, i18n } = useTranslation();
    const accountingSituation = useAppSelector(
      (state: RootState) => state.globalState.accountingSituation
    );
    const [avoir, setAvoir] = React.useState<number>(
      getAvoirFdocentete(fDocentete)
    );

    useEffect(() => {
      setAvoir(getAvoirFdocentete(fDocentete));
    }, [accountingSituation?.creditFdocentetes]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Box>
          {fDocentete.nDeTransport && (
            <Box sx={{ marginBottom: 1 }}>
              <Typography>
                {t("word.nDeTransport")}: {fDocentete.nDeTransport}
              </Typography>
              <a
                href={
                  "https://parcelsapp.com/fr/tracking/" +
                  fDocentete.nDeTransport
                }
                target="_blank"
                rel="noreferrer"
              >
                <LoadingButton variant="contained">
                  {t("word.trackOrder")}
                </LoadingButton>
              </a>
            </Box>
          )}
          <FDocentetePayComponent fDocentete={fDocentete} />
          {isUserBonDeCommande(user) ? (
            <FDocenteteTransformComponent
              fDocentete={fDocentete}
              withBonCommande={true}
            />
          ) : (
            <>
              {displayTransform(fDocentete, user) && (
                <FDocenteteTransformComponent
                  fDocentete={fDocentete}
                  withBonCommande={false}
                />
              )}
            </>
          )}
          <Box>
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={
                RAW_HTML_PAGE +
                objectToQuery({
                  url: getUrlPdfDoPiece(fDocentete.doPiece),
                })
              }
              target="_blank"
            >
              <LoadingButton variant="contained" sx={{ mt: 1 }}>
                {t(
                  fDocentete.doType === SAGE_DO_TYPE_VENTE_DE
                    ? "word.seeDevis"
                    : fDocentete.doType >= SAGE_DO_TYPE_VENTE_FA
                    ? "word.seePdf"
                    : "word.seeOrder"
                )}
              </LoadingButton>
            </Link>
            {avoir > 0 && (
              <Box>
                <Typography variant="caption">
                  {t("sentence.usedAvoir").replace(
                    "%amount%",
                    priceFormat(avoir, i18n.language, "EUR")
                  )}
                </Typography>
              </Box>
            )}
          </Box>
          {fDocentete.doType >= SAGE_DO_TYPE_VENTE_FA && (
            <FDocenteteOrderAgainComponent fDocentete={fDocentete} />
          )}
        </Box>
        <Box>
          {!paiementDone(fDocentete) &&
            !bonDeCommandeIsValidated(fDocentete, user) &&
            avoir === 0 &&
            fDocentete.doType < SAGE_DO_TYPE_VENTE_FA &&
            (differenceInSeconds(new Date(), new Date(fDocentete.cbcreation)) <
              3600 ||
              fDocentete.doType === SAGE_DO_TYPE_VENTE_DE) && (
              <DeleteFDocenteteComponent fDocentete={fDocentete} />
            )}
        </Box>
      </>
    );
  }
);

export default FDocenteteActionComponent;
