import * as React from "react";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { FDocenteteInterface } from "../../../interfaces/FDocenteteInterface";
import Card from "@mui/material/Card";
import {
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  useTheme,
} from "@mui/material";
import PriceComponent from "../PriceComponent";
import { useTranslation } from "react-i18next";
import {
  SAGE_DO_TYPE_VENTE_BC,
  SAGE_DO_TYPE_VENTE_DE,
  SAGE_DO_TYPE_VENTE_PL,
} from "../../../utils/DoceneteteUtils";
import { getUrlFArticleImage } from "../../../helpers/FileHelper";
import { IMAGE_SIZE_LOGO, IMAGE_SIZE_SMALL } from "../../../utils/FileUtils";
import HideImageIcon from "@mui/icons-material/HideImage";
import { PRODUCT_PAGE } from "../../../utils/RouteUtils";
import { Link } from "react-router-dom";
import FDocenteteActionComponent from "./FDocenteteActionComponent";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { isUserEnCompte } from "../../../helpers/UserHelper";
import {
  getNbDayTransformPlInDe,
  hasExpedition,
  paiementDone,
} from "../../../helpers/FDocenteteHelper";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import CopyClipboardComponent from "../CopyClipboardComponent";
import FDocenteteRefComponent from "./FDocenteteRefComponent";
import useMediaQuery from "@mui/material/useMediaQuery";
import Divider from "@mui/material/Divider";
import InfoIcon from "@mui/icons-material/Info";
import { FDOCENETETE_COLORS } from "../../../utils/FDoceneteteUtils";

interface State {
  fDocentete: FDocenteteInterface;
}

const FDocenteteComponent: React.FC<State> = React.memo(({ fDocentete }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const accountingSituation = useAppSelector(
    (state: RootState) => state.globalState.accountingSituation
  );
  const userProp = useAppSelector(
    (state: RootState) => state.globalState.userProp
  );
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [nbDayTransformPLInDe, setNbDayTransformPLInDe] = React.useState(
    getNbDayTransformPlInDe(fDocentete, user, userProp)
  );

  useEffect(() => {
    setNbDayTransformPLInDe(
      getNbDayTransformPlInDe(fDocentete, user, userProp)
    );
  }, [fDocentete, user, userProp, accountingSituation?.creditFdocentetes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Card variant="outlined" sx={{ marginBottom: 2 }}>
        <Box
          sx={{
            display: "flex",
            padding: 2,
            backgroundColor: FDOCENETETE_COLORS[fDocentete.doType].main,
            color: FDOCENETETE_COLORS[fDocentete.doType].contrastText,
          }}
        >
          <Box sx={{ flex: 1 }}>
            {fDocentete.doType === SAGE_DO_TYPE_VENTE_DE ? (
              <Typography>{t("sentence.dateDevis") + " "}</Typography>
            ) : (
              <Typography>{t("sentence.dateOrder") + " "}</Typography>
            )}
            <Typography>
              {new Date(fDocentete.doDate).toLocaleDateString(i18n.language, {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                timeZone: "Etc/UTC",
              })}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, textAlign: "center" }}>
            <Typography>{t("word.totalTtc") + " "}</Typography>
            <PriceComponent price={fDocentete.doTotalttc * 100} />
          </Box>
          <Box sx={{ flex: 1, textAlign: "right" }}>
            <Typography>
              {t("word.fdocentete.doType." + fDocentete.doType)}
            </Typography>
            <Typography>{fDocentete.doPiece}</Typography>
          </Box>
        </Box>
        <Grid container spacing={1} sx={{ padding: 1 }}>
          <Grid item xs={12} md={9}>
            {!isUserEnCompte(user) && hasExpedition(fDocentete) && (
              <>
                {!paiementDone(fDocentete) ? (
                  <Typography component="p" variant="h5" color="error">
                    {t("sentence.paymentRequiredForExpedition")}
                  </Typography>
                ) : (
                  [SAGE_DO_TYPE_VENTE_PL, SAGE_DO_TYPE_VENTE_BC].includes(
                    fDocentete.doType
                  ) && (
                    <Typography color="success">
                      {t("sentence.awaitingExpedition")}
                    </Typography>
                  )
                )}
              </>
            )}
            <List sx={{ paddingY: 0 }}>
              {fDocentete.fDoclignesPaginate?.map(
                (fDoclignePaginate, indexFDoclignePaginate) => {
                  let nbNoDate =
                    (fDoclignePaginate.stocks?.wantedQuantity ?? 0) -
                    (fDoclignePaginate.stocks?.gotQuantity ?? 0);
                  if (fDoclignePaginate.stocks?.incoming) {
                    for (const incoming of fDoclignePaginate.stocks?.incoming) {
                      nbNoDate -= incoming.DL_Qte;
                    }
                  }
                  return (
                    <React.Fragment key={indexFDoclignePaginate}>
                      <ListItem
                        alignItems="center"
                        sx={{
                          ...(indexFDoclignePaginate === 0 && {
                            paddingTop: 0,
                          }),
                          ...(indexFDoclignePaginate ===
                            // @ts-ignore
                            fDocentete.fDoclignesPaginate.length - 1 && {
                            paddingBottom: 0,
                          }),
                          ...(isSmall && {
                            flexWrap: "wrap",
                          }),
                        }}
                      >
                        <Box sx={{ flex: 0, marginRight: 2 }}>
                          <Typography>
                            {Number(fDoclignePaginate.dlQte)}
                          </Typography>
                        </Box>
                        <CopyClipboardComponent
                          className="RobotoMono"
                          component="span"
                          sx={{
                            color: theme.palette.success.main,
                          }}
                          text={fDoclignePaginate.arRef}
                        />
                        <Box sx={{ marginX: 1 }}>
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                            to={PRODUCT_PAGE + "/" + fDoclignePaginate.slug}
                            state={{
                              arRef: fDoclignePaginate.arRef,
                              fArticleProp: {
                                id: fDoclignePaginate.farticlePropId,
                              },
                            }}
                          >
                            {fDoclignePaginate.images !== null ? (
                              <img
                                src={
                                  (process.env.REACT_APP_API_URL ?? "") +
                                  getUrlFArticleImage(
                                    fDoclignePaginate.arRef,
                                    fDoclignePaginate.images[0],
                                    IMAGE_SIZE_SMALL
                                  )
                                }
                                style={{
                                  margin: "auto",
                                  width: IMAGE_SIZE_SMALL / 2,
                                }}
                                alt={
                                  "[" +
                                  fDoclignePaginate.arRef +
                                  "] " +
                                  fDoclignePaginate.dlDesign
                                }
                                loading="lazy"
                              />
                            ) : ["LIVRAISON", "PORT"].includes(
                                fDoclignePaginate.arRef
                              ) ? (
                              <LocalShippingIcon
                                sx={{
                                  width: IMAGE_SIZE_LOGO,
                                  height: IMAGE_SIZE_LOGO,
                                }}
                              />
                            ) : (
                              <HideImageIcon
                                sx={{
                                  width: IMAGE_SIZE_LOGO,
                                  height: IMAGE_SIZE_LOGO,
                                }}
                              />
                            )}
                          </Link>
                        </Box>
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            wordBreak: "break-word",
                          }}
                          to={PRODUCT_PAGE + "/" + fDoclignePaginate.slug}
                          state={{
                            arRef: fDoclignePaginate.arRef,
                            fArticleProp: {
                              id: fDoclignePaginate.farticlePropId,
                            },
                          }}
                        >
                          <ListItemText primary={fDoclignePaginate.dlDesign} />
                          {fDoclignePaginate.stocks && fDocentete.doType === 1 && (
                            <>
                              <Chip
                                label={
                                  fDoclignePaginate.stocks.gotQuantity +
                                  " / " +
                                  fDoclignePaginate.stocks.wantedQuantity
                                }
                                variant="outlined"
                                color={
                                  fDoclignePaginate.stocks.wantedQuantity ===
                                  fDoclignePaginate.stocks.gotQuantity
                                    ? "success"
                                    : "warning"
                                }
                              />
                              {fDoclignePaginate.stocks.wantedQuantity !==
                                fDoclignePaginate.stocks.gotQuantity && (
                                <>
                                  {fDoclignePaginate.stocks.incoming.length ===
                                  0 ? (
                                    <Typography
                                      variant="caption"
                                      sx={{ marginLeft: 1 }}
                                    >
                                      {t("sentence.noExpectedIncoming")}
                                    </Typography>
                                  ) : (
                                    <ul>
                                      <li>
                                        <Typography variant="caption">
                                          {fDoclignePaginate.stocks
                                            ?.gotQuantity +
                                            " " +
                                            t(
                                              "word.disponible." +
                                                (fDoclignePaginate.stocks
                                                  ?.gotQuantity > 1
                                                  ? "multiple"
                                                  : "single")
                                            )}
                                        </Typography>
                                      </li>
                                      {fDoclignePaginate.stocks.incoming.map(
                                        (incoming, indexIncoming) => (
                                          <React.Fragment key={indexIncoming}>
                                            <li>
                                              <Typography variant="caption">
                                                {t(
                                                  "sentence.incoming." +
                                                    (incoming.DL_Qte > 1
                                                      ? "multiple"
                                                      : "single")
                                                )
                                                  .replace(
                                                    "%quantity%",
                                                    incoming.DL_Qte.toString()
                                                  )
                                                  .replace(
                                                    "%date%",
                                                    new Date(
                                                      incoming.DO_DateLivr
                                                    ).toLocaleString(
                                                      i18n.language,
                                                      {
                                                        dateStyle: "long",
                                                        timeZone:
                                                          "America/Cuiaba",
                                                      }
                                                    )
                                                  )}
                                              </Typography>
                                            </li>
                                          </React.Fragment>
                                        )
                                      )}
                                      {nbNoDate > 0 && (
                                        <li>
                                          <Typography variant="caption">
                                            {t(
                                              "sentence.incomingNoTime." +
                                                (nbNoDate > 1
                                                  ? "multiple"
                                                  : "single")
                                            ).replace(
                                              "%quantity%",
                                              nbNoDate.toString()
                                            )}
                                          </Typography>
                                        </li>
                                      )}
                                    </ul>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </Link>
                      </ListItem>
                      {fDocentete.fDoclignesPaginate &&
                        indexFDoclignePaginate !==
                          fDocentete.fDoclignesPaginate.length - 1 && (
                          <Divider />
                        )}
                    </React.Fragment>
                  );
                }
              )}
            </List>
            <FDocenteteRefComponent fDocentete={fDocentete} />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              textAlign: "right",
              alignItems: "flex-end",
            }}
          >
            <FDocenteteActionComponent fDocentete={fDocentete} />
          </Grid>
          {nbDayTransformPLInDe !== null && (
            <Grid item xs={12}>
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                {nbDayTransformPLInDe > 0 ? (
                  <Typography component="span">
                    {t(
                      "sentence.transformPlInDe.day" +
                        (hasExpedition(fDocentete) ? "Livraison" : "")
                    )
                      .replace("%nbDay%", nbDayTransformPLInDe.toString())
                      .replace("%s%", nbDayTransformPLInDe > 1 ? "s" : "")}
                  </Typography>
                ) : (
                  <Typography component="span">
                    {t("sentence.transformPlInDe.now")}
                  </Typography>
                )}
                <Tooltip
                  title={t("sentence.transformPlInDe.explain")
                    .replace("%nbDay%", nbDayTransformPLInDe.toString())
                    .replace("%s%", nbDayTransformPLInDe > 1 ? "s" : "")}
                >
                  <InfoIcon />
                </Tooltip>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  );
});

export default FDocenteteComponent;
