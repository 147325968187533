import { RootState, store } from "../app/store";
import { resetApp } from "../app/globalSlice";
import { PATCH } from "../utils/MethodUtils";

export interface RequestApiResponseInterface {
  content: any;
  statusCode: number | null;
}

export interface RequestApiParamInterface {
  method: string;
  path: string;
  allowError?: boolean;
  paginate?: boolean;
  body?: any;
  token?: string;
  formData?: boolean;
  timeout?: number;
  blob?: boolean;
  leaveImpersonate?: boolean;
  redirect401?: boolean;
  getRawResponse?: boolean;
}

export function objectToQuery(param: any) {
  let result = "";
  for (const key in param) {
    if (Array.isArray(param[key])) {
      for (const value of param[key]) {
        result += "&" + key + "=" + encodeURIComponent(value);
      }
    } else {
      result += "&" + key + "=" + encodeURIComponent(param[key]);
    }
  }
  return "?" + result.substring(1);
}

export async function requestApi({
  method,
  path,
  allowError,
  paginate,
  body,
  token,
  formData,
  timeout,
  blob,
  leaveImpersonate,
  redirect401,
  getRawResponse,
}: RequestApiParamInterface): Promise<RequestApiResponseInterface> {
  if (paginate === undefined) {
    paginate = false;
  }
  if (formData === undefined) {
    formData = false;
  }
  const contentType = paginate ? "application/ld+json" : "application/json";
  const state: RootState = store.getState();
  const impersonate = state.globalState.impersonate;
  const headers: any = {
    ...(!!impersonate && !leaveImpersonate && { "X-Switch-User": impersonate }),
    Accept: contentType,
    "Content-Type":
      method === PATCH ? "application/merge-patch+json" : contentType,
  };
  if (token !== undefined) {
    headers.Authorization = "Bearer " + token;
  }
  if (allowError === undefined) {
    allowError = false;
  }
  const options: any = {
    method: method,
    headers: headers,
  };
  if (body !== undefined) {
    if (formData) {
      options.body = body;
      delete options.headers.Accept;
      delete options.headers["Content-Type"];
    } else {
      options.body = JSON.stringify(body);
    }
  }
  const errorResult: RequestApiResponseInterface = {
    statusCode: 0,
    content: null,
  };

  try {
    // eslint-disable-next-line no-undef
    const controller = new AbortController(); // https://stackoverflow.com/questions/46946380/fetch-api-request-timeout
    options.signal = controller.signal;
    setTimeout(() => controller.abort(), timeout ?? 30000);
    const rawResponse = await fetch(
      (process.env.REACT_APP_API_URL ?? "") + path,
      options
    );
    if (getRawResponse) {
      return {
        content: rawResponse,
        statusCode: rawResponse.status,
      };
    }
    const statusCode = rawResponse.status;
    if (redirect401 !== false && statusCode === 401) {
      if (token !== undefined) {
        store.dispatch(resetApp());
      }
    }
    if (!allowError && (statusCode < 200 || statusCode >= 300)) {
      // todo
    }
    let content = null;
    if (rawResponse.status !== 204) {
      if (blob) {
        window.open(
          window.URL.createObjectURL(await rawResponse.blob()),
          "_blank"
        );
      }
      try {
        content = await rawResponse.json();
      } catch (e) {
        // nothing
      }
    }
    return {
      content: content,
      statusCode: statusCode,
    };
  } catch (e) {
    console.log(e);
    return errorResult;
  }
}

function _jsonToFormData(data: any, files: File[] = []) {
  for (const key in data) {
    if (!data.hasOwnProperty(key)) {
      continue;
    }
    if (data[key] instanceof File) {
      files.push(data[key]);
      data[key] = "file-" + (files.length - 1);
    }
    if (
      data[key] != null &&
      data[key] != null &&
      typeof data[key] === "object"
    ) {
      _jsonToFormData(data[key], files);
    }
  }
  return files;
}

export function jsonToFormData(data: any, formData: FormData | null = null) {
  if (formData == null) {
    formData = new FormData();
  }
  const files = _jsonToFormData(data);
  formData.append("json", JSON.stringify(data));
  // @ts-ignore
  for (const [index, file] of files.entries()) {
    formData.append("file-" + index, file);
  }
  return formData;
}
