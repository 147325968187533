import {
  DriverInterface,
  FArticleImageInterface,
} from "../interfaces/FArticleInterface";
import {
  CATEGORY_IMAGE_DIR,
  CONSTRUCTOR_IMAGE_DIR,
  FARTICLE_IMAGE_DIR,
  FARTICLE_SIZE_DIR,
  FILTER_IMAGE_DIR,
  LOGO_IMAGE_DIR,
} from "../utils/FileUtils";
import { CategoryInterface } from "../interfaces/CategoryInterface";
import { FilterFilterImageInterface } from "../interfaces/FilterInterface";

export const getUrlImageDirectory = (
  arRef: string | undefined
): string | null => {
  return FARTICLE_IMAGE_DIR + "/" + arRef + FARTICLE_SIZE_DIR;
};

export const getUrlFArticleImage = (
  arRef: string | undefined,
  fArticleImage: FArticleImageInterface | null,
  size: number | null
): string | null => {
  if (
    fArticleImage === null ||
    fArticleImage === undefined ||
    arRef === undefined
  ) {
    return null;
  }
  if (size === null || fArticleImage.sizes.length === 0) {
    return FARTICLE_IMAGE_DIR + "/" + arRef + "/" + fArticleImage.name;
  }
  if (size > window.screen.width) {
    size = window.screen.width;
  }
  const thisSize = getClosestSize(fArticleImage, size);
  if (thisSize === null) {
    return null;
  }
  return (
    getUrlImageDirectory(arRef) + "/" + thisSize + "_" + fArticleImage.name
  );
};

export const getUrlCategoryImage = (
  category: CategoryInterface | undefined,
  size: number
): string | null => {
  if (category === undefined) {
    return null;
  }
  if (size > window.screen.width) {
    size = window.screen.width;
  }
  const thisSize = getClosestSize(category, size);
  if (thisSize === null) {
    return null;
  }
  return (
    CATEGORY_IMAGE_DIR +
    "/" +
    category.id +
    "/" +
    thisSize +
    "_" +
    category.filename +
    "." +
    category.extension
  );
};

export const getUrlFilterImageImage = (
  filterImage: FilterFilterImageInterface
): string => {
  return (
    (process.env.REACT_APP_API_URL ?? "") +
    FILTER_IMAGE_DIR +
    "/" +
    filterImage.filename +
    "." +
    filterImage.extension
  );
};

export const getUrlConstructorImage = (
  driver: DriverInterface | null | undefined,
  size: number | null
): string | null => {
  if (driver === null || !driver?.images) {
    return null;
  }
  if (size === null || driver?.images.sizes.length === 0) {
    return null;
  }
  if (size > window.screen.width) {
    size = window.screen.width;
  }
  const thisSize = getClosestSize(driver.images, size);
  if (thisSize === null) {
    return null;
  }
  return CONSTRUCTOR_IMAGE_DIR + "/" + thisSize + "_" + driver.images.name;
};

export const getUrlLogoImage = (logo: string): string => {
  return LOGO_IMAGE_DIR + "/" + logo;
};

const getClosestSize = (
  fArticleImage: FArticleImageInterface | CategoryInterface,
  size: number
): number | null => {
  if (fArticleImage.sizes === null) {
    return null;
  }
  return fArticleImage.sizes.reduce(function (prev, curr) {
    let result;
    let notResult;
    if (Math.abs(curr - size) < Math.abs(prev - size)) {
      result = curr;
      notResult = prev;
    } else {
      result = prev;
      notResult = curr;
    }
    if (result >= size) {
      return result;
    }
    if (notResult >= size) {
      return notResult;
    }
    return result - notResult > 0 ? result : notResult;
  });
};

export const urltoFile = async (
  url: string,
  filename: string,
  mimeType: string
) => {
  return (
    (await fetch(url)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((buf) => {
        return new File([buf], filename, { type: mimeType });
      })) ?? null
  );
};
