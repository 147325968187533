import React, { SyntheticEvent, useRef } from "react";
import { InputInterface } from "../../../interfaces/InputInterface";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { requestApi } from "../../../helpers/RequestApi";
import { GET, POST } from "../../../utils/MethodUtils";
import { SIRET_URL, USER_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { SiretResponseInterface } from "../../../interfaces/SiretInterface";
import RegisterFormComponent from "./RegisterFormComponent";
import { Link, useNavigate } from "react-router-dom";
import { ACCOUNT_PAGE, LOGIN_PAGE } from "../../../utils/RouteUtils";
import { LoadingButton } from "@mui/lab";
import { ADMINISTRATION, COMPANY } from "../../../utils/RegisterUtils";
import siretValidator from "../../../helpers/validator/SiretValidator";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { initAppHelper } from "../../../helpers/InitAppHelper";
import { getConfigurationValue } from "../../../helpers/ConfigurationHelper";
import { CAN_CREATE_ACCOUNT } from "../../../utils/ConfigurationUtils";

interface FormState {
  type: InputInterface;
  ctSiret: InputInterface;
}

const RegisterComponent: React.FC = React.memo(() => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const refSiret: any = useRef(undefined);
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const getDefaultValues = React.useCallback((): FormState => {
    return {
      type: { value: "", error: "" },
      ctSiret: { value: null, error: "" },
    };
  }, []);
  const [values, setValues] = React.useState<FormState>(getDefaultValues());
  const [sirets, setSirets] = React.useState<SiretResponseInterface[]>([]);
  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const [searchSiret, setSearchSiret] = React.useState<string>("");
  const [hasSiret, setHasSiret] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState(false);
  const refForm: any = useRef(undefined);
  const navigate = useNavigate();

  const handleChangeSelect = React.useCallback(
    (prop: keyof FormState) => (event: SelectChangeEvent) => {
      setValues((v) => {
        return {
          ...v,
          [prop]: {
            ...v[prop],
            value: event.target.value as string,
            error: "",
          },
        };
      });
    },
    []
  );

  const handleSearchSiret = React.useCallback(
    (event: SyntheticEvent<Element, Event>, value: string) => {
      setSearchSiret(value);
      setSirets([]);
    },
    []
  );

  const handleChangeAutocomplete = React.useCallback(
    (prop: keyof FormState) =>
      (event: SyntheticEvent<Element, Event>, value: any) => {
        setValues((v) => {
          return {
            ...v,
            // @ts-ignore
            [prop]: { ...v[prop], value: value, error: "" },
          };
        });
      },
    []
  );

  const searchSirets = React.useCallback(async () => {
    const siret = searchSiret.trim();
    const siretError = siretValidator(siret);
    if (siretError || siret === values.ctSiret.value?.etablissement.siret) {
      if (siretError && hasSiret) {
        setValues((v) => {
          return {
            ...v,
            ctSiret: { ...v.ctSiret, error: siretError },
          };
        });
      }
      return;
    }
    setIsSearching(true);
    const response = await requestApi({
      method: GET,
      path: SIRET_URL + "/" + siret,
      allowError: false,
    });
    const list: NodeList = refSiret.current.querySelectorAll("input");
    if (response.statusCode === 200) {
      setValues((v) => {
        return {
          ...v,
          ctSiret: { value: response.content, error: "" },
        };
      });
      list.forEach((el) => {
        // @ts-ignore
        el.blur();
      });
    } else {
      if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else if (response.statusCode === 404) {
        toastr.error(t("word.error"), t("sentence.invalidSiret"));
        setValues((v) => {
          return {
            ...v,
            ctSiret: { value: null, error: "" },
          };
        });
      } else {
        setValues((v) => {
          const customSiretResponse: SiretResponseInterface = {
            etablissement: {
              siret: siret,
              uniteLegale: {
                denominationUniteLegale: null,
              },
              adresseEtablissement: {
                complementAdresseEtablissement: null,
                numeroVoieEtablissement: null,
                typeVoieEtablissement: null,
                libelleVoieEtablissement: null,
                codePostalEtablissement: null,
                libelleCommuneEtablissement: null,
              },
            },
          };
          return {
            ...v,
            ctSiret: {
              value: customSiretResponse,
              error: "",
            },
          };
        });
        list.forEach((el) => {
          // @ts-ignore
          el.blur();
        });
      }
    }
    setIsSearching(false);
  }, [hasSiret, searchSiret, t, values.ctSiret.value?.etablissement.siret]);

  const handleChangeSwitch = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setValues((v) => {
          return {
            ...v,
            ctSiret: { value: null, error: "" },
          };
        });
      }
      setHasSiret(!event.target.checked);
    },
    []
  );

  const showForm = React.useCallback(() => {
    return (
      !!values.ctSiret.value || (!hasSiret && values.type.value === COMPANY)
    );
  }, [hasSiret, values.ctSiret.value, values.type.value]);

  const handleHasSiret = React.useCallback(() => {
    if (values.type.value === ADMINISTRATION) {
      setHasSiret(true);
    }
  }, [values.type.value]);

  const handleRegister = React.useCallback(async () => {
    setLoading(true);
    const form = refForm.current.getValue();
    if (!form) {
      setLoading(false);
      return;
    }
    const response = await requestApi({
      method: POST,
      path: USER_URL,
      allowError: true,
      token: token,
      body: {
        ...form,
        ctSiret: !!searchSiret ? searchSiret : null,
        type: values.type.value,
      },
    });
    if (
      response.statusCode === 201 &&
      (await initAppHelper(response.content.token))
    ) {
      navigate(ACCOUNT_PAGE);
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [navigate, searchSiret, t, token, values.type.value]);

  React.useEffect(() => {
    const timeoutTyping = setTimeout(() => {
      searchSirets();
    }, 500);
    return () => clearTimeout(timeoutTyping);
  }, [searchSirets]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    handleHasSiret();
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {getConfigurationValue(CAN_CREATE_ACCOUNT) && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={values.type.value === "" ? 12 : 6}>
            <FormControl fullWidth required>
              <InputLabel id="type-label">{t("field.iam")}</InputLabel>
              <Select
                labelId="type-label"
                value={values.type.value}
                label={t("field.iam")}
                onChange={handleChangeSelect("type")}
              >
                <MenuItem value={COMPANY}>{t("word.company")}</MenuItem>
                <MenuItem value={ADMINISTRATION}>
                  {t("word.administration")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {values.type.value !== "" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ...(isSmall && { flexDirection: "column" }),
                }}
              >
                <Box sx={{ flex: 1, width: "100%" }}>
                  <Autocomplete
                    disabled={!hasSiret}
                    options={sirets}
                    forcePopupIcon={false}
                    noOptionsText={
                      isSearching ? (
                        <Box sx={{ textAlign: "center" }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        t("word.searchSiret")
                      )
                    }
                    inputValue={searchSiret}
                    onInputChange={handleSearchSiret}
                    getOptionLabel={(siretResponse: SiretResponseInterface) => {
                      return siretResponse.etablissement.siret;
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.etablissement.siret}>
                          {option.etablissement.siret}
                        </li>
                      );
                    }}
                    isOptionEqualToValue={(
                      option: SiretResponseInterface,
                      value: SiretResponseInterface
                    ) =>
                      option.etablissement.siret === value.etablissement.siret
                    }
                    onChange={handleChangeAutocomplete("ctSiret")}
                    value={values.ctSiret.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        ref={refSiret}
                        autoComplete="off"
                        error={!!values.ctSiret.error}
                        helperText={t(values.ctSiret.error ?? "")}
                        required={hasSiret}
                        label={t("word.siret")}
                      />
                    )}
                  />
                </Box>
                {values.type.value === COMPANY && (
                  <Box sx={{ paddingLeft: 2 }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            value={hasSiret}
                            onChange={handleChangeSwitch}
                          />
                        }
                        label={t("word.iDontHaveSiret")}
                      />
                    </FormGroup>
                  </Box>
                )}
              </Box>
            )}
          </Grid>
          {showForm() && (
            <RegisterFormComponent
              ref={refForm}
              initSiret={values.ctSiret.value}
              type={values.type.value}
            />
          )}
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 2,
              }}
            >
              <Link
                to={LOGIN_PAGE}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <LoadingButton variant="text" disabled={loading}>
                  {t("action.login")}
                </LoadingButton>
              </Link>
              {showForm() && (
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  onClick={handleRegister}
                >
                  {t("action.register")}
                </LoadingButton>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
});

export default RegisterComponent;
